import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'



import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { makeStyles } from '@material-ui/core/styles'

import * as FileSaver from 'file-saver'
import printJS from "print-js";



import { 
    IconButton,
    Icon,
    Tooltip,
} from '@material-ui/core'

import HARDCODE from '../../business-const/HardCode/HardCode'

import ICON from '../../business-const/Icon/Icon'

import LABEL from '../../business-const/Label/Label'

import {
    COLOR
} from '../../business-const/Color/Color'

import {
    GridContainer,
    GridItem1,
    GridItem3,
    GridItem4,
    GridItem5,
    GridItem6,
    GridItem7,
    GridItem11,
} from '../../business-components/Grid/Grid'

import {
    IconButtonClearDialog,
} from '../IconButton/IconButton'

import {
    SvgIconButtonHelp,
} from '../SvgIcon/SvgIconGlobal/SvgIconGlobal'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide
        direction="up"
        ref={ref}
        {...props}
    />
})

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: COLOR().bgIconButtonHelpTitle,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
})

const TooltipHelp = withStyles((theme) => ({
    tooltip: {
        color: COLOR().iconButtonHelpTooltip,
        backgroundColor: COLOR().bgIconButtonHelpTooltip,
    }
}))(Tooltip);

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose } = props

    return (
        <MuiDialogTitle
            disableTypography
            className={classes.root}
        >
            <GridContainer>
                <GridItem11>
                    <Typography
                        variant="h6"
                        style={{
                            color: COLOR().iconButtonHelpTitle,
                        }}
                    >
                        {children}
                    </Typography>
                </GridItem11>
                
                <GridItem1>
                    {onClose
                        ?
                            (
                                <IconButtonClearDialog
                                    aria-label="Close"
                                    className={classes.closeButton}
                                    onClick={onClose}
                                />
                            )
                        :
                            null
                    }
                </GridItem1>
            </GridContainer>
        </MuiDialogTitle>
    )
})

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: COLOR().bgIconButtonHelpText,
    },
}))(MuiDialogContent)



export const CustomizedDialogsPopup = ( props ) => {

    const [open, setOpen] = useState(props.aberto ? props.aberto : false)

    const handleClickOpen = () => {

        if (props.open) {
            
            props.open(true)
        }

        setOpen(true)
    }

    const handleClose = () => {

        if (props.close) {
            
            props.close(false)
        }

        setOpen(false)

        if (props.onClose) {

            props.onClose()
        }

        if (props.afterClose) {
            
            props.afterClose(false)
        }
    }

    return (
        <span
            align={props.align ? props.align : "right"}
        >
            <TooltipHelp
                title={<div dangerouslySetInnerHTML={{ __html: props.popupTitle}} />}
            >
                <>
                    {props.tooltipTitle
                        ?
                            props.popupButtonDestaque
                                ?
                                    <props.PopupButton
                                        tooltipTitle={props.tooltipTitle}
                                        popupButtonDestaque={props.popupButtonDestaque}
                                        onClick={handleClickOpen}
                                    />
                                :
                                    <props.PopupButton
                                        tooltipTitle={props.tooltipTitle}
                                        onClick={handleClickOpen}
                                    />
                        :
                            props.popupButtonDestaque
                                ?
                                    <props.PopupButton
                                        popupButtonDestaque={props.popupButtonDestaque}
                                        onClick={handleClickOpen}
                                    />
                                :
                                    props.PopupButton
                                        ?
                                            <props.PopupButton
                                                onClick={handleClickOpen}
                                            />
                                        :
                                            ''
                    }
                </>
            </TooltipHelp>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                fullScreen={props.fullScreen ? true : false}
                maxWidth={props.maxWidth ? props.maxWidth : 'md'}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {/*props.popupTitle*/}
                    <span
                        dangerouslySetInnerHTML={{ __html: props.popupTitle + " "}}
                    />
                    {props.popupNavegacao && (
                        <>
                            {props.popupNavegacao}
                        </>
                    )}
                </DialogTitle>

                <DialogContent
                    dividers={props.popupTitle ? true : false}
                    className={props.DialogContentClassName}
                >
                    <div
                        className={props.bgDefault ? 'bg-default' : ''}
                    >
                        {props.bgDefault && (
                            <br/>
                        )}
                        {props.popupComponent}
                        {props.bgDefault && (
                            <br/>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </span>
    )
}



export const CustomizedDialogsConfirmacao = ( props ) => {

    const { ObjetoAcao, confirmacaoTitle, confirmacaoText, onClick, maxWidth, ...rest } = props

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <ObjetoAcao
                onClick={handleClickOpen}
                {...rest}
            />

            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                maxWidth={maxWidth ? maxWidth : 'sm'}
            >
                <DialogTitle
                    id="customized-dialog-title"
                >
                    {confirmacaoTitle}
                </DialogTitle>

                <DialogContent dividers
                >
                    <Typography
                        gutterBottom
                        style={{
                            color: COLOR().iconButtonConfirmacaoText
                        }}
                    >
                        {<span dangerouslySetInnerHTML={{ __html: confirmacaoText}} />}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => {
                            onClick()
                            handleClose()
                        }}
                        color="primary"
                    >
                        {LABEL.sim}
                    </Button>
                    <Button
                        onClick={handleClose}
                        color="primary"
                    >
                        {LABEL.nao}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}



export const CustomizedDialogsMensagem = ( props ) => {

    const { confirmacaoTitle, confirmacaoText, onClick } = props

    const [open, setOpen] = useState(true)

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
            >
                <DialogTitle
                    id="customized-dialog-title"
                >
                    {confirmacaoTitle}
                </DialogTitle>

                <DialogContent dividers
                >
                    <Typography
                        gutterBottom
                        style={{
                            color: COLOR().iconButtonConfirmacaoText
                        }}
                    >
                        {<span dangerouslySetInnerHTML={{ __html: confirmacaoText}} />}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => {
                            onClick()
                            handleClose()
                        }}
                        color="primary"
                    >
                        {LABEL.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}



export const CustomizedDialogsIconButtonHelp = ( props ) => {
    
    const { helpTitle, helpText, disableHoverListener, SvgImage, ...rest } = props

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <TooltipHelp
                title={<div dangerouslySetInnerHTML={{ __html: helpText}} />}
                disableHoverListener={disableHoverListener}
            >
                <IconButton
                    className="mt-2"
                    size="small"
                    onClick={handleClickOpen}
                    {...rest}
                >
                    <SvgIconButtonHelp
                        style={{
                            color: COLOR().iconButtonHelp
                        }}
                        fontSize="large"
                    />
                </IconButton>
            </TooltipHelp>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {helpTitle}
                </DialogTitle>

                <DialogContent dividers
                >
                    {SvgImage
                        ?
                            <SvgImage />
                        :
                            ''
                    }
                    
                    <Typography
                        gutterBottom
                        style={{
                            color: COLOR().iconButtonHelpText
                        }}
                    >
                        
                        {<span dangerouslySetInnerHTML={{ __html: helpText}} />}
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    )
}



const useStyles = makeStyles(() => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center',
        margin: '0 auto'
    },
    /*
    nav: {
        display: 'flex',
        //margin: '1rem auto',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    previous: {
        border: 'none',
        padding: '1rem',
        listStyle: 'none',
        fontSize: '1.1rem',
        //fontSize: '1.3rem',
        //margin: '0.7rem',
        borderRadius: '20%',
        cursor: 'pointer',
        backgroundolColor: '#f1f1f1',
        color: 'black',
    },
    next: {
        border: 'none',
        padding: '1rem',
        listStyle: 'none',
        fontSize: '1.1rem',
        //fontSize: '1.3rem',
        //margin: '0.7rem',/
        borderRadius: '20%',
        cursor: 'pointer',
        backgroundColor: '#4a8fed',
        color: 'white'
    },
    */
    p: {
        fontSize: '1.1rem',
        /*fontSize: '1.3rem',*/
        backgroundColor: '#f5f5f5',
        padding: '0.5rem'
        /*padding: '1rem'*/
    }
}))

export const CustomizedDialogsViewPDF = ( props ) => {

    const [open, setOpen] = useState(false)

    const [pdfFile, setPdfFile] = useState(false)

    const handleClickOpen = async () => {

        if (props.carregarPDF) {

            setPdfFile(await props.carregarPDF())

        } else {

            setPdfFile(props.pdfFile)
        }

        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }



    const classes = useStyles()



    const [numPages, setNumPages] = useState(null)

    const [pageNumber, setPageNumber] = useState(1)

    const [zoom, setZoom] = useState(1.5)



    const onDocumentLoadSuccess = ({ numPages }) => {

        setNumPages(numPages)
    }

    const goToPrevPage = () =>
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1)

    const goToNextPage = () =>
        setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1)

    const goToPage = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= numPages) {
            setPageNumber(pageNumber);
        }
    }

    const handleZoomIn = () => {
        setZoom(zoom + 0.1);
    }

    const handleZoomOut = () => {
        if (zoom > 0.1) {
            setZoom(zoom - 0.1);
        }
    }

    const handleDownloadPDF = () => {
        FileSaver.saveAs(pdfFile, props.popupTitle)
    }

    const handlePrintPDF = () => {
        printJS({printable: pdfFile.substring(HARDCODE.PDFApplicationCompleto.length), type: 'pdf', base64: true})
    }
    


    return (
        <div
            className={props.className}
            align="right"
        >
            <TooltipHelp
                title={<div dangerouslySetInnerHTML={{ __html: props.popupTitle}} />}
            >
                <>
                    {props.tooltipTitle
                        ?
                            props.popupButtonDestaque
                                ?
                                    <props.PopupButton
                                        tooltipTitle={props.tooltipTitle}
                                        popupButtonDestaque={props.popupButtonDestaque}
                                        onClick={handleClickOpen}
                                    />
                                :
                                    <props.PopupButton
                                        tooltipTitle={props.tooltipTitle}
                                        onClick={handleClickOpen}
                                    />
                        :
                            props.popupButtonDestaque
                                ?
                                    <props.PopupButton
                                        popupButtonDestaque={props.popupButtonDestaque}
                                        onClick={handleClickOpen}
                                    />
                                :
                                    <props.PopupButton
                                    onClick={handleClickOpen}
                                    />
                    }
                </>
            </TooltipHelp>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                maxWidth={props.maxWidth ? props.maxWidth : 'md'}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {/*
                    <nav
                        className={classes.nav}
                    >
                    */}
                        <GridContainer
                            className='mb--4'
                        >
                            <GridItem6
                                className='mt-3'
                            >
                                {props.popupTitle && props.popupTitle.length > 50
                                    ?
                                        <h5>
                                            {props.popupTitle}
                                        </h5>
                                    :
                                        <h4>
                                            {props.popupTitle}
                                        </h4>
                                }
                            </GridItem6>

                            <GridItem6>
                                <GridContainer>
                                    <GridItem4>
                                        <GridContainer>
                                            <GridItem3
                                                align='left'
                                            >
                                                <Tooltip
                                                    title={LABEL.paginaAnterior}
                                                >
                                                    <IconButton
                                                        className='p-0'
                                                        onClick={goToPrevPage}
                                                        disabled={pageNumber === 1}
                                                    >
                                                        <Icon
                                                            className={pageNumber === 1 ? "text-muted" : "text-black"}
                                                            fontSize="large"
                                                        >
                                                            {ICON.paginaAnterior}
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </GridItem3>

                                            <GridItem6>
                                                <GridContainer>
                                                    <GridItem7>
                                                        <input
                                                            className="mt-2 mb-2 w-full text-center text-18"
                                                            size="medium"
                                                            variant="outlined"
                                                            type="number"
                                                            min="1"
                                                            max={numPages}
                                                            value={pageNumber}
                                                            onChange={(e) => goToPage(parseInt(e.target.value))}
                                                        />
                                                    </GridItem7>
                                            
                                                    <GridItem5
                                                        className='mt-1 text-18'
                                                        align='right'
                                                    >
                                                        <span
                                                            className='font-light'
                                                        >
                                                            de
                                                        </span>
                                                        <span
                                                            className='ml-2'
                                                        >
                                                            {numPages}
                                                        </span>
                                                    </GridItem5>
                                                </GridContainer>
                                            </GridItem6>

                                            <GridItem3
                                                align='right'
                                            >
                                                <Tooltip
                                                    title={LABEL.proximaPagina}
                                                >
                                                    <IconButton
                                                        className='p-0'
                                                        onClick={goToNextPage}
                                                        disabled={pageNumber === numPages}
                                                    >
                                                        <Icon
                                                            className={pageNumber === numPages ? "text-muted" : "text-black"}
                                                            fontSize="large"
                                                        >
                                                            {ICON.proximaPagina}
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </GridItem3>
                                        </GridContainer>
                                    </GridItem4>

                                    <GridItem4>
                                        <GridContainer
                                            className='ml-10'
                                        >
                                            
                                            <GridItem4
                                                align='right'
                                            >
                                                <Tooltip
                                                    title={LABEL.zoomOut}
                                                >
                                                    <IconButton
                                                        className='p-0'
                                                        onClick={handleZoomOut}
                                                    >
                                                        <Icon
                                                            className="text-black"
                                                            fontSize="large"
                                                        >
                                                            {ICON.zoomOut}
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </GridItem4>

                                            <GridItem4
                                                className='mt-1 text-18'
                                                align='center'
                                            >
                                                <span
                                                    className='font-light'
                                                >
                                                    {Number.parseInt(100 * zoom) + '%'} 
                                                </span>
                                            </GridItem4>

                                            <GridItem4>
                                                <Tooltip
                                                    title={LABEL.zoomIn}
                                                >
                                                    <IconButton
                                                        className='p-0'
                                                        onClick={handleZoomIn}
                                                    >
                                                        <Icon
                                                            className="text-black"
                                                            fontSize="large"
                                                        >
                                                            {ICON.zoomIn}
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </GridItem4>
                                        </GridContainer>
                                    </GridItem4>

                                    <GridItem4>
                                        <GridContainer
                                            className='ml-10'
                                        >
                                            <GridItem6
                                                align='right'
                                            >
                                                <Tooltip
                                                    title={LABEL.downloadPDF}
                                                >
                                                    <IconButton
                                                        className='p-0'
                                                        onClick={handleDownloadPDF}
                                                    >
                                                        <Icon
                                                            className="text-black"
                                                            fontSize="large"
                                                        >
                                                            {ICON.download}
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </GridItem6>

                                            <GridItem6
                                                align='center'
                                            >
                                                <Tooltip
                                                    title={LABEL.imprimirPDF}
                                                >
                                                    <IconButton
                                                        className='p-0'
                                                        onClick={handlePrintPDF}
                                                    >
                                                        <Icon
                                                            className="text-black"
                                                            fontSize="large"
                                                        >
                                                            {ICON.print}
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </GridItem6>
                                        </GridContainer>
                                    </GridItem4>
                                </GridContainer>
                            </GridItem6>

                            {/*
                            <button
                                onClick={goToPrevPage}
                                className={classes.previous}
                            >
                                Anterior
                            </button>

                            <button
                                onClick={goToNextPage}
                                className={classes.next}
                            >
                                Próxima
                            </button>
                            
                            <div
                                className={classes.p}
                            >
                                Página {pageNumber} de {numPages}
                            </div>
                            */}
                        </GridContainer>
                    {/*</nav>*/}
                </DialogTitle>

                <DialogContent dividers
                >
                    <div
                        className={classes.page}
                    >
                        <Document
                            file={pdfFile}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page
                                pageNumber={pageNumber}
                                scale={zoom}
                            />
                        </Document>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
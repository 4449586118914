import _ from 'lodash'

import history from 'history.js'

import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import MESSAGE from '../../business-const/Message/Message'

import PATH from '../../business-const/Path/Path'

import AuthService from '../../business-api/AuthService'

import ConfiguracaoService from '../../business-api/ConfiguracaoService'

import UsuarioService from '../../business-api/UsuarioService'

import {
    mensagemErroErro,
    mensagemAlerta,
} from '../../business-components/Toastr/Toastr'

import {
    sortAsc
} from '../../business-components/Sort/Sort'

import {
    pesquisaList,
    pesquisaIndexList,
} from '../../business-rules/List/ListService'



export const comparaConfiguracao = (values, valuesAnt) => {
    
    const comparaConfiguracao =
        _.isEqual(values.jsonConfiguracao, valuesAnt.jsonConfiguracao) &&
        _.isEqual(values.jsonImagem, valuesAnt.jsonImagem) &&
        _.isEqual(values.jsonPagina, valuesAnt.jsonPagina) &&
        _.isEqual(values.jsonGestaoPatrimonial, valuesAnt.jsonGestaoPatrimonial) &&
        _.isEqual(values.jsonUsuario, valuesAnt.jsonUsuario)

    return comparaConfiguracao
}



export const converteJsonConfiguracaoJsonImagem = (jsonConfiguracao, jsonImagem) => {

    let jsonImagemAnt = jsonImagem

    if (!Array.isArray(jsonImagem)) {

        jsonImagem = []

    } else {

        jsonImagemAnt = {}
    }

    if (jsonImagem.length === 0) {

        jsonImagem.push(
            {
                id: HARDCODE.idWhiteLabelPadrao
            }
        )

        if (jsonImagemAnt.logo) {

            jsonImagem[0].logo = jsonImagemAnt.logo
        }

        if (/*!jsonImagem.logo && */jsonConfiguracao.logo) {

            jsonImagem[0].logo = jsonConfiguracao.logo
            jsonConfiguracao.logo = null
        }
        
        if (jsonImagemAnt.logoLoginSidenav) {

            jsonImagem[0].logoLoginSidenav = jsonImagemAnt.logoLoginSidenav
        }

        if (/*!jsonImagem.logoLoginSidenav && */jsonConfiguracao.logoLoginSidenav) {

            jsonImagem[0].logoLoginSidenav = jsonConfiguracao.logoLoginSidenav
            jsonConfiguracao.logoLoginSidenav = null
        }

        //if (!jsonImagem.background && jsonConfiguracao.background) {

        //    jsonImagem.background = jsonConfiguracao.background
        //    jsonConfiguracao.background = null
        //}

        if (jsonImagemAnt.backgroundCapaRelatorio) {

            jsonImagem[0].backgroundCapaRelatorio = jsonImagemAnt.backgroundCapaRelatorio
        }

        if (/*!jsonImagem.backgroundCapaRelatorio && */jsonConfiguracao.backgroundCapaRelatorio) {

            jsonImagem[0].backgroundCapaRelatorio = jsonConfiguracao.backgroundCapaRelatorio
            jsonConfiguracao.backgroundCapaRelatorio = null
        }

        if (jsonImagemAnt.backgroundPaginaRelatorio) {

            jsonImagem[0].backgroundPaginaRelatorio = jsonImagemAnt.backgroundPaginaRelatorio
        }
    }

    return jsonImagem
}

export const getConfiguracaoUsuario = async (variaveisGlobais, usuario, validaAssinatura, reutilizar, obterImagem) => {

    const configuracaoService = new ConfiguracaoService()

    try {

        let response

        if (
                reutilizar &&
                (!usuario || usuario === AuthService.obterUsuarioLogado(variaveisGlobais)) &&
                ConfiguracaoService.obterConfiguracaoUsuario(variaveisGlobais) !== undefined
        ) {

            response = ConfiguracaoService.obterConfiguracaoUsuario(variaveisGlobais)

        } else {

            response =
                await configuracaoService.buscarConfiguracaoUsuario(variaveisGlobais,
                    usuario ? usuario : AuthService.obterUsuarioLogado(variaveisGlobais), obterImagem)

            if (reutilizar && (!usuario || usuario === AuthService.obterUsuarioLogado(variaveisGlobais))) {

                ConfiguracaoService.setarConfiguracaoUsuario(variaveisGlobais, response)
            }
        }

        const email = response.data.email

        const perfil = response.data.perfil

        const tipoLicenca = response.data.tipoLicenca

        const fullWhiteLabel = response.data.fullWhiteLabel

        const ativo = response.data.ativo

        const idConfiguracao = response.data.idConfiguracao

        const idFormList = response.data.idFormList

        const escopo = response.data.escopo

        const jsonConfiguracao =
            ajustaInclusoesAlteracoesExclusoesJsonConfiguracao(JSON.parse(response.data.jsonConfiguracao))

        const jsonImagem =
            ajustaInclusoesAlteracoesExclusoesJsonImagem(JSON.parse(response.data.jsonImagem))

        const jsonPagina =
            ajustaInclusoesAlteracoesExclusoesJsonPagina(JSON.parse(response.data.jsonPagina))

        const jsonGestaoPatrimonial =
            ajustaInclusoesAlteracoesExclusoesJsonGestaoPatrimonial(JSON.parse(response.data.jsonGestaoPatrimonial))

        const contratouLicencaPlanejadorTodosUsuarios = response.data.contratouLicencaPlanejadorTodosUsuarios

        const jsonPagamento =
            ajustaInclusoesAlteracoesExclusoesJsonPagamento(JSON.parse(response.data.jsonPagamento))

        const pagamentoBoleto = response.data.pagamentoBoleto

        const jsonUsuario = 
            ajustaInclusoesAlteracoesExclusoesJsonUsuario(JSON.parse(response.data.jsonUsuario))

        const jsonUsuarioAdmin =
            ajustaInclusoesAlteracoesExclusoesJsonUsuarioAdmin(JSON.parse(response.data.jsonUsuarioAdmin))

        const dataPrecoModuloBaseAtual = response.data.dataPrecoModuloBaseAtual
	
        const precoModuloBaseAtual = response.data.precoModuloBaseAtual

        const statusAssinatura = response.data.statusAssinatura

        const integracao = response.data.integracao

        if (validaAssinatura) {

            if (statusAssinatura === HARDCODE.statusAssinaturaPendente) {

                mensagemAlerta(MESSAGE().realizarAssinatura)

                history.push(PATH.pageConfiguracaoForm)

            } else {

                if (statusAssinatura === HARDCODE.statusAssinaturaPagtoAtraso) {

                    mensagemAlerta(MESSAGE().cobrancaEmAtraso)

                } else {

                    if (statusAssinatura === HARDCODE.statusAssinaturaBloquearAcessoPagtoAtraso) {

                        mensagemAlerta(MESSAGE().acessoBloqueadoPagtoAtraso)

                        history.push(PATH.pageConfiguracaoForm)
                    }
                }
            }
        }

        return { email, perfil, tipoLicenca, fullWhiteLabel, ativo, idConfiguracao, idFormList, escopo, jsonConfiguracao, jsonImagem,
            jsonPagina, jsonGestaoPatrimonial, contratouLicencaPlanejadorTodosUsuarios, jsonPagamento, pagamentoBoleto, jsonUsuario,
                jsonUsuarioAdmin, dataPrecoModuloBaseAtual, precoModuloBaseAtual, statusAssinatura, integracao }

    } catch (erro) {

        mensagemErroErro(erro)

        const usuarioService = new UsuarioService()

        usuarioService.logout(variaveisGlobais, false);
    }
}

   

export const getEquipeList = async ( variaveisGlobais, idConfiguracao, reutilizar ) => {

    const usuarioService = new UsuarioService()

    try {

        let response

        if (
                reutilizar &&
                UsuarioService.obterEquipeList(variaveisGlobais) !== undefined
        ) {

            response = UsuarioService.obterEquipeList(variaveisGlobais)

        } else {

            response = await usuarioService.buscarEquipeList(variaveisGlobais, AuthService.obterUsuarioLogado(variaveisGlobais),
                idConfiguracao)

            response.data?.forEach( (linhaResponse, index) => {
                response.data[index].jsonUsuario = JSON.parse(linhaResponse.jsonUsuario)
                response.data[index].jsonEquipe = JSON.parse(linhaResponse.jsonEquipe)
            })

            if (reutilizar) {

                UsuarioService.setarEquipeList(variaveisGlobais, response)
            }
        }
        
        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const putConfiguracaoUsuario = async ( variaveisGlobais, values ) => {

    const configuracaoService = new ConfiguracaoService()

    try {
        const configuracaoUsuario = {
            idUsuario: AuthService.obterUsuarioLogado(variaveisGlobais),
            jsonConfiguracao: JSON.stringify(values.jsonConfiguracao),
            jsonImagem: JSON.stringify(values.jsonImagem),
            jsonPagina: JSON.stringify(values.jsonPagina),
            jsonGestaoPatrimonial: JSON.stringify(values.jsonGestaoPatrimonial),
            jsonPagamento: JSON.stringify(values.jsonPagamento),
            jsonUsuario: JSON.stringify(values.jsonUsuario)
        }

        const response = await configuracaoService.atualizarConfiguracaoUsuario(variaveisGlobais, configuracaoUsuario)

        values.idConfiguracao = response.data

        return true

    } catch (erro) {

        mensagemErroErro(erro)

        return false
    }
}

export const deleteEquipe = async ( variaveisGlobais, idEquipe ) => {

    const usuarioService = new UsuarioService()

    try {
        await usuarioService.deletarEquipe(variaveisGlobais, AuthService.obterUsuarioLogado(variaveisGlobais), idEquipe)

        return true

    } catch (erro) {

        mensagemErroErro(erro)

        return false
    }
}



export const obtemPaletaCores = (jsonConfiguracao, jsonUsuario) => {

    let idWhiteLabel = HARDCODE.idWhiteLabelPadrao

    if (jsonUsuario && jsonUsuario.idWhiteLabel) {

        idWhiteLabel = jsonUsuario.idWhiteLabel
    }

    return pesquisaList(jsonConfiguracao.whiteLabel, idWhiteLabel).paletaCores
}

export const calculaPaletaCores = (jsonConfiguracao) => {

    const paletaCores = jsonConfiguracao.paletaCores ? jsonConfiguracao.paletaCores : []

    if (jsonConfiguracao.cores) {

        if (jsonConfiguracao.cores.corPadrao1) {

            paletaCores.push(
                {
                    id: HARDCODE.corPadrao1,
                    cor: jsonConfiguracao.cores.corPadrao1
                },
            )
        }
        
        if (jsonConfiguracao.cores.corPadrao2) {

            paletaCores.push(
                {
                    id: HARDCODE.corPadrao2,
                    cor: jsonConfiguracao.cores.corPadrao2
                },
            )
        }
          
        if (jsonConfiguracao.cores.corPadrao3) {

            paletaCores.push(
                {
                    id: HARDCODE.corPadrao3,
                    cor: jsonConfiguracao.cores.corPadrao3
                },
            )
        }

        if (jsonConfiguracao.cores.corPadrao4) {

            paletaCores.push(
                {
                    id: HARDCODE.corPadrao4,
                    cor: jsonConfiguracao.cores.corPadrao4
                },
            )
        }

        if (jsonConfiguracao.cores.corPadrao5) {

            paletaCores.push(
                {
                    id: HARDCODE.corPadrao5,
                    cor: jsonConfiguracao.cores.corPadrao5
                },
            )
        }

        if (jsonConfiguracao.estudoReportCapaExibirTextoCorBranca) {

            paletaCores.push(
                {
                    id: HARDCODE.corPadrao7,
                    cor: HARDCODE.corPadraoBranca,
                },
            )
        }
    }

    return paletaCores
    /*
    if (
            !cores ||
            JSON.stringify(cores) === JSON.stringify(HARDCODE.coresOld) ||
            JSON.stringify(cores) === JSON.stringify(HARDCODE.coresOld2)
    ) {

        cores = HARDCODE.cores
    }

    if (!cores.corPadrao4) {

        cores.corPadrao4 = HARDCODE.cores.corPadrao4
    }

    if (!cores.corPadrao5) {

        cores.corPadrao5 = HARDCODE.cores.corPadrao5
    }

    return [
        {
            id: HARDCODE.corPadrao1,
            cor: cores.corPadrao1
        },
        {
            id: HARDCODE.corPadrao2,
            cor: cores.corPadrao2
        },
        {
            id: HARDCODE.corPadrao3,
            cor: cores.corPadrao3
        },
        {
            id: HARDCODE.corPadrao4,
            cor: cores.corPadrao4
        },
        {
            id: HARDCODE.corPadrao5,
            cor: cores.corPadrao5
        },
        {
            id: HARDCODE.corPadrao6,
            cor: cores.corPadrao5
        },
    ]
    */
}

export const identificaIndexIdWhiteLabel = ( jsonConfiguracao, jsonUsuario ) => {

    let indexIdWhiteLabel

    if (jsonUsuario) {

        indexIdWhiteLabel = pesquisaIndexList(jsonConfiguracao.whiteLabel,
            jsonUsuario.idWhiteLabel ? jsonUsuario.idWhiteLabel : HARDCODE.idWhiteLabelPadrao)

    } else {

        indexIdWhiteLabel = pesquisaIndexList(jsonConfiguracao.whiteLabel, jsonConfiguracao.idWhiteLabel)
    }

    if (!indexIdWhiteLabel && indexIdWhiteLabel !== 0) {

        indexIdWhiteLabel = pesquisaIndexList(jsonConfiguracao.whiteLabel, HARDCODE.idWhiteLabelPadrao)
    }

    return indexIdWhiteLabel
}

export const ajustaInclusoesAlteracoesExclusoesJsonConfiguracao = ( jsonConfiguracao ) => {

    if (!jsonConfiguracao) {

        jsonConfiguracao = {}
    }

    if (!jsonConfiguracao.numeroCartaoCredito) {
        jsonConfiguracao.numeroCartaoCredito = ''
    }

    if (!jsonConfiguracao.mesAnoExpiracaoCartaoCredito) {
        jsonConfiguracao.mesAnoExpiracaoCartaoCredito = ''
    }
    
    if (!jsonConfiguracao.cvcCartaoCredito) {
        jsonConfiguracao.cvcCartaoCredito = ''
    }

    if (!jsonConfiguracao.nomeCartaoCredito) {
        jsonConfiguracao.nomeCartaoCredito = ''
    }
    
    if (!jsonConfiguracao.cpfCartaoCredito) {
        jsonConfiguracao.cpfCartaoCredito = ''
    }

    if (!jsonConfiguracao.historicoAssinatura) {
        jsonConfiguracao.historicoAssinatura = []
    }

    if (jsonConfiguracao.filiaisUnidades) {
        jsonConfiguracao.filiaisUnidades = sortAsc(jsonConfiguracao.filiaisUnidades)
    }

    //if (!jsonConfiguracao.tamanhoLogo) {
    //    jsonConfiguracao.tamanhoLogo = {}
    //}

    if (!jsonConfiguracao.whiteLabel) {

        let paletaCores = jsonConfiguracao.paletaCores

        if (paletaCores) {

            jsonConfiguracao.paletaCores = ''

        } else {

            paletaCores = calculaPaletaCores(jsonConfiguracao)
    
            if (jsonConfiguracao.cores) {
    
                jsonConfiguracao.cores = ''
            }
            
            jsonConfiguracao.estudoReportCapaExibirTextoCorBranca = ''
        }
    
        if (jsonConfiguracao.estudoReportCapaExibirTextoCorBranca) {
    
            paletaCores.push(
                {
                    id: HARDCODE.corPadrao7,
                    cor: HARDCODE.corPadraoBranca,
                },
            )
    
            jsonConfiguracao.estudoReportCapaExibirTextoCorBranca = ''
        }

        jsonConfiguracao.contWhiteLabel = HARDCODE.idWhiteLabelPadrao
        jsonConfiguracao.idWhiteLabel = HARDCODE.idWhiteLabelPadrao
        jsonConfiguracao.whiteLabel = [
            {
                id: HARDCODE.idWhiteLabelPadrao,
                descricao: LABEL.padrao,
                tamanhoLogo: jsonConfiguracao.tamanhoLogo ? jsonConfiguracao.tamanhoLogo : {},
                exibirLogoTopbarParceiros: jsonConfiguracao.exibirLogoTopbarParceiros,
                naoExibirLogoCapaRelatorio: jsonConfiguracao.naoExibirLogoCapaRelatorio,
                alinhamentoLogoCapaRelatorio: jsonConfiguracao.alinhamentoLogoCapaRelatorio,

                naoExibirAvatarClienteConjuge: jsonConfiguracao.naoExibirAvatarClienteConjuge,

                backgroundCapaRelatorioPaginaInteira: jsonConfiguracao.backgroundCapaRelatorioPaginaInteira,
                backgroundCapaRelatorioPaisagemPaginaInteira: jsonConfiguracao.backgroundCapaRelatorioPaisagemPaginaInteira,

                estudoReportCapaTitulo1: jsonConfiguracao.estudoReportCapaTitulo1,
                estudoReportCapaTitulo1MargemTopo: jsonConfiguracao.estudoReportCapaTitulo1MargemTopo,
                estudoReportCapaNaoExibirTitulo1: jsonConfiguracao.estudoReportCapaNaoExibirTitulo1,
                estudoReportCapaNaoExibirUsuario: jsonConfiguracao.estudoReportCapaNaoExibirUsuario,
                estudoReportCapaNaoExibirProfissaoQualificacao: jsonConfiguracao.estudoReportCapaNaoExibirProfissaoQualificacao,
                estudoReportCapaClienteConjugeTitulo2MargemTopo: jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemTopo,
                estudoReportCapaClienteConjugeTitulo2MargemEsquerda:
                    jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemEsquerda,
                estudoReportCapaNaoExibirClienteConjuge: jsonConfiguracao.estudoReportCapaNaoExibirClienteConjuge,
                estudoReportCapaTitulo2: jsonConfiguracao.estudoReportCapaTitulo2,
                estudoReportCapaNaoExibirTitulo2: jsonConfiguracao.estudoReportCapaNaoExibirTitulo2,

                backgroundPaginaRelatorioNaoExibirLogoCabecalho: jsonConfiguracao.backgroundPaginaRelatorioNaoExibirLogoCabecalho,
                backgroundPaginaRelatorioNaoExibirLinhaDivisoriaCabecalho:
                    jsonConfiguracao.backgroundPaginaRelatorioNaoExibirLinhaDivisoriaCabecalho,
                backgroundPaginaRelatorioPaisagemNaoExibirLogoCabecalho:
                    jsonConfiguracao.backgroundPaginaRelatorioPaisagemNaoExibirLogoCabecalho,
                backgroundPaginaRelatorioPaisagemNaoExibirLinhaDivisoriaCabecalho:
                    jsonConfiguracao.backgroundPaginaRelatorioPaisagemNaoExibirLinhaDivisoriaCabecalho,
                paletaCores: paletaCores,
            }
        ]


        if (jsonConfiguracao.tamanhoLogo) {
        
            jsonConfiguracao.tamanhoLogo = ''
        }

        if (jsonConfiguracao.exibirLogoTopbarParceiros === true || jsonConfiguracao.exibirLogoTopbarParceiros === false) {
        
            jsonConfiguracao.exibirLogoTopbarParceiros = ''
        }

        if (jsonConfiguracao.naoExibirLogoCapaRelatorio === true || jsonConfiguracao.naoExibirLogoCapaRelatorio === false) {
        
            jsonConfiguracao.naoExibirLogoCapaRelatorio = ''
        }

        if (jsonConfiguracao.alinhamentoLogoCapaRelatorio === true || jsonConfiguracao.alinhamentoLogoCapaRelatorio === false) {
        
            jsonConfiguracao.alinhamentoLogoCapaRelatorio = ''
        }
        


        if (jsonConfiguracao.naoExibirAvatarClienteConjuge === true || jsonConfiguracao.naoExibirAvatarClienteConjuge === false) {
        
            jsonConfiguracao.naoExibirAvatarClienteConjuge = ''
        }



        if (
                jsonConfiguracao.backgroundCapaRelatorioPaginaInteira === true ||
                jsonConfiguracao.backgroundCapaRelatorioPaginaInteira === false
        ) {
        
            jsonConfiguracao.backgroundCapaRelatorioPaginaInteira = ''
        }

        if (
                jsonConfiguracao.backgroundCapaRelatorioPaisagemPaginaInteira === true ||
                jsonConfiguracao.backgroundCapaRelatorioPaisagemPaginaInteira === false
        ) {
        
            jsonConfiguracao.backgroundCapaRelatorioPaisagemPaginaInteira = ''
        }
        


        if (
                jsonConfiguracao.estudoReportCapaTitulo1 === true ||
                jsonConfiguracao.estudoReportCapaTitulo1 === false
        ) {
        
            jsonConfiguracao.estudoReportCapaTitulo1 = ''
        }
        
        if (
                jsonConfiguracao.estudoReportCapaTitulo1MargemTopo === true ||
                jsonConfiguracao.estudoReportCapaTitulo1MargemTopo === false
        ) {
        
            jsonConfiguracao.estudoReportCapaTitulo1MargemTopo = ''
        }
        
        if (
                jsonConfiguracao.estudoReportCapaNaoExibirTitulo1 === true ||
                jsonConfiguracao.estudoReportCapaNaoExibirTitulo1 === false
        ) {
        
            jsonConfiguracao.estudoReportCapaNaoExibirTitulo1 = ''
        }
        
        if (
                jsonConfiguracao.estudoReportCapaNaoExibirUsuario === true ||
                jsonConfiguracao.estudoReportCapaNaoExibirUsuario === false
        ) {
        
            jsonConfiguracao.estudoReportCapaNaoExibirUsuario = ''
        }
        
        if (
                jsonConfiguracao.estudoReportCapaNaoExibirProfissaoQualificacao === true ||
                jsonConfiguracao.estudoReportCapaNaoExibirProfissaoQualificacao === false
        ) {
        
            jsonConfiguracao.estudoReportCapaNaoExibirProfissaoQualificacao = ''
        }
        
        if (
                jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemTopo === true ||
                jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemTopo === false
        ) {
        
            jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemTopo = ''
        }
        
        if (
                jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemEsquerda === true ||
                jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemEsquerda === false
        ) {
        
            jsonConfiguracao.estudoReportCapaClienteConjugeTitulo2MargemEsquerda = ''
        }
        
        if (
                jsonConfiguracao.estudoReportCapaNaoExibirClienteConjuge === true ||
                jsonConfiguracao.estudoReportCapaNaoExibirClienteConjuge === false
        ) {
        
            jsonConfiguracao.estudoReportCapaNaoExibirClienteConjuge = ''
        }
        
        if (
                jsonConfiguracao.estudoReportCapaTitulo2 === true ||
                jsonConfiguracao.estudoReportCapaTitulo2 === false
        ) {
        
            jsonConfiguracao.estudoReportCapaTitulo2 = ''
        }
        
        if (
                jsonConfiguracao.estudoReportCapaNaoExibirTitulo2 === true ||
                jsonConfiguracao.estudoReportCapaNaoExibirTitulo2 === false
        ) {
        
            jsonConfiguracao.estudoReportCapaNaoExibirTitulo2 = ''
        }



        if (
                jsonConfiguracao.backgroundPaginaRelatorioNaoExibirLogoCabecalho === true ||
                jsonConfiguracao.backgroundPaginaRelatorioNaoExibirLogoCabecalho === false
        ) {
        
            jsonConfiguracao.backgroundPaginaRelatorioNaoExibirLogoCabecalho = ''
        }

        if (
                jsonConfiguracao.backgroundPaginaRelatorioNaoExibirLinhaDivisoriaCabecalho === true ||
                jsonConfiguracao.backgroundPaginaRelatorioNaoExibirLinhaDivisoriaCabecalho === false
        ) {
        
            jsonConfiguracao.backgroundPaginaRelatorioNaoExibirLinhaDivisoriaCabecalho = ''
        }

        if (
                jsonConfiguracao.backgroundPaginaRelatorioPaisagemNaoExibirLogoCabecalho === true ||
                jsonConfiguracao.backgroundPaginaRelatorioPaisagemNaoExibirLogoCabecalho === false
        ) {
        
            jsonConfiguracao.backgroundPaginaRelatorioPaisagemNaoExibirLogoCabecalho = ''
        }

        
        if (
                jsonConfiguracao.backgroundPaginaRelatorioPaisagemNaoExibirLinhaDivisoriaCabecalho === true ||
                jsonConfiguracao.backgroundPaginaRelatorioPaisagemNaoExibirLinhaDivisoriaCabecalho === false
        ) {
        
            jsonConfiguracao.backgroundPaginaRelatorioPaisagemNaoExibirLinhaDivisoriaCabecalho = ''
        }
    }

    return jsonConfiguracao
}

export const ajustaInclusoesAlteracoesExclusoesJsonImagem = ( jsonImagem ) => {

    return jsonImagem
}

export const ajustaInclusoesAlteracoesExclusoesJsonPagina = ( jsonPagina ) => {

    return jsonPagina
}

export const ajustaInclusoesAlteracoesExclusoesJsonGestaoPatrimonial = ( jsonGestaoPatrimonial ) => {

    return jsonGestaoPatrimonial
}

export const ajustaInclusoesAlteracoesExclusoesJsonPagamento = ( jsonPagamento ) => {

    return jsonPagamento
}

export const ajustaInclusoesAlteracoesExclusoesJsonUsuario = ( jsonUsuario ) => {

    if (!jsonUsuario) {

        jsonUsuario = {}
    }
    return jsonUsuario
}

export const ajustaInclusoesAlteracoesExclusoesJsonUsuarioAdmin = ( jsonUsuarioAdmin ) => {

    return jsonUsuarioAdmin
}
import HARDCODE from '../../business-const/HardCode/HardCode'



export const celularOK = (celular, validarTamanho11) => {
    
    return HARDCODE.regExpValidaCelular.test(celular) && celular.length >= 10 &&
        (!validarTamanho11 || (validarTamanho11 && celular.length === 11))
}

export const emailOK = (email) => {
    
    return HARDCODE.regExpValidaEmail.test(email)
}
import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    obtemTextoArray,
} from '../../../business-components/Array/Array'

import {
    trataValorTexto,
    trataData,
} from '../cliente-form/ClienteServiceImportacao'

import {
    espaco,
    fimLinha,
    identificaTipoCoberturaSeguroVida,
    obtemDadoPDF,
    obtemDadoPDFAnterior,
    indexAtualJsonRegistros,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosAtualizaErros,
    incluiCoberturas,
} from './IntegracaoClienteArquivoService'



export const importaApolicePortoSeguro = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)



    //PDFTxt = PDFTxt.replaceAll('R$R$', espaco + 'R$R$')


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'APÓLICE Nº' + espaco, espaco,
        HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'PROPOSTA Nº' + espaco, espaco + 'FOLHA',
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'VIGÊNCIA DO SEGURO: A PARTIR DAS 24 HORAS DO DIA' + espaco, espaco, 
        HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'ATÉ ÀS 24 HORAS DO DIA' + espaco, '.' + fimLinha,
        HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)

    const dataTerminoApolice = trataData(jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados)
    
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'NOME SOCIAL' + fimLinha + 'CORREIO' + fimLinha + 'NOME' + espaco, fimLinha, 
        HARDCODE.importacaoNomeSeguradoCliente, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].nomeSeguradoCliente) {

        obtemDadoPDFAnterior(PDFTxt, jsonRegistros, fimLinha + 'RG' + espaco, fimLinha + 'NOME' + espaco, 
             HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)
    }


    obtemDadoPDFAnterior(PDFTxt, jsonRegistros, 'CPF/CNPJ' + fimLinha, fimLinha, 
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAnterior(PDFTxt, jsonRegistros, 'ENDEREÇO' + fimLinha, fimLinha,
        HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAnterior(PDFTxt, jsonRegistros, 'CEP' + fimLinha, fimLinha,
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)
    
    //obtemDadoPDFAvulso (jsonRegistros, linhaDados[tamLinhaDados - 2],
    //    HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + '02 Endereço Residencial' + fimLinha + 'Cidade' + fimLinha, fimLinha,
    //    HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)
        
    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Bairro' + fimLinha, fimLinha,
    //    HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'DATA NASCIMENTO' + espaco, fimLinha, 
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone' + fimLinha, fimLinha,
    //    HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Email' + fimLinha, fimLinha,
    //    HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo' + fimLinha, fimLinha,
    //    HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
    
    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Profissão' + fimLinha, fimLinha, 
    //    HARDCODE.importacaoProfissaoSegurado, false, null, erros, nomeArquivo)

   

    jsonRegistros.jsonRegistros[indexJsonRegistros].statusApolice = HARDCODE.idStatusApoliceAtiva
    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Status da Apólice:' + espaco, fimLinha,
    //    HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'RAMO GARANTIAS CAPITAIS SEGURADOS PRÊMIOS' + fimLinha +
        'DADOS DO SEGURO' + fimLinha, fimLinha + 'RAMO GARANTIAS' + fimLinha + 'CARÊNCIAS E FRANQUIAS',
            HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'RAMO GARANTIAS CAPITAIS SEGURADOS PRÊMIOS' + fimLinha +
            'DADOS DO SEGURO' + fimLinha, fimLinha + 'CARÊNCIAS E FRANQUIAS',
                HARDCODE.importacaoCoberturas, true, null, erros, nomeArquivo)
    }

    jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeMensal
    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha +
    //    'FORMA DE PAGAMENTO PARCELA DATA VENCIMENTO I.O.F. VALOR DAS PARCELASQTDE. PARC.' + fimLinha, espaco + 'ADC' + espaco,
    //        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, false, null, erros, nomeArquivo)


    
    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll('R$R$', espaco + 'R$R$')

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
            '1391 MORTE (*)' + espaco,
                '1391 MORTE NATURAL OU ACIDENTAL' + espaco)

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
            fimLinha + '1381 INVALIDEZ PERMANENTE TOTAL OU' + fimLinha + 'PARCIAL POR ACIDENTE AT(***)' + fimLinha,
                fimLinha + '1381 INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR ACIDENTE' + espaco)

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
            fimLinha + '1381 INVALIDEZ PERMANENTE TOTAL OU' + fimLinha + 'PARCIAL POR ACIDENTE ATÉ(***)' + fimLinha,
                fimLinha + '1381 INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR ACIDENTE' + espaco)

    //const linhasCobertura = []
    const linhasCobertura = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)
    let indexLinhasCobertura = 0

    //const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)
    //let indexLinhasPosicaoAux = 0

    const coberturas = []

    let dadosCobertura

    let numeroCobertura
    let codigoCobertura
    let descricaoCobertura
    let tipoCoberturaSeguroVida
    let valorCS
    let valorPremioLiquido
    let valorIof
    let valorPremio
    let classeAjusteRisco
    let valorPremioExtra
    let duracaoPremioExtra
    let dataTerminoCobertura
    let valorRendaMensal

    //while (indexLinhasPosicaoAux < linhasCoberturaAux.length) {

    //    if (linhasCoberturaAux[indexLinhasPosicaoAux].indexOf('R$') !== -1) {

    //        linhasCobertura.push(linhasCoberturaAux[indexLinhasPosicaoAux])
    //    }

    //    indexLinhasPosicaoAux += 1
    //}

    while (indexLinhasCobertura < linhasCobertura.length) {

        dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)

        let tamDadosCobertura = dadosCobertura.length

        codigoCobertura = dadosCobertura[0]
        descricaoCobertura = obtemTextoArray(dadosCobertura, 1, tamDadosCobertura - 4)

        valorCS = trataValorTexto(dadosCobertura[tamDadosCobertura - 3])
        valorPremio = trataValorTexto(dadosCobertura[tamDadosCobertura - 2])

        dataTerminoCobertura = dataTerminoApolice

        tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
            jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

        incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
            HARDCODE.idStatusCoberturaAtivaPagandoPremio, valorCS, valorPremioLiquido, valorIof, valorPremio,
                classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
        
        indexLinhasCobertura++
    }

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas


    
    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}



export const importaConsorcioPortoSeguro = (PDFTxt, jsonUsuario, linhaIntegracao, seguradora, nomeArquivo, erros, tipoConsorcio) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'TIPO DO DOCUMENTO Nº DO DOCUMENTO ORGÃO EMISSOR/UF DATA DE EMISSÃO CPF' + fimLinha, fimLinha,
        HARDCODE.importacaoNumeroConsorcio, true, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].tipoConsorcio = tipoConsorcio

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoValorCreditoConsorcio, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, espaco, HARDCODE.importacaoGrupoConsorcio, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco, espaco, HARDCODE.importacaoCotaConsorcio, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco, espaco, HARDCODE.importacaoPessoaFisicaJuridica, true, null, erros, nomeArquivo)
       
    obtemDadoPDF(PDFTxt, jsonRegistros, espaco, fimLinha, HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

    jsonRegistros.indexInicial = PDFTxt.indexOf('/', jsonRegistros.indexInicial)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco, fimLinha, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'S - SOLTEIRO - C - CASADO - D - DIVORCIADO - V - VIÚVO' + fimLinha, espaco, 
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)
        
    obtemDadoPDF(PDFTxt, jsonRegistros, espaco, fimLinha, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    jsonRegistros.indexInicial = PDFTxt.indexOf(fimLinha, jsonRegistros.indexInicial) + 1

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'E-MAIL AUTORIZO MENSAGENS VIA SMS' + fimLinha, espaco,
        HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'NOME DA EMPRESA ONDE TRABALHA' + fimLinha, espaco, 
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + '4. DADOS DO GRUPO' + fimLinha, fimLinha,
        HARDCODE.importacaoStatusGrupoConsorcio, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'PARCELA INICIAL FORMA DE PAGAMENTO' + fimLinha, fimLinha,
        HARDCODE.importacaoTipoFormaPagtoConsorcio, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'POR E-MAIL' + fimLinha, espaco,
        HARDCODE.importacaoDataAssembleia, true, null, erros, nomeArquivo)
    
    jsonRegistros.indexInicial = PDFTxt.indexOf('/', jsonRegistros.indexInicial)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco, fimLinha,
        HARDCODE.importacaoNumeroAssembleiaParticipacao, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha,
        HARDCODE.importacaoValorParcelaInicial, true, null, erros, nomeArquivo)

    jsonRegistros.indexInicial = PDFTxt.indexOf('NÃO CONCORDO', jsonRegistros.indexInicial) + 'NÃO CONCORDO'.length

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco, espaco,
        HARDCODE.importacaoNumeroTotalParticipantesGrupoConsorcio, true, null, erros, nomeArquivo)

    jsonRegistros.indexInicial = PDFTxt.indexOf('% ', jsonRegistros.indexInicial) + 1

    jsonRegistros.indexInicial = PDFTxt.indexOf('% ', jsonRegistros.indexInicial)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco, '%', HARDCODE.importacaoTaxaAdministracaoTotal, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, '%', fimLinha, HARDCODE.importacaoPrazoTotalGrupoConsorcio, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'RESERVA²' + fimLinha, '%',
        HARDCODE.importacaoPercFundoReserva, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco, '%' + fimLinha, HARDCODE.importacaoPercSeguroPrestamista, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'LANCE EMBUTIDO LANCE FIXO' + fimLinha, '%',
        HARDCODE.importacaoPercLanceEmbutido, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco, '%', HARDCODE.importacaoPercLanceEmbutido, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco, '%', HARDCODE.importacaoPercLanceFixo, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + '(+) Outros Acréscimos' + fimLinha, fimLinha,
        HARDCODE.importacaoDataVenctoBoleto, true, null, erros, nomeArquivo)
    
    /*
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo' + fimLinha, fimLinha,
        HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, espaco, HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, espaco, espaco, HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, espaco, fimLinha, HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Profissão / ocupação profissional' + fimLinha, fimLinha,
        HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)
    */



    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
    


    return jsonRegistros.jsonRegistros
}

export const importaConsorcioBensImoveisPortoSeguro = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao,
    seguradora, nomeArquivo, erros, variaveisGlobais) => {

    return importaConsorcioPortoSeguro(PDFTxt, jsonUsuario, linhaIntegracao, seguradora, nomeArquivo, erros,
        HARDCODE.idTipoConsorcioBensImoveis)
}
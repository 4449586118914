import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

import AuthService from './AuthService'

import {
    usuarioInfo,
} from './UsuarioService'

import {
    ano,
    mes,
    dia,
} from '../business-components/Date/Date'



class VwClienteAcessoFamiliaService extends ApiService {

    constructor() {
        super(HARDCODE.apiVwClienteAcessoFamilia)
    }
    /*
    static usuarioSelecionadoList() {

        let usuarioSelecionadoListAux = []

        this.obterUsuarioSelecionado(variaveisGlobais)?.forEach( (linhaUsuarioSelecionado, index) => {

            usuarioSelecionadoListAux.push(
                {
                    id: linhaUsuarioSelecionado.id,
                }
            )
        })

        return encodeURIComponent(JSON.stringify(usuarioSelecionadoListAux))
    }
    */

    async buscarClienteList(variaveisGlobais, usuarioClienteSelecionadoNULL, nomeNULL, nascimentoNULL, sexoNULL, cpfNULL,
        mesDiaAniversarioInicialNULL, mesDiaAniversarioFinalNULL) {

        /*
        let params = `idUsuario=${AuthService.obterUsuarioLogado(variaveisGlobais)}`
        
        if (usuarioClienteSelecionadoNULL) {

            if (usuarioClienteSelecionadoNULL === HARDCODE.usuarioClienteSelecionadoGetFamiliaClienteList) {
                
                params = `${params}&jsonUsuarioSelecionado=${HARDCODE.usuarioClienteSelecionadoGetFamiliaClienteList}`

            } else {

                params = `${params}&jsonUsuarioSelecionado=${AuthService.usuarioClienteSelecionadoList(usuarioClienteSelecionadoNULL)}`
            }

        } else {

            params = `${params}&jsonUsuarioSelecionado=${AuthService.usuarioSelecionadoList(variaveisGlobais)}`
        }
        
        if (nomeNULL) {
            
            params = `${params}&nome=${nomeNULL}`
        }

        if (nascimentoNULL) {

            params = `${params}&nascimentoAno=${ano(nascimentoNULL)}`
            params = `${params}&nascimentoMes=${mes(nascimentoNULL)}`
            params = `${params}&nascimentoDia=${dia(nascimentoNULL)}`
        }

        if (sexoNULL) {
            
            params = `${params}&sexo=${sexoNULL}`
        }

        if (cpfNULL) {
            
            params = `${params}&cpf=${cpfNULL}`
        }

        if (mesDiaAniversarioInicialNULL) {
            
            params = `${params}&mesDiaAniversarioInicial=${mesDiaAniversarioInicialNULL}`
        }

        if (mesDiaAniversarioFinalNULL) {
            
            params = `${params}&mesDiaAniversarioFinal=${mesDiaAniversarioFinalNULL}`
        }

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoFamiliaBuscarClienteList}/?${params}`, paramsAux, false)
        */

        const body = {
            getDTO: {
                idUsuario: AuthService.obterUsuarioLogado(variaveisGlobais),
                jsonUsuarioSelecionado:
                    usuarioClienteSelecionadoNULL
                        ?
                            usuarioClienteSelecionadoNULL === HARDCODE.usuarioClienteSelecionadoGetFamiliaClienteList
                                ?
                                    HARDCODE.usuarioClienteSelecionadoGetFamiliaClienteList
                                :
                                    AuthService.usuarioClienteSelecionadoListString(usuarioClienteSelecionadoNULL)
                        :
                            AuthService.usuarioSelecionadoListString(variaveisGlobais),
                nome: nomeNULL ? nomeNULL : null,
                nascimentoAno: nascimentoNULL ? ano(nascimentoNULL) : null,
                nascimentoMes: nascimentoNULL ? mes(nascimentoNULL) : null,
                nascimentoDia: nascimentoNULL ? dia(nascimentoNULL) : null,
                sexo: sexoNULL ? sexoNULL : null,
                cpf: cpfNULL ? cpfNULL : null,
                mesDiaAniversarioInicial: mesDiaAniversarioInicialNULL ? mesDiaAniversarioInicialNULL : null,
                mesDiaAniversarioFinal: mesDiaAniversarioFinalNULL ? mesDiaAniversarioFinalNULL : null,
            },
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoFamiliaBuscarClienteList, body, false)
    }

    async buscarFamiliaList(variaveisGlobais, filtroList) {

        /*
        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, null, filtroList, true)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoFamiliaBuscarFamiliaList}/?${params}`, paramsAux, false)
        */

        const body = await this.calculaBodyFiltroList(variaveisGlobais, null, filtroList, true)

        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoFamiliaBuscarFamiliaList, body, false)
    }
    
    async sumFamilia(variaveisGlobais, campoAgrupamento, filtroList) {

        /*
        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, campoAgrupamento, filtroList, true)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoFamiliaSumFamilia}/?${params}`, paramsAux, false)
        */

        const body = await this.calculaBodyFiltroList(variaveisGlobais, campoAgrupamento, filtroList, true)

        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoFamiliaSumFamilia, body, false)
    }
}

export default VwClienteAcessoFamiliaService
import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'



class VwClienteAcessoApoliceComissaoService extends ApiService {

    constructor() {
        super(HARDCODE.apiVwClienteAcessoApoliceComissao)
    }

    async buscarApoliceComissaoList(variaveisGlobais, filtroList) {

        /*
        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, null, filtroList, true)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoApoliceComissaoBuscarApoliceComissaoList}/?${params}`,
            paramsAux, false)
        */

        const body = await this.calculaBodyFiltroList(variaveisGlobais, null, filtroList, true)

        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoApoliceComissaoBuscarApoliceComissaoList, body, false)
    }
    
    async sumApoliceComissao(variaveisGlobais, campoAgrupamento, filtroList) {

        /*
        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, campoAgrupamento, filtroList, true)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoApoliceComissaoSumApoliceComissao}/?${params}`,
            paramsAux, false)
        */

        const body = await this.calculaBodyFiltroList(variaveisGlobais, campoAgrupamento, filtroList, true)

        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoApoliceComissaoSumApoliceComissao, body, false)
    }
}

export default VwClienteAcessoApoliceComissaoService
import React from 'react'

import { 
    IsSmDownScreen,
} from '../../business-components/Service/GlobalService'

import { 
    TextValidator,
} from 'react-material-ui-form-validator'

import {
    STYLE
} from '../../business-const/Style/Style'

import NUMMIN from '../../business-rules/NumMinMax/NumMin'

import NUMMAX from '../../business-rules/NumMinMax/NumMax'

import TAMMAX from '../../business-rules/TamMax/TamMax'

import MESSAGE from '../../business-const/Message/Message'

import {
    mesMesAno,
    anoMesAno,
    diaDiaMes,
    mesDiaMes,
} from '../../business-components/Date/Date'

import {
    moedaPrefixSuffix
} from '../../business-components/Formata/Formata'

import {
    NumberFormatPadrao
} from '../../business-components/NumberFormat/NumberFormat'

import {
    celularOK,
    emailOK,
} from '../../business-components/RegExp/RegExp'



// Base
// ----

export const TextValidatorBase = props => {

    const { report, ...rest} = props

    return (
        <TextValidator
            className="mt-2 mb-2 w-full"
            size="medium"
            variant="outlined"
            InputProps={{
                style: STYLE(props.destaque, report ? false : props.disabled).styleTextField
            }}
            InputLabelProps={{
                style: props.value || props.value === 0 ? STYLE(false, props.disabled).styleLabelTextFieldWithValue : STYLE(false, props.disabled).styleLabelTextField
            }}
            FormHelperTextProps={{
                style: STYLE(false, props.disabled).styleHelperText
            }}
            {...rest}
        />
    )
}



// Text
// ----

export const TextValidatorText = props => {

    const { tamMax, value, onChange, notToUpperCase, ...rest } = props

    if (!notToUpperCase && typeof value === "string" && value && value !== value.toUpperCase()) {
        onChange({
            target: {
                name: rest.name,
                value: value.toUpperCase(),
            },
        })
    }

    return (
        <TextValidatorBase
            type="text"
            multiline={true}
            //inputProps={{
            //    style: {
            //        textTransform: 'uppercase',
            //    },
            //}}
            value={!notToUpperCase && typeof value === "string" ? value.toUpperCase() : value}
            onChange={({ target: { value }, }) =>
                onChange({
                    target: {
                        name: rest.name,
                        value: !notToUpperCase ? value.toUpperCase() : value,
                    },
                })
            }
            validators={
                !tamMax ? 
                    [] 
                : 
                    [
                        `maxStringLength: ${tamMax}`,
                    ]
            }
            errorMessages={[
                MESSAGE().tamanhoMaximo.replace( "$tamMax$", tamMax ),
            ]}
            {...rest}
            
        />
    )
}

export const TextValidatorTextDestaque = props => {
    return (
        <TextValidatorText
            destaque={'sim'}
            {...props}
        />
    )
}

export const TextValidatorTextMultiline = props => {
    return (
        <TextValidatorText
            multiline={true}
            //inputProps={{
            //    style: {
            //        textTransform: 'none',
            //    },
            //}}
            {...props}
        />
    )
}

export const TextValidatorTextTable = props => {
    return (
        <TextValidatorText
            className=""    
            fullWidth
            {...props}
        />
    )
}

export const TextValidatorTextRequired = props => {
    return (
        <TextValidatorText
            helperText={props.value ? '' : MESSAGE().campoObrigatorio}
            {...props}
        />
    )
}

export const TextValidatorTextRequiredDestaque = props => {
    return (
        <TextValidatorTextRequired
            destaque={'sim'}
            {...props}
        />
    )
}

export const TextValidatorTextRequiredTable = props => {
    return (
        <TextValidatorTextRequired
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Number
// ------

export const TextValidatorNumber = props => {
    const { numMin, numMax, ...rest } = props

    return (
        <TextValidatorBase
            type="number"
            validators={[
                `minNumber: ${numMin}`, 
                `maxNumber: ${numMax}`,
            ]}
            errorMessages={[
                MESSAGE().numeroMinimo.replace( "$numMin$", numMin),
                MESSAGE().numeroMaximo.replace( "$numMax$", numMax),
            ]}
            {...rest}
        />
    )
}

export const TextValidatorNumberTable = props => {
    return (
        <TextValidatorNumber
            className=""    
            fullWidth
            {...props}
        />
    )
}

export const TextValidatorNumberRequired = props => {
    return (
        <TextValidatorNumber
            helperText={props.value === 0 ? '' : props.value ? '' : MESSAGE().campoObrigatorio}
            {...props}
        />
    )
}



// Valor
// -----

function NumberFormatValor(props) {

    const { prefix, suffix } = moedaPrefixSuffix()

    return (
        <NumberFormatPadrao
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
            prefix={props.value > 99999999 && IsSmDownScreen()  ? "" : prefix}
            suffix={props.value > 99999999 && IsSmDownScreen()  ? "" : suffix}
            {...props}
        />
    )
}

export const TextValidatorValor = props => {

    const { report, ...rest} = props

    return (
        <TextValidatorText
            tamMax={''}
            InputProps={{
                inputComponent: NumberFormatValor,
                style: STYLE(false, report ? false : props.disabled).styleTextField
            }}
            {...rest}
        />
    )
}

export const TextValidatorValorRequired = props => {
    return (
        <TextValidatorValor
            helperText={props.value || props.value === 0 ? '' : MESSAGE().campoObrigatorio}
            {...props}
        />
    )
}

export const TextValidatorValorTable = props => {
    return (
        <TextValidatorValor
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Altura
// ------

function NumberFormatAltura(props) {
    return (
        <NumberFormatPadrao
            format="###"
            {...props}
        />
    )
}

export const TextValidatorAltura = props => {
    return (
        <TextValidatorText
            tamMax={TAMMAX.altura}
            InputProps={{
                inputComponent: NumberFormatAltura,
                style: STYLE(false, props.disabled).styleTextField
            }}
            {...props}
        />
    )
}

export const TextValidatorAlturaTable = props => {
    return (
        <TextValidatorAltura
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Cpf
// ---

function NumberFormatCpf(props) {
    return (
      <NumberFormatPadrao
        format="###.###.###-##"
        {...props}
      />
    );
}

const cpfValido = ( cpf ) => {
    let cpfOK = true

    if (cpf.length > 0) {

        cpfOK = false

        if (cpf.length === 11) {

            const digitoDigitado = Number(cpf.substr(9, 1) + cpf.substr(10, 1))

            let soma1 = 0
            let soma2 = 0

            let valor = 10

            for(let i = 0; i < 9; i++) {
                soma1 += Number(cpf.substr(i, 1)) * valor
                soma2 += Number(cpf.substr(i, 1))
                valor--
            }

            let digito1 = (11 * (1 + Math.floor(soma1 / 11))) - soma1

            if (digito1 > 9) {
                digito1 = 0
            }

            soma1 = soma1 + soma2 + digito1 + digito1

            let digito2 = (11 * (1 + Math.floor(soma1 / 11))) - soma1

            if (digito2 > 9) {
                digito2 = 0
            }

            const digitoGerado = (digito1 * 10) + digito2

            if (digitoGerado === digitoDigitado) {
                cpfOK = true
            }      
        }
    }

    return cpfOK
}

export const TextValidatorCpf = props => {
    return (
        <TextValidatorText
            tamMax={TAMMAX.cpf}
            helperText={cpfValido(props.value) ? '' : MESSAGE().cpfInvalido}
            InputProps={{
                inputComponent: NumberFormatCpf,
                style: STYLE(false, props.disabled).styleTextField
            }}
            {...props}
        />
    )
}

export const TextValidatorCpfTable = props => {
    return (
        <TextValidatorCpf
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Cnpj
// ----

function NumberFormatCnpj(props) {
    return (
      <NumberFormatPadrao
        format="##.###.###/####-##"
        {...props}
      />
    );
}

const cnpjValido = ( cnpj ) => {
    let cnpjOK = true

    if (cnpj.length > 0) {

        cnpjOK = false

        if (cnpj.length === 14) {

            let valida = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

            let dig1 = 0
            let dig2 = 0

            const digitoDigitado = Number(cnpj.substr(12, 1) + cnpj.substr(13, 1))

            for (let i = 0; i < valida.length; i++) {
                dig1 += i > 0 ? cnpj.substr(i - 1, 1) * valida[i] : 0
                dig2 += cnpj.substr(i, 1) * valida[i]   
            }

            dig1 = (dig1 % 11) < 2 ? 0 : 11 - (dig1 % 11)
            dig2 = (dig2 % 11) < 2 ? 0 : 11 - (dig2 % 11)

            const digitoGerado = (dig1 * 10) + dig2

            if (digitoGerado === digitoDigitado) {
                cnpjOK = true
            }
        }
    }

    return cnpjOK
} 

export const TextValidatorCnpj = props => {
    return (
        <TextValidatorText
            tamMax={TAMMAX.cnpj}
            helperText={cnpjValido(props.value) ? '' : MESSAGE().cnpjInvalido}
            InputProps={{
                inputComponent: NumberFormatCnpj,
                style: STYLE(false, props.disabled).styleTextField
            }}
            {...props}
        />
    )
}

export const TextValidatorCnpjTable = props => {
    return (
        <TextValidatorCnpj
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Telefone
// --------

function NumberFormatTelefone(props) {
    return (
      <NumberFormatPadrao
        format="(##) ####-####"
        {...props}
      />
    );
}

export const TextValidatorTelefone = props => {
    return (
        <TextValidatorText
            tamMax={TAMMAX.telefone}
            InputProps={{
                inputComponent: NumberFormatTelefone,
                style: STYLE(false, props.disabled).styleTextField
            }}
            {...props}
        />
    )
}

export const TextValidatorTelefoneTable = props => {
    return (
        <TextValidatorTelefone
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Celular
// -------

function NumberFormatCelular(props) {
    return (
      <NumberFormatPadrao
        format="(##) #####-####"
        {...props}
      />
    );
}

export const TextValidatorCelular = props => {
    return (
        <TextValidatorText
            tamMax={TAMMAX.celular}
            InputProps={{
                inputComponent: NumberFormatCelular,
                style: STYLE(false, props.disabled).styleTextField
            }}
            helperText={props.value || props.value === 0 ? celularOK(props.value, false) ? '' : MESSAGE().celularInvalido : ''}
            {...props}
        />
    )
}

export const TextValidatorCelularRequired = props => {
    return (
        <TextValidatorCelular
            helperText={props.value || props.value === 0 ? celularOK(props.value, false) ? '' : MESSAGE().celularInvalido : MESSAGE().campoObrigatorio}
            {...props}
        />
    )
}

export const TextValidatorCelularTable = props => {
    return (
        <TextValidatorCelular
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Cep
// ---

function NumberFormatCep(props) {
    return (
      <NumberFormatPadrao
        format="##.###-###"
        {...props}
      />
    );
}

export const TextValidatorCep = props => {
    return (
        <TextValidatorText
            tamMax={TAMMAX.cep}
            InputProps={{
                inputComponent: NumberFormatCep,
                style: STYLE(false, props.disabled).styleTextField
            }}
            {...props}
        />
    )
}

export const TextValidatorCepTable = props => {
    return (
        <TextValidatorCep
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Dia
// ---

function NumberFormatDia(props) {
    return (
      <NumberFormatPadrao
        format="##"
        {...props}
      />
    )
}

const diaValido = ( dia ) => {
    let diaOK = true

    if (dia.length > 0) {

        if (dia.length < TAMMAX.dia) {

            diaOK = false

        } else {

            if (dia < NUMMIN.dia || dia > NUMMAX.dia) {

                diaOK = false
            }
        }
    }

    return diaOK
} 

export const TextValidatorDia = props => {
    return (
        <TextValidatorText
            tamMax={TAMMAX.dia}
            helperText={diaValido(props.value) ? '' : MESSAGE().diaInvalido}
            InputProps={{
                inputComponent: NumberFormatDia,
                style: STYLE(false, props.disabled).styleTextField
            }}
            {...props}
        />
    )
}

export const TextValidatorDiaRequired = props => {
    return (
        <TextValidatorDia
            helperText={props.value || props.value === 0 ? diaValido(props.value) ? '' : MESSAGE().diaInvalido : MESSAGE().campoObrigatorio}
            {...props}
        />
    )
}

export const TextValidatorDiaTable = props => {
    return (
        <TextValidatorDia
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Mês
// ---

function NumberFormatMes(props) {
    return (
      <NumberFormatPadrao
        format="##"
        {...props}
      />
    )
}

const mesValido = ( mes ) => {
    let mesOK = true

    if (mes.length > 0) {

        if (mes.length < TAMMAX.mes) {

            mesOK = false

        } else {

            if (mes < NUMMIN.mes || mes > NUMMAX.mes) {

                mesOK = false
            }
        }
    }

    return mesOK
} 

export const TextValidatorMes = props => {
    return (
        <TextValidatorText
            tamMax={TAMMAX.mes}
            helperText={mesValido(props.value) ? '' : MESSAGE().mesInvalido}
            InputProps={{
                inputComponent: NumberFormatMes,
                style: STYLE(false, props.disabled).styleTextField
            }}
            {...props}
        />
    )
}

export const TextValidatorMesRequired = props => {
    return (
        <TextValidatorMes
            helperText={props.value || props.value === 0 ? mesValido(props.value) ? '' : MESSAGE().mesInvalido : MESSAGE().campoObrigatorio}
            {...props}
        />
    )
}

export const TextValidatorMesTable = props => {
    return (
        <TextValidatorMes
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Mês/Ano
// -------

function NumberFormatMesAno(props) {
    return (
      <NumberFormatPadrao
        format="##/####"
        {...props}
      />
    );
}

const mesAnoValido = ( mesAno ) => {
    let mesAnoOK = true

    if (mesAno.length > 0) {

        if (mesAno.length < TAMMAX.mesAno) {

            mesAnoOK = false

        } else {

            const mes = mesMesAno(mesAno)
            const ano = anoMesAno(mesAno)

            if (mes < NUMMIN.mes || mes > NUMMAX.mes || ano < NUMMIN.ano || ano > NUMMAX.ano) {

                mesAnoOK = false
            }
        }
    }

    return mesAnoOK
} 

export const TextValidatorMesAno = props => {

    const {report, ...rest} = props

    return (
        <TextValidatorText
            tamMax={TAMMAX.mesAno}
            helperText={mesAnoValido(props.value) ? '' : MESSAGE().mesAnoInvalido}
            InputProps={{
                inputComponent: NumberFormatMesAno,
                style: STYLE(false, report ? false : props.disabled).styleTextField
            }}
            {...rest}
        />
    )
}

export const TextValidatorMesAnoRequired = props => {
    return (
        <TextValidatorMesAno
            helperText={props.value || props.value === 0 ? mesAnoValido(props.value) ? '' : MESSAGE().mesAnoInvalido : MESSAGE().campoObrigatorio}
            {...props}
        />
    )
}

export const TextValidatorMesAnoTable = props => {
    return (
        <TextValidatorMesAno
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Dia/Mês
// -------

function NumberFormatDiaMes(props) {
    return (
      <NumberFormatPadrao
        format="##/##"
        {...props}
      />
    );
}

const diaMesValido = ( diaMes ) => {
    let diaMesOK = true

    if (diaMes.length > 0) {

        if (diaMes.length < TAMMAX.diaMes) {

            diaMesOK = false

        } else {

            const dia = diaDiaMes(diaMes)
            const mes = mesDiaMes(diaMes)

            if (dia < NUMMIN.dia || dia > NUMMAX.dia || mes < NUMMIN.mes || mes > NUMMAX.mes) {

                diaMesOK = false
            }
        }
    }

    return diaMesOK
} 

export const TextValidatorDiaMes = props => {
    return (
        <TextValidatorText
            tamMax={TAMMAX.diaMes}
            helperText={diaMesValido(props.value) ? '' : MESSAGE().diaMesInvalido}
            InputProps={{
                inputComponent: NumberFormatDiaMes,
                style: STYLE(false, props.disabled).styleTextField
            }}
            {...props}
        />
    )
}

export const TextValidatorDiaMesRequired = props => {
    return (
        <TextValidatorDiaMes
            helperText={props.value || props.value === 0 ? diaMesValido(props.value) ? '' : MESSAGE().diaMesInvalido : MESSAGE().campoObrigatorio}
            {...props}
        />
    )
}

export const TextValidatorDiaMesTable = props => {
    return (
        <TextValidatorDiaMes
            className=""    
            fullWidth
            {...props}
        />
    )
}



// Email
// -----
        
export const TextValidatorEmail = props => {

    const { value, onChange, ...rest } = props

    const tamMax = TAMMAX.email

    if (value && value !== value.toLowerCase()) {
        onChange({
            target: {
                name: rest.name,
                value: value.toUpperCase(),
            },
        })
    }
    
    return (
        <TextValidatorBase
            type="email"
            multiline={true}
            value={value.toLowerCase()}
            onChange={({ target: { value }, }) =>
                onChange({
                    target: {
                        name: rest.name,
                        value: value.toLowerCase(),
                    },
                })
            }
            validators={[
                //'isEmail',
                `maxStringLength: ${tamMax}`,
            ]}
            errorMessages={[
                //MESSAGE().emailInvalido,
                MESSAGE().tamanhoMaximo.replace( "$tamMax$", tamMax ),
            ]}
            helperText={props.value ? emailOK(props.value) ? '' : MESSAGE().emailInvalido : ''}
            {...rest}
        />
    )
}

export const TextValidatorEmailRequired = props => {
    
    const tamMax = TAMMAX.email

    return (
        <TextValidatorEmail
            validators={[
                'required', 
                //'isEmail',
                `maxStringLength: ${tamMax}`,
            ]}
            errorMessages={[
                MESSAGE().campoObrigatorio,
                //MESSAGE().emailInvalido,
                MESSAGE().tamanhoMaximo.replace( "$tamMax$", tamMax ),
            ]}
            helperText={props.value ? emailOK(props.value) ? '' : MESSAGE().emailInvalido : MESSAGE().campoObrigatorio}
            {...props}
        />
    )
}

export const TextValidatorEmailTable = props => {
    return (
        <TextValidatorEmail
            className=""    
            fullWidth
            {...props}
        />
    )
}


// Senha
// -----

export const TextValidatorSenha = props => {

    const tamMax = TAMMAX.senha
    
    return (
        <TextValidatorBase
            type="password"
            validators={[
                `maxStringLength: ${tamMax}`,
            ]}
            errorMessages={[
                MESSAGE().tamanhoMaximo.replace( "$tamMax$", tamMax ),
            ]}
            {...props}
        />
    )
}

export const TextValidatorSenhaRequired = props => {
    const { tamMax, ...rest } = props

    return (
        <TextValidatorSenha
            validators={[
                'required', 
                `maxStringLength: ${tamMax}`,
            ]}
            errorMessages={[
                MESSAGE().campoObrigatorio,
                MESSAGE().tamanhoMaximo.replace( "$tamMax$", tamMax ),
            ]}
            {...rest}
        />
    )
}
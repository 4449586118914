import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'



class VwClienteAcessoApoliceCobrancaService extends ApiService {

    constructor() {
        super(HARDCODE.apiVwClienteAcessoApoliceCobranca)
    }

    async buscarApoliceCobrancaList(variaveisGlobais, filtroList) {

        /*
        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, null, filtroList, true)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoApoliceCobrancaBuscarApoliceCobrancaList}/?${params}`,
            paramsAux, false)
        */

        const body = await this.calculaBodyFiltroList(variaveisGlobais, null, filtroList, true)

        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoApoliceCobrancaBuscarApoliceCobrancaList, body, false)
    }

    async sumApoliceCobranca(variaveisGlobais, campoAgrupamento, filtroList) {

        /*
        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, campoAgrupamento, filtroList, true)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoApoliceCobrancaSumApoliceCobranca}/?${params}`,
            paramsAux, false)
        */

        const body = await this.calculaBodyFiltroList(variaveisGlobais, campoAgrupamento, filtroList, true)

        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoApoliceCobrancaSumApoliceCobranca, body, false)
    }
}

export default VwClienteAcessoApoliceCobrancaService
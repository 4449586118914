const LABELESPECIAL = {

    morte: 'Morte',

    ou: ' ou ',

    
    
    moedaReal: 'R$',
    moedaEuro: '€',
    moedaDolarAmericano: 'US$',
    moedaDolarCanadense: 'C$',
    moedaGenerica: '$',


    
    mes: 'mês',
    meses: 'meses',
    ano: 'ano',
    anos: 'anos',
}

export default LABELESPECIAL
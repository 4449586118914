// alterado - revisado

import HARDCODE from "../../business-const/HardCode/HardCode"

const tipoPatrimonioImobilizadoList = [
    {
        id: 10,
        descricao: 'Imóvel', // 'Imóvel Urbano',
        ordem: 10,
        informaAluguel: 'S',
        informaTipoImovel: 'S',
        informaUfItcmd: 'S',
        tipoAgrupamento: HARDCODE.tipoAgrupamentoPatrimonioImobilizadoBensMoveisImoveis,
        help: '',
    },
    {
        id: 20,
        descricao: 'Veículos', // 'Bens Móveis',
        ordem: 20,
        informaAluguel: 'N',
        informaTipoImovel: 'N',
        informaUfItcmd: 'S',
        tipoAgrupamento: HARDCODE.tipoAgrupamentoPatrimonioImobilizadoBensMoveisImoveis,
        help: 'Informa aqui o valor de veículos, tratores, etc.',
    },
    {
        id: 30,
        descricao: 'Negócios',
        ordem: 30,
        informaAluguel: 'N',
        informaTipoImovel: 'N',
        informaUfItcmd: 'S',
        tipoAgrupamento: HARDCODE.tipoAgrupamentoPatrimonioImobilizadoNegocios,
        help: 'Informe o valuation da empresa em caso de sucessão.',
    },
    {
        id: 40,
        descricao: 'Direitos Autorais',
        ordem: 40,
        informaAluguel: 'N',
        informaTipoImovel: 'N',
        informaUfItcmd: 'S',
        tipoAgrupamento: HARDCODE.tipoAgrupamentoPatrimonioImobilizadoNegocios,
        help: '',
    },
    //{
    //    id: 50,
    //    descricao: 'Consórcio',
    //    ordem: 50,
    //    informaAluguel: 'N',
    //    informaTipoImovel: 'N',
    //    informaUfItcmd: 'S',
    //    tipoAgrupamento: ???,
    //    help: '',
    //},
    {
        id: 60,
        descricao: 'Terreno',
        ordem: 60,
        informaAluguel: 'N',
        informaTipoImovel: 'N',
        informaUfItcmd: 'S',
        tipoAgrupamento: HARDCODE.tipoAgrupamentoPatrimonioImobilizadoBensMoveisImoveis,
        help: '',
    },
    {
        id: 70,
        descricao: 'Imóvel Rural',
        ordem: 15,
        informaAluguel: 'N',
        informaTipoImovel: 'N',
        informaUfItcmd: 'S',
        tipoAgrupamento: HARDCODE.tipoAgrupamentoPatrimonioImobilizadoBensMoveisImoveis,
        help: 'Informe aqui o valor de fazendas, sítios, etc.',
    },
    {
        id: 900, // HARDCODE.idTipoPatrimonioImobilizadoOutros
        descricao: 'Outros',
        ordem: 900,
        informaAluguel: 'N',
        informaTipoImovel: 'N',
        informaUfItcmd: 'S',
        tipoAgrupamento: HARDCODE.tipoAgrupamentoPatrimonioImobilizadoBensMoveisImoveis,
        help: '',
    },
]

export default tipoPatrimonioImobilizadoList
import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'

import {
    dataAtual,
    ano,
    mes,
    dia,
    dataAnoMesDia,
    converteDataExtensoData,
    stringParaData,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    pesquisaList
} from '../../../business-rules/List/ListService'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    semZerosEsquerda
} from '../../../business-components/String/String'

import {
    trataTexto,
    trataValorTexto,
    trataInteiro,
    trataData,
} from '../cliente-form/ClienteServiceImportacao'

import {
    espaco,
    fimLinha,
    identificaTipoCoberturaSeguroVida,
    identificaStatusCobertura,
    indexAtualJsonRegistros,
    obtemDadoPDF,
    obtemDadoXLSX,
    timeSeguradora,
    idAgenteTimeSeguradora,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosAtualizaErros,
    incluiCoberturas,
    numeroCotacaoAutomatico,
    incluiTabelaEvolucaoValorResgateCoberturaVidaInteira,
} from './IntegracaoClienteArquivoService'



export const importaPropostaPrudential = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {


    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)


    PDFTxt = PDFTxt.replace(fimLinha + 'PROPOSTA DE CONTRATAÇÃO DE SEGURO Nº' + espaco, 
        fimLinha + 'PROPOSTA DE CONTRATAÇÃO DE SEGURO DE VIDA Nº' + espaco)


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'PROPOSTA DE CONTRATAÇÃO DE SEGURO DE VIDA Nº' + espaco, fimLinha,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].statusProposta = HARDCODE.idStatusPropostaPendente

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome do Segurado:' + espaco, 'Idade:',
        HARDCODE.importacaoNomeSeguradoCliente, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].nomeSeguradoCliente) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome ou Nome Social do Segurado:' + espaco, 'Idade:',
            HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data de Nascimento:' + espaco, espaco, 
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, 'Sexo:' + espaco, espaco,
        HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, 'CPF:' + espaco, espaco, 
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Telefone Celular:' + espaco, espaco + 'Telefone Comercial:',
        HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço de Correspondência do Segurado' + fimLinha + 'Logradouro:' + espaco,
        fimLinha, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Número:' + fimLinha,
    //    fimLinha, HARDCODE.importacaoNumeroEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nº e Complemento:' + espaco,
        'Bairro:', HARDCODE.importacaoComplementoEnderecoSeguradoCliente, false, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, 'Bairro:' + espaco, fimLinha,
        HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Cidade:' + espaco, espaco + 'Estado:',
        HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, 'Estado:' + espaco, espaco,
        HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, 'CEP:' + espaco, espaco + 'Possui',
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

    if (PDFTxt.substring(jsonRegistros.indexInicial, jsonRegistros.indexInicial + 100).indexOf('Atividade profissional:' + espaco) !== -1) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Atividade profissional:' + espaco, fimLinha,
            HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Faturamento Anual ou Renda Anual:' + espaco, espaco + 'Possui',
            HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)

        jsonRegistros.jsonRegistros[indexJsonRegistros].rendaMensal = round(jsonRegistros.jsonRegistros[indexJsonRegistros].rendaMensal / 12, 2)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'E-mail:' + espaco, fimLinha,
        HARDCODE.importacaoEmailSeguradoCliente, false, null, erros, nomeArquivo)
    
    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].emailSeguradoCliente) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'E-mail:' + espaco, fimLinha,
            HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Automática Valor do Prêmio Valor IOF' + fimLinha,
        fimLinha + 'C. Informações sobre o Pagamento dos Prêmios' + espaco + fimLinha,
            HARDCODE.importacaoPropostaCoberturas, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Periodicidade de Pagamento:' + espaco, espaco,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data de Finalização' + espaco, espaco,
        HARDCODE.importacaoDataAssinatura, false, null, erros, nomeArquivo)


    if (jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replace(
                fimLinha + 'COBERTURAS OPCIONAIS DE SEGURO DE' + espaco + fimLinha + 'VIDA' + fimLinha + 'CLASSE DE' + espaco +
                fimLinha + 'AJUSTE' + fimLinha + 'CAPITAL SEGURADO' + espaco + fimLinha + 'INICIAL/VALOR DA DIÁRIA (HC)' +
                    fimLinha + 'EXTENSÃO' + espaco + fimLinha + 'AUTOMÁTICA VALOR DO PRÊMIO VALOR IOF', '')

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replaceAll('NÃO SE APLICA' + espaco, '')

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replaceAll('NÃO' + espaco + fimLinha +
                'FUMANTE-' + fimLinha, 'NÃO-FUMANTE-')
          
        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replaceAll('CLASSE A', 'CLASSE-A')

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replaceAll('CLASSE B', 'CLASSE-B')

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replaceAll('CLASSE C', 'CLASSE-C')

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replaceAll('CLASSE D', 'CLASSE-D')

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replaceAll('CLASSE E', 'CLASSE-E')

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replaceAll('CLASSE F', 'CLASSE-F')
              
        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replaceAll('SIM' + espaco, '')

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replaceAll('NÃO' + espaco, '')

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.replaceAll('R$', '')


        const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.split(fimLinha)
        let indexLinhasCoberturaAux = 0

        const linhasCobertura = ['']
        let indexLinhasCobertura = 0

        while (indexLinhasCoberturaAux < linhasCoberturaAux.length) {

            linhasCobertura[indexLinhasCobertura] +=
                (linhasCobertura[indexLinhasCobertura] ? espaco : '' ) + linhasCoberturaAux[indexLinhasCoberturaAux]

            if (
                    linhasCoberturaAux[indexLinhasCoberturaAux].indexOf(',') !== -1 &&
                    indexLinhasCoberturaAux + 1 < linhasCoberturaAux.length
            ) {

                indexLinhasCobertura++

                linhasCobertura.push('')
            }

            indexLinhasCoberturaAux++
        }


        const coberturas = []

        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let dadosCobertura
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio
        let classeAjusteRisco = ''
        let valorPremioExtra = ''
        let duracaoPremioExtra = ''
        let dataTerminoCobertura
        let valorRendaMensal = ''

        indexLinhasCobertura = 0

        while (indexLinhasCobertura < linhasCobertura.length) {

            dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)
            
            valorCS = trataValorTexto(dadosCobertura[dadosCobertura.length - 5])
            valorPremioLiquido = trataValorTexto(dadosCobertura[dadosCobertura.length - 3])
            valorIof = trataValorTexto(dadosCobertura[dadosCobertura.length - 1])
            valorPremio = valorPremioLiquido + valorIof

            classeAjusteRisco = dadosCobertura[dadosCobertura.length - 7]

            if (classeAjusteRisco) {
            
                const classeAjusteRiscoConversao =
                    pesquisaList(linhaIntegracao.classeAjuste, classeAjusteRisco).idConversao

                if (classeAjusteRiscoConversao) {

                    classeAjusteRisco = classeAjusteRiscoConversao
                }
            }

            descricaoCobertura = ''

            indexLinhasCoberturaAux = 0

            while (indexLinhasCoberturaAux < dadosCobertura.length - 7) {
            
                descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexLinhasCoberturaAux]
            
                indexLinhasCoberturaAux++
            }

            const tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

            incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida, null, valorCS,
                valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco, valorPremioExtra, duracaoPremioExtra,
                    dataTerminoCobertura, valorRendaMensal)

            indexLinhasCobertura += 1
        }

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas = coberturas
    }



    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
    


    return jsonRegistros.jsonRegistros
}



export const importaApolicePrudential = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    let modeloCobertura

    let numeroApoliceAnt = ''

    PDFTxt = PDFTxt.replaceAll('Nº da Apólice:', 'Apólice:')
    PDFTxt = PDFTxt.replaceAll('Nº da Proposta:', 'Proposta:')
    PDFTxt = PDFTxt.replaceAll('Nome do Segurado:', 'Segurado:')
    PDFTxt = PDFTxt.replaceAll('Endereço Residencial:', 'Endereço:')
    PDFTxt = PDFTxt.replaceAll('Telefone Residencial:', 'Telefone:')
    PDFTxt = PDFTxt.replaceAll('Telefone Comercial:', 'Tel.Comercial:')
    PDFTxt = PDFTxt.replaceAll('Telefone Celular:', 'Celular:')
    PDFTxt = PDFTxt.replaceAll('Periodicidade:', 'Periodicidade Pagto.:')
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Profissão Responsável pelo Pagamento:', espaco + 'Profissão Responsável pelo Pagamento:')
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Benefício' + fimLinha + 'Pago' + fimLinha, fimLinha + 'Benefício Pago' + espaco)
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Fumante-' + fimLinha + 'Standard' + fimLinha, espaco + 'Fumante-Standard' + espaco)
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Não Fumante-' + fimLinha + 'Standard' + fimLinha, espaco + 'Não Fumante-Standard' + espaco)
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Não Fumante-' + fimLinha + 'Classe A' + fimLinha, espaco + 'Não Fumante-Classe A' + espaco)
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Não Fumante-' + fimLinha + 'Classe B' + fimLinha, espaco + 'Não Fumante-Classe B' + espaco)
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Não Fumante-' + fimLinha + 'Classe C' + fimLinha, espaco + 'Não Fumante-Classe C' + espaco)
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Não Fumante-' + fimLinha + 'Classe D' + fimLinha, espaco + 'Não Fumante-Classe D' + espaco)
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Não Fumante-' + fimLinha + 'Classe E' + fimLinha, espaco + 'Não Fumante-Classe E' + espaco)
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Não Fumante-' + fimLinha + 'Classe F' + fimLinha, espaco + 'Não Fumante-Classe F' + espaco)
    PDFTxt = PDFTxt.replaceAll(fimLinha + '(Regular)' + espaco, fimLinha + '(Regular)' + fimLinha)
    PDFTxt = PDFTxt.replaceAll(espaco + 'Expired' + espaco, fimLinha + 'Expired' + fimLinha)
    PDFTxt = PDFTxt.replaceAll(espaco + 'Paid-Up Life' + espaco, fimLinha + 'Paid-Up Life' + fimLinha)

     
    while (PDFTxt.indexOf('Segurado:', jsonRegistros.indexInicial) > 0) {

        jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

        const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Apólice:' + espaco, espaco, HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)
      
        while (jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice === numeroApoliceAnt) {

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Apólice:' + espaco, espaco,
                HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)
        }

        numeroApoliceAnt = jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice

        jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice =
            semZerosEsquerda(jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice)

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco, espaco + 'Proposta:', 
            HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Proposta:' + espaco, fimLinha, 
            HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)
        
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data da Emissão:' + espaco, espaco, 
            HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Segurado:' + espaco, espaco + 'CPF:',
            HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)
        
        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'CPF:' + espaco, fimLinha,
            HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

        if (PDFTxt.substring(jsonRegistros.indexInicial, jsonRegistros.indexInicial + 100).indexOf('Profissão:' + espaco) !== -1) {

            obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Profissão:' + espaco, fimLinha, 
                HARDCODE.importacaoProfissaoSegurado, false, null, erros, nomeArquivo)
        }
        
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data de Nascimento:' + espaco, espaco,
            HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)
        
        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Sexo:' + espaco, fimLinha,
            HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço:' + espaco, espaco + 'Bairro:',
            HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Bairro:' + espaco, fimLinha,
            HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)
            
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Cidade:' + espaco, espaco + 'Estado:',
            HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Estado:' + espaco, espaco,
            HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'CEP:' + espaco, fimLinha,
            HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone:', espaco + 'Tel.Comercial:',
            HARDCODE.importacaoTelefoneSegurado, false, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Tel.Comercial:', fimLinha,
            HARDCODE.importacaoTelefoneComercialSegurado, false, null, erros, nomeArquivo)
        
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Celular:', espaco + 'E-mail:',
            HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'E-mail:'/* + espaco*/, fimLinha,
            HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Responsável pelo Pagamento:' + espaco, /*espaco + */'CPF / CNPJ:',
            HARDCODE.importacaoNomeRespPagto, false, null, erros, nomeArquivo)

        if (jsonRegistros.jsonRegistros[indexJsonRegistros].nomeRespPagto) {

            obtemDadoPDF(PDFTxt, jsonRegistros, /*espaco + */'CPF / CNPJ:' + espaco, fimLinha,
                HARDCODE.importacaoCpfCnpjRespPagto, true, null, erros, nomeArquivo)
            
            if (jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto.length === 14) {

                jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto = ''

            } else {

                obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo do Responsável pelo Pagamento:' + espaco,
                    espaco + 'Profissão Responsável pelo Pagamento:', HARDCODE.importacaoSexoRespPagto, false, null, erros, nomeArquivo)
            }

        } else {

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Responsável pelo Pagamento:' + espaco, fimLinha,
                HARDCODE.importacaoNomeRespPagto, true, null, erros, nomeArquivo)
            
            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CPF / CNPJ:' + espaco, fimLinha,
                HARDCODE.importacaoCpfCnpjRespPagto, true, null, erros, nomeArquivo)
                
            if (jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto.length === 14) {

                jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto = ''

            } else {

                obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo do Responsável pelo Pagamento:' + espaco,
                fimLinha, HARDCODE.importacaoSexoRespPagto, false, null, erros, nomeArquivo)
            }
        }
        

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Extensão de' + fimLinha + 'Vigência' + fimLinha,
            fimLinha + 'Prêmio Líquido Total:' + espaco, HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)

        modeloCobertura = 1

        if (!jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Duração Prêmio' + fimLinha + 'Extra' + fimLinha,
                fimLinha + '1) Valor já incluso no prêmio líquido por cobertura.' + fimLinha, HARDCODE.importacaoCoberturas, true, null,
                    erros, nomeArquivo)

            modeloCobertura = 2
        }



        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Periodicidade Pagto.:' + espaco, 1/*espaco*/,
            HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)



        if (jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
                jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replace(fimLinha + 'SUPER' + fimLinha + 'PREFERENCIAL' + fimLinha,
                    espaco + 'SUPER PREFERENCIAL' + espaco)

            const linhasCobertura = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)

            let indexLinhasCobertura = 0


            const coberturas = []

            let indexPesquisa
            let linhaConversaoIntegracao

            let linhaEspecial
            let indexPesquisaLinhaEspecial
            let posStatusCoberturaLinhaEspecial

            let numeroCobertura
            let codigoCobertura
            let descricaoCobertura
            let dadosCobertura
            let posDadosCobertura
            let valorCS
            let valorPremioLiquido
            let valorIof
            let valorPremio
            let indexCobertura
            let classeAjusteRisco
            let valorPremioExtra
            let duracaoPremioExtra
            let dataTerminoCobertura
            let valorRendaMensal

            while (indexLinhasCobertura < linhasCobertura.length) {

                descricaoCobertura = linhasCobertura[indexLinhasCobertura]

                if (descricaoCobertura.length > 50) {

                    indexPesquisa = 0

                    linhaEspecial = linhasCobertura[indexLinhasCobertura].split(espaco)

                    linhaConversaoIntegracao = {}

                    indexPesquisaLinhaEspecial = 0

                    while (!linhaConversaoIntegracao.id) {

                        linhaConversaoIntegracao = pesquisaList(linhaIntegracao.statusCobertura, linhaEspecial[indexPesquisaLinhaEspecial])

                        if (!linhaConversaoIntegracao.id) {

                            linhaConversaoIntegracao = pesquisaList(linhaIntegracao.statusCobertura,
                                linhaEspecial[indexPesquisaLinhaEspecial] + espaco + linhaEspecial[indexPesquisaLinhaEspecial + 1])
                        }

                        indexPesquisaLinhaEspecial++
                    }

                    posStatusCoberturaLinhaEspecial = linhasCobertura[indexLinhasCobertura].indexOf(linhaConversaoIntegracao.id)

                    descricaoCobertura = linhasCobertura[indexLinhasCobertura].substring(0, posStatusCoberturaLinhaEspecial - 1)

                    dadosCobertura = linhasCobertura[indexLinhasCobertura].substring(posStatusCoberturaLinhaEspecial +
                        linhaConversaoIntegracao.id.length + 1)

                } else {

                    indexPesquisa = 1

                    linhaConversaoIntegracao = pesquisaList(linhaIntegracao.statusCobertura, linhasCobertura[indexLinhasCobertura + indexPesquisa])

                    if (!linhaConversaoIntegracao.id) {

                        descricaoCobertura += ' ' + linhasCobertura[indexLinhasCobertura + 1]

                        indexPesquisa++

                        linhaConversaoIntegracao =
                            pesquisaList(linhaIntegracao.statusCobertura, linhasCobertura[indexLinhasCobertura + indexPesquisa])
                    }

                    if (!linhaConversaoIntegracao.id) {

                        posDadosCobertura = linhasCobertura[indexLinhasCobertura + indexPesquisa].indexOf(espaco)

                        linhaConversaoIntegracao = pesquisaList(linhaIntegracao.statusCobertura,
                            linhasCobertura[indexLinhasCobertura + indexPesquisa].substring(0, posDadosCobertura))
                        
                        dadosCobertura = linhasCobertura[indexLinhasCobertura + indexPesquisa].substring(posDadosCobertura + 1).split(espaco)

                        if (!linhaConversaoIntegracao.id) {

                            posDadosCobertura = linhasCobertura[indexLinhasCobertura + indexPesquisa].indexOf(espaco, posDadosCobertura + 1)

                            linhaConversaoIntegracao = pesquisaList(linhaIntegracao.statusCobertura,
                                linhasCobertura[indexLinhasCobertura + indexPesquisa].substring(0, posDadosCobertura))

                            dadosCobertura = linhasCobertura[indexLinhasCobertura + indexPesquisa].substring(posDadosCobertura + 1).split(espaco)

                            if (!linhaConversaoIntegracao.id) {

                                descricaoCobertura += ' ' + linhasCobertura[indexLinhasCobertura + indexPesquisa]

                                indexPesquisa++
        
                                linhaConversaoIntegracao =
                                    pesquisaList(linhaIntegracao.statusCobertura, linhasCobertura[indexLinhasCobertura + indexPesquisa])

                                if (!linhaConversaoIntegracao.id) {

                                    if (linhasCobertura[indexLinhasCobertura + indexPesquisa] === "ANNUIT") {

                                        linhaConversaoIntegracao = pesquisaList(linhaIntegracao.statusCobertura,
                                            linhasCobertura[indexLinhasCobertura + indexPesquisa - 1] + ' ' +
                                                linhasCobertura[indexLinhasCobertura + indexPesquisa])
                            
                                        indexPesquisa++

                                        dadosCobertura = linhasCobertura[indexLinhasCobertura + indexPesquisa].split(espaco)

                                    } else {

                                        posDadosCobertura = linhasCobertura[indexLinhasCobertura + indexPesquisa].indexOf(espaco)

                                        linhaConversaoIntegracao = pesquisaList(linhaIntegracao.statusCobertura,
                                            linhasCobertura[indexLinhasCobertura + indexPesquisa].substring(0, posDadosCobertura))
                            
                                        dadosCobertura = linhasCobertura[indexLinhasCobertura + indexPesquisa].substring(posDadosCobertura + 1).split(espaco)
                                    }

                                } else {
                        
                                    indexPesquisa += linhaConversaoIntegracao.linhas

                                    let dadosCoberturaAux = linhasCobertura[indexLinhasCobertura + indexPesquisa]

                                    while (dadosCoberturaAux.indexOf('/') === -1) {

                                        indexPesquisa++

                                        dadosCoberturaAux += ' ' + linhasCobertura[indexLinhasCobertura + indexPesquisa]
                                    }
                                    
                                    dadosCobertura = dadosCoberturaAux.split(espaco)
                                }
                            }
                        }

                    } else {

                        indexPesquisa += linhaConversaoIntegracao.linhas

                        dadosCobertura = linhasCobertura[indexLinhasCobertura + indexPesquisa].split(espaco)
                    }
                }

                if (indexLinhasCobertura + indexPesquisa + 1 < linhasCobertura.length) {

                    if (linhasCobertura[indexLinhasCobertura + indexPesquisa + 1] === 'NÃO') {

                        indexPesquisa++
                    }

                    if (indexLinhasCobertura + indexPesquisa + 1 < linhasCobertura.length) {

                        if (linhasCobertura[indexLinhasCobertura + indexPesquisa + 1] === 'AUTORIZADA') {

                            indexPesquisa++
                        }
                    }
                }

                const tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                    jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

                valorCS = trataValorTexto(dadosCobertura[0])
                valorPremioLiquido = trataValorTexto(dadosCobertura[1])
                valorIof = trataValorTexto(dadosCobertura[3])
                valorPremio = round(valorPremioLiquido + valorIof, 2)

                indexCobertura = 4

                classeAjusteRisco = ''

                while (isNaN(dadosCobertura[indexCobertura].substring(0, 1))) {

                    if (classeAjusteRisco) {

                        classeAjusteRisco += espaco
                    }

                    classeAjusteRisco += dadosCobertura[indexCobertura]

                    indexCobertura++
                }

                if (modeloCobertura === 1) {

                    valorPremioExtra = trataValorTexto(dadosCobertura[indexCobertura])
                    duracaoPremioExtra = trataValorTexto(dadosCobertura[indexCobertura + 1])
                    dataTerminoCobertura = trataData(dadosCobertura[indexCobertura + 2])

                } else {

                    valorPremioExtra = trataValorTexto(dadosCobertura[indexCobertura + 1])
                    duracaoPremioExtra = trataValorTexto(dadosCobertura[indexCobertura + 2])
                    dataTerminoCobertura = trataData(dadosCobertura[indexCobertura])
                }

                if (
                        indexLinhasCobertura + indexPesquisa + 1 < linhasCobertura.length &&
                        linhasCobertura[indexLinhasCobertura + indexPesquisa + 1].trim().substring(0, 3) === '***'
                ) {

                    valorRendaMensal = linhasCobertura[indexLinhasCobertura + indexPesquisa + 1]

                    valorRendaMensal = valorRendaMensal.split(espaco)

                    valorRendaMensal = trataValorTexto(valorRendaMensal[valorRendaMensal.length - 1])

                    indexPesquisa++

                } else {

                    valorRendaMensal = null
                }

                incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                    linhaConversaoIntegracao.idConversao, valorCS, valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco,
                        valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)

                indexLinhasCobertura += indexPesquisa + 1
            }

            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas
        }


        
        jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
    }



    return jsonRegistros.jsonRegistros
}



export const importaApolicePrudentialSiteCliente = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao,
    seguradora, nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    if (PDFTxt.indexOf(fimLinha + 'PAGA' + fimLinha/*'SPWL  VIDA INTEIRA PAGAMENTO'*/) !== -1) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].statusApolice = HARDCODE.idStatusApoliceAtiva

    } else {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'APÓLICE', fimLinha,
            HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice, erros, nomeArquivo)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Proposta:' + espaco, fimLinha, 
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice =
        semZerosEsquerda(jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice)
   
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo:' + fimLinha, fimLinha,
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

    if (isFinite(jsonRegistros.jsonRegistros[indexJsonRegistros].nomeSeguradoCliente.substring(2, 6))) {
        
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)
    }
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha,
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

    jsonRegistros.indexInicial += 2

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, espaco + 'Telefone Celular:', 
        HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)

    if (PDFTxt.indexOf('Telefone Comercial:', jsonRegistros.indexInicial) !== -1) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone Comercial:' + fimLinha, fimLinha,
            HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)
        
        //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoTelefoneSegurado, true, null, erros, nomeArquivo)

        //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoTelefoneComercialSegurado, true, null, erros, nomeArquivo)

    } else {

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Telefone Celular:' /*+ espaco*/, fimLinha,
            HARDCODE.importacaoCelularSeguradoCliente, false/*true*/, null, erros, nomeArquivo)

        //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone Residencial:' + espaco, 'Email Principal:',
        //    HARDCODE.importacaoTelefoneSegurado, false, null, erros, nomeArquivo)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, /*fimLinha + */'Email Principal:' + espaco, fimLinha,
        HARDCODE.importacaoEmailSeguradoCliente, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].emailSeguradoCliente) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Email Alternativo:' + fimLinha, fimLinha,
            HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data de Nascimento:' + espaco, fimLinha,
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço de correspondência e pagamento:' + fimLinha, fimLinha,
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Cidade:' + espaco, fimLinha,
        HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, 'Bairro:' + fimLinha,
        HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Estado:' + espaco, fimLinha,
        HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

    /* 
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Responsável pelo Pagamento:' + espaco, espaco + 'CPF / CNPJ:',
        HARDCODE.importacaoNomeRespPagto, false, null, erros, nomeArquivo)

    if (jsonRegistros.jsonRegistros[indexJsonRegistros].nomeRespPagto) {

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'CPF / CNPJ:' + espaco, fimLinha,
            HARDCODE.importacaoCpfCnpjRespPagto, true, null, erros, nomeArquivo)
        
        if (jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto.length === 14) {

            jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto = ''

        } else {

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo do Responsável pelo Pagamento:' + espaco,
                espaco + 'Profissão Responsável pelo Pagamento:', HARDCODE.importacaoSexoRespPagto, false, null, erros, nomeArquivo)
        }

    } else {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Responsável pelo Pagamento:' + espaco, fimLinha,
            HARDCODE.importacaoNomeRespPagto, true, null, erros, nomeArquivo)
        
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CPF / CNPJ:' + espaco, fimLinha,
            HARDCODE.importacaoCpfCnpjRespPagto, true, null, erros, nomeArquivo)
            
        if (jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto.length === 14) {

            jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto = ''

        } else {

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo do Responsável pelo Pagamento:' + espaco,
            fimLinha, HARDCODE.importacaoSexoRespPagto, false, null, erros, nomeArquivo)
        }
    }
    */
    

    
    const coberturas = []

    let numeroCobertura
    let codigoCobertura
    let descricaoCobertura
    let valorCS
    let valorPremioLiquido
    let valorIof
    let valorPremio
    let classeAjusteRisco
    let valorPremioExtra
    let duracaoPremioExtra
    let dataTerminoCobertura
    let valorRendaMensal

    while (PDFTxt.indexOf('Status:', jsonRegistros.indexInicial) !== -1) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Status:' + fimLinha, fimLinha,
            HARDCODE.importacaoDescricaoCobertura, true, null, erros, nomeArquivo)

        if (jsonRegistros.jsonRegistros[indexJsonRegistros].descricaoCobertura === 'EXTENSÃO DE VIGÊNCIA:') {

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoDescricaoCobertura, true, null, erros, nomeArquivo)
        }

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoValorCS, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoValorIof, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoValorPremioLiquido, true, null, erros, nomeArquivo)
        
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoValorPremio, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoDataTerminoCoberturaString, true, null, erros, nomeArquivo)
        
        jsonRegistros.indexInicial += 2

        if (
                jsonRegistros.jsonRegistros[indexJsonRegistros].descricaoCobertura.indexOf('QUEBRA DE OSSOS') === -1 &&
                jsonRegistros.jsonRegistros[indexJsonRegistros].descricaoCobertura.indexOf('CIRURGIA') === -1
        ) {
        
            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoClasseAjusteRisco, true, null, erros, nomeArquivo)
        
        } else {

            jsonRegistros.jsonRegistros[indexJsonRegistros].classeAjusteRisco = ''
        }

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha, HARDCODE.importacaoStatusCobertura, true, null, erros, nomeArquivo)

        const tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(jsonRegistros.jsonRegistros[indexJsonRegistros].descricaoCobertura,
            linhaIntegracao, jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

        descricaoCobertura = jsonRegistros.jsonRegistros[indexJsonRegistros].descricaoCobertura
        valorCS = jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS
        valorPremioLiquido = jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioLiquido
        valorIof = jsonRegistros.jsonRegistros[indexJsonRegistros].valorIof
        valorPremio = jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioLiquido + jsonRegistros.jsonRegistros[indexJsonRegistros].valorIof
        classeAjusteRisco = jsonRegistros.jsonRegistros[indexJsonRegistros].classeAjusteRisco
        valorPremioExtra = ''
        duracaoPremioExtra = ''

        const statusCobertura = identificaStatusCobertura(jsonRegistros.jsonRegistros[indexJsonRegistros].statusCobertura, linhaIntegracao,
            jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

        if (jsonRegistros.jsonRegistros[indexJsonRegistros].dataTerminoCoberturaString === 'VITALÍCIO') {

            dataTerminoCobertura =
                dataAnoMesDia(
                    ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente) + 100/* - 1*/,
                    mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao),
                    dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao)
                )

        } else {
            
            dataTerminoCobertura = stringParaData(jsonRegistros.jsonRegistros[indexJsonRegistros].dataTerminoCoberturaString, 'dd/mm/yyyy')
        }

        valorRendaMensal = ''

        incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
            statusCobertura, valorCS, valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco, valorPremioExtra,
                duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
    }

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Forma de Pagamento:' + fimLinha, fimLinha,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)


        
    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}



export const importaListaApolicesPrudentialImplantacaoConsultorFinanceiroLP = (XLSX, idUsuarioLogado, jsonUsuario,
    equipeList, linhaIntegracao, seguradora, nomeArquivo, erros, variaveisGlobais) => {

    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)

    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (XLSX[linha][0]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, XLSX[linha][2])

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

           

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 0, HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 1, HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)
    
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 5, HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice,
                erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 3, HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 7, HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 10, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 9, HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 8, HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
            
            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 13, HARDCODE.importacaoCidadeSeguradoCliente, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 14, HARDCODE.importacaoUfSeguradoCliente, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 11, HARDCODE.importacaoEnderecoSeguradoCliente, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 12, HARDCODE.importacaoBairroSeguradoCliente, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 15, HARDCODE.importacaoCepSeguradoCliente, false, null, erros, nomeArquivo)
            
            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoNumeroEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
            
            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoComplementoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 16, HARDCODE.importacaoTelefoneSegurado, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 18, HARDCODE.importacaoTelefoneComercialSegurado, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 17, HARDCODE.importacaoCelularSeguradoCliente, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 19, HARDCODE.importacaoEmailSeguradoCliente, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 20, HARDCODE.importacaoNomeRespPagto, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 21, HARDCODE.importacaoCpfCnpjRespPagto, true, null, erros, nomeArquivo)
            
            if (jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto.length === 14) {

                jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto = ''
            }

            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)
           
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 31, HARDCODE.importacaoTipoPeriodicidadePagtoPremio,
                true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 32, HARDCODE.importacaoTipoFormaPagtoPremio,
                true, null, erros, nomeArquivo)


            
            if (usuarioClienteSelecionado) {
               
                jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
            
                const coberturas = []

                let valorPremioAnualizado = 0

                while (
                            linha < XLSX.length &&
                            ('' + XLSX[linha][0]) === jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice
                ) {

                    valorPremioAnualizado = round(valorPremioAnualizado + trataValorTexto(XLSX[linha][56]), 2)

                    let numeroCobertura
                    let codigoCobertura
                    let descricaoCobertura
                    let tipoCoberturaSeguroVida
                    let valorCS
                    let valorPremioLiquido
                    let valorIof
                    let valorPremio
                    let classeAjusteRisco
                    let valorPremioExtra
                    let duracaoPremioExtra
                    let dataTerminoCobertura
                    let valorRendaMensal

                    descricaoCobertura = trataTexto(XLSX[linha][34])

                    tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                        jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)
        
                    if (tipoCoberturaSeguroVida) {

                        numeroCobertura = XLSX[linha][33]
                        codigoCobertura = XLSX[linha][35]
                        valorCS = trataValorTexto(XLSX[linha][46])
                        valorPremioLiquido = trataValorTexto(XLSX[linha][53])
                        valorIof = trataValorTexto(XLSX[linha][54])
                        valorPremio = trataValorTexto(XLSX[linha][55])
                        classeAjusteRisco = trataTexto(XLSX[linha][36])
                        valorPremioExtra = trataValorTexto(XLSX[linha][47])
                        duracaoPremioExtra = trataInteiro(XLSX[linha][50])
                        dataTerminoCobertura = trataData(XLSX[linha][45])

                        const statusCobertura = identificaStatusCobertura(trataTexto(XLSX[linha][37]), linhaIntegracao,
                            jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

                        incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                            statusCobertura, valorCS, valorPremioLiquido, valorIof, valorPremio,
                                classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
                        
                        coberturas[coberturas.length - 1].ordem = numeroCobertura
                    }

                    linha++
                }

                jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado = valorPremioAnualizado

                jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = sortAsc(coberturas)

            } else {

                erros.push(MESSAGE().agenteNaoEncontradoProposta
                    .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                        .replace("$codigoAgente$", XLSX[linha][2])
                )

                linha++
            }
            
            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)

        } else {

            linha++
        }
    }

    return jsonRegistros.jsonRegistros
}



export const importaListaApolicesPrudentialImplantacaoSemAgente = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    //const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)

    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (XLSX[linha][0]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            

            //const usuarioClienteSelecionado = idUsuarioLogado//idAgenteTimeSeguradora(timeSeguradoraAux, XLSX[linha][2])

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

           

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 0, HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 1, HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)
    
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 4/*5*/, HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice,
                erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 2/*3*/, HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 5/*7*/, HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 8/*10*/, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 7/*9*/, HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 6/*8*/, HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
            
            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 11/*13*/, HARDCODE.importacaoCidadeSeguradoCliente, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 12/*14*/, HARDCODE.importacaoUfSeguradoCliente, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 9/*11*/, HARDCODE.importacaoEnderecoSeguradoCliente, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 10/*12*/, HARDCODE.importacaoBairroSeguradoCliente, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 13/*15*/, HARDCODE.importacaoCepSeguradoCliente, false, null, erros, nomeArquivo)
            
            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoNumeroEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
            
            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoComplementoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 14/*16*/, HARDCODE.importacaoTelefoneSegurado, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 16/*18*/, HARDCODE.importacaoTelefoneComercialSegurado, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 15/*17*/, HARDCODE.importacaoCelularSeguradoCliente, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 17/*19*/, HARDCODE.importacaoEmailSeguradoCliente, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 18/*20*/, HARDCODE.importacaoNomeRespPagto, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 19/*21*/, HARDCODE.importacaoCpfCnpjRespPagto, true, null, erros, nomeArquivo)
            
            if (jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto.length === 14) {

                jsonRegistros.jsonRegistros[indexJsonRegistros].cpfRespPagto = ''
            }

            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)
           
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 29/*31*/, HARDCODE.importacaoTipoPeriodicidadePagtoPremio,
                true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 30/*32*/, HARDCODE.importacaoTipoFormaPagtoPremio,
                true, null, erros, nomeArquivo)


            
            //if (usuarioClienteSelecionado) {
               
                //jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
            
                const coberturas = []

                let valorPremioAnualizado = 0

                while (
                            linha < XLSX.length &&
                            ('' + XLSX[linha][0]) === jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice
                ) {

                    valorPremioAnualizado = round(valorPremioAnualizado + trataValorTexto(XLSX[linha][54/*56*/]), 2)

                    let numeroCobertura
                    let codigoCobertura
                    let descricaoCobertura
                    let tipoCoberturaSeguroVida
                    let valorCS
                    let valorPremioLiquido
                    let valorIof
                    let valorPremio
                    let classeAjusteRisco
                    let valorPremioExtra
                    let duracaoPremioExtra
                    let dataTerminoCobertura
                    let valorRendaMensal

                    descricaoCobertura = trataTexto(XLSX[linha][32/*34*/])

                    tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                        jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)
        
                    if (tipoCoberturaSeguroVida) {

                        numeroCobertura = XLSX[linha][31/*33*/]
                        codigoCobertura = XLSX[linha][33/*35*/]
                        valorCS = trataValorTexto(XLSX[linha][44/*46*/])
                        valorPremioLiquido = trataValorTexto(XLSX[linha][51/*53*/])
                        valorIof = trataValorTexto(XLSX[linha][52/*54*/])
                        valorPremio = trataValorTexto(XLSX[linha][53/*55*/])
                        classeAjusteRisco = trataTexto(XLSX[linha][34/*36*/])
                        valorPremioExtra = trataValorTexto(XLSX[linha][45/*47*/])
                        duracaoPremioExtra = trataInteiro(XLSX[linha][48/*50*/])
                        dataTerminoCobertura = trataData(XLSX[linha][43/*45*/])

                        const statusCobertura = identificaStatusCobertura(trataTexto(XLSX[linha][35/*37*/]), linhaIntegracao,
                            jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

                        incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                            statusCobertura, valorCS, valorPremioLiquido, valorIof, valorPremio,
                                classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
                        
                        coberturas[coberturas.length - 1].ordem = numeroCobertura
                    }

                    linha++
                }

                jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado = valorPremioAnualizado

                jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = sortAsc(coberturas)

            //} else {

            //    erros.push(MESSAGE().agenteNaoEncontradoProposta
            //        .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
            //            .replace("$codigoAgente$", XLSX[linha][2])
            //    )

            //    linha++
            //}
            
            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)

        } else {

            linha++
        }
    }

    return jsonRegistros.jsonRegistros
}



export const importaCotacaoPrudentialTabelaEvolucaoValorResgate = (values, item, PDFTxt, nomeArquivo, erros) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)
    


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data da simulação:' + espaco, fimLinha,
        HARDCODE.importacaoDataCotacaoExtenso, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataCotacaoExtenso) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data da impressão:' + espaco, espaco + 'Data da última alteração:' + espaco,
            HARDCODE.importacaoDataCotacaoExtenso, true, null, erros, nomeArquivo)
    }
    


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Idade do Segurado Capital segurado (R$) Valor Quitado (R$) Valor de Resgate (R$) Valor Saldado (R$) Benefício Prolongado' + fimLinha,
        espaco + 'Valor de resgate se Iguala ao capital segurado' + fimLinha,
            HARDCODE.importacaoTabelaEvolucaoValorResgate, false, null, erros, nomeArquivo)

    let singlePremium = false

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate) {
    
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Idade do Segurado Capital segurado (R$) Valor Quitado (R$) Valor de Resgate (R$)' + fimLinha,
            espaco + 'Valor de resgate se Iguala ao capital segurado' + fimLinha,
                HARDCODE.importacaoTabelaEvolucaoValorResgate, true, null, erros, nomeArquivo)

        singlePremium = true
    }



    if (jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate) {

        const linhasTabelaEvolucaoValorResgateAux =
            jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate.split(fimLinha)
        let indexLinhasTabelaEvolucaoValorResgateAux = 0

        const linhasTabelaEvolucaoValorResgate = []
        let indexLinhasTabelaEvolucaoValorResgate = 0

        let numeroCotacao = numeroCotacaoAutomatico()
        let dataCotacao = converteDataExtensoData(jsonRegistros.jsonRegistros[indexJsonRegistros].dataCotacaoExtenso)
        let ano
        let idade
        let valorCS
        let valorPremioAnualizadoAcumulado
        let valorResgate
        let valorSaldado
        let beneficioProlongado

        item.tabelaEvolucaoValorResgate = []

        let dadosTabelaEvolucaoValorResgate

        while (indexLinhasTabelaEvolucaoValorResgateAux < linhasTabelaEvolucaoValorResgateAux.length) {

            if (
                    linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux].substring(0, 4) === 'ANO ' &&
                    linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux].indexOf('|') === -1
            ) {

                linhasTabelaEvolucaoValorResgate.push(
                    linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux]
                )
            }

            indexLinhasTabelaEvolucaoValorResgateAux++
        }

        while (indexLinhasTabelaEvolucaoValorResgate < linhasTabelaEvolucaoValorResgate.length) {

            dadosTabelaEvolucaoValorResgate = linhasTabelaEvolucaoValorResgate[indexLinhasTabelaEvolucaoValorResgate].split(espaco)

            ano = Number.parseInt(dadosTabelaEvolucaoValorResgate[1])
            idade = Number.parseInt(dadosTabelaEvolucaoValorResgate[2].substring(1))
            valorCS = trataValorTexto(dadosTabelaEvolucaoValorResgate[4])
            valorPremioAnualizadoAcumulado = trataValorTexto(dadosTabelaEvolucaoValorResgate[5])
            valorResgate = trataValorTexto(dadosTabelaEvolucaoValorResgate[6])
            valorSaldado = singlePremium ? '' : trataValorTexto(dadosTabelaEvolucaoValorResgate[7])
            beneficioProlongado = ''
            
            let indexDadosTabelaEvolucaoValorResgate = 8

            while (indexDadosTabelaEvolucaoValorResgate < dadosTabelaEvolucaoValorResgate.length) {

                beneficioProlongado +=
                    dadosTabelaEvolucaoValorResgate[indexDadosTabelaEvolucaoValorResgate] + espaco

                indexDadosTabelaEvolucaoValorResgate++
            }

            beneficioProlongado = beneficioProlongado.trim()
            
            incluiTabelaEvolucaoValorResgateCoberturaVidaInteira(values, item, numeroCotacao, dataCotacao, ano, idade, valorCS,
                valorPremioAnualizadoAcumulado, valorResgate, valorSaldado, beneficioProlongado)

            indexLinhasTabelaEvolucaoValorResgate++
        }
    }



    
    //jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    //return jsonRegistros.jsonRegistros
}



const importaCotacaoPrudentialXPTabelaEvolucaoValorResgate = (values, item, linhaIntegracaoCotacao, XLSX, deslocamento) => {

    let linha = linhaIntegracaoCotacao.cabecalho + 1

    let numeroCotacao = numeroCotacaoAutomatico()
    let dataCotacao = dataAtual()
    let ano = 0
    let idade
    let valorCS
    let valorPremioAnualizadoAcumulado
    let valorResgate
    let valorSaldado
    let beneficioProlongado

    item.tabelaEvolucaoValorResgate = []

    while (linha < XLSX.length) {

        if (XLSX[linha][1]) {

            ano++
            idade = XLSX[linha][1]
            valorCS = XLSX[linha][2]
            valorPremioAnualizadoAcumulado =
                XLSX[linha][4 + deslocamento] === 'Apólice Quitada' || !XLSX[linha][5 + deslocamento]
                    ?
                        0
                    :
                        XLSX[linha][5 + deslocamento]
            valorResgate = XLSX[linha][6 + deslocamento]
            valorSaldado = ''
            beneficioProlongado = ''

            incluiTabelaEvolucaoValorResgateCoberturaVidaInteira(values, item, numeroCotacao, dataCotacao, ano, idade, valorCS,
                valorPremioAnualizadoAcumulado, valorResgate, valorSaldado, beneficioProlongado)
        }

        linha++
    }
}

export const importaCotacaoPrudentialXPTabelaEvolucaoValorResgateAtual = (values, item, linhaIntegracaoCotacao, XLSX) => {

    importaCotacaoPrudentialXPTabelaEvolucaoValorResgate(values, item, linhaIntegracaoCotacao, XLSX, 0)
}

export const importaCotacaoPrudentialXPTabelaEvolucaoValorResgateAntiga = (values, item, linhaIntegracaoCotacao, XLSX) => {

    importaCotacaoPrudentialXPTabelaEvolucaoValorResgate(values, item, linhaIntegracaoCotacao, XLSX, -1)
}
import history from 'history.js'

import HARDCODE from '../../business-const/HardCode/HardCode'

import PATH from '../../business-const/Path/Path'

import AuthService from '../../business-api/AuthService'

import ClienteService from '../../business-api/ClienteService'

import {
    formataArrayData,
} from '../../business-components/Date/Date'

import {
    semAcento
} from '../../business-components/String/String'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../business-rules/List/ListService'



export const ajustaClienteListAux = (variaveisGlobais, clienteListAux, equipeListAux, jsonConfiguracao) => {

    clienteListAux?.forEach((linhaClienteListAux, indClienteListAux) => {

        let linhaEquipeList = pesquisaList(equipeListAux, linhaClienteListAux.idUsuario)

        clienteListAux[indClienteListAux].emailUsuarioOwnerCliente = linhaEquipeList.email
        clienteListAux[indClienteListAux].nascimentoClienteFormatado = formataArrayData(linhaClienteListAux.nascimentoCliente)
        clienteListAux[indClienteListAux].nascimentoConjugeFormatado = formataArrayData(linhaClienteListAux.nascimentoConjuge)
        clienteListAux[indClienteListAux].dataHoraInclusao = formataArrayData(linhaClienteListAux.dataHoraInclusao)

        if (linhaClienteListAux.jsonCompartilhamentoUsuario) {
            
            clienteListAux[indClienteListAux].jsonCompartilhamentoUsuario =
                JSON.parse(linhaClienteListAux.jsonCompartilhamentoUsuario)

            if (linhaClienteListAux.jsonCompartilhamentoUsuario.length > 0) {
            
                clienteListAux[indClienteListAux].descricaoFiliaisUnidades = ''
                
                linhaClienteListAux.jsonCompartilhamentoUsuario?.forEach( (linhaJsonCompartilhamentoUsuario,
                    indJsonCompartilhamentoUsuario) => {
                    
                    if (linhaJsonCompartilhamentoUsuario.id !== AuthService.obterUsuarioLogado(variaveisGlobais)) {

                        linhaEquipeList = pesquisaList(equipeListAux, linhaJsonCompartilhamentoUsuario.id)

                        const descricaoFilialUnidade =
                            pesquisaDescricaoList(jsonConfiguracao.filiaisUnidades, linhaEquipeList.filialUnidade)
                        
                        if (clienteListAux[indClienteListAux].descricaoFiliaisUnidades.indexOf(descricaoFilialUnidade) === -1) {

                            clienteListAux[indClienteListAux].descricaoFiliaisUnidades += ', ' + semAcento(descricaoFilialUnidade)
                        }
                    }
                })

                clienteListAux[indClienteListAux].descricaoFiliaisUnidades = 
                    clienteListAux[indClienteListAux].descricaoFiliaisUnidades.substring(2)
            }
        }
    })
}



export const redirecionarClienteForm = ( variaveisGlobais, idUsuario, idCliente, ultimaVersao, jsonComparacaoEstudos, versao,
    integracaoCRMNULL, jsonAcessoCliente, jsonIntegracao, jsonInfoclientCopia ) => {

    ClienteService.setarClienteSelecionado(variaveisGlobais, idCliente)
    ClienteService.setarUltimaVersaoClienteSelecionado(variaveisGlobais, ultimaVersao)
    ClienteService.setarJsonComparacaoEstudosClienteSelecionado(variaveisGlobais, JSON.parse(jsonComparacaoEstudos))
    ClienteService.setarVersaoSelecionada(variaveisGlobais, versao)
    ClienteService.setarUsuarioClienteSelecionado(variaveisGlobais, idUsuario)
    //ClienteService.setarUsuarioClienteSelecionadoIdentificacao(variaveisGlobais,
    //    pesquisaList(AuthService.obterUsuarioSelecionado(variaveisGlobais), idUsuario).label
    //)

    history.push({
        pathname: PATH.pageClienteForm,
        integracaoCRM: integracaoCRMNULL,
        jsonAcessoCliente: jsonAcessoCliente,
        jsonIntegracao: jsonIntegracao,
        jsonInfoclientCopia: jsonInfoclientCopia,
    })
}



export const redirecionarImportarClienteExcelForm = (variaveisGlobais, idUsuario) => {

    ClienteService.setarUsuarioClienteSelecionado(variaveisGlobais, idUsuario)

    history.push(PATH.pageImportarClienteExcelForm)
}



export const redirecionarIntegracaoClienteArquivoList = (variaveisGlobais, idUsuario, idConfiguracao) => {

    ClienteService.setarUsuarioClienteSelecionado(variaveisGlobais, idUsuario)

    history.push({
        pathname: PATH.pageIntegracaoClienteArquivoList,
        idConfiguracao: idConfiguracao,
    })
}



export const destaqueDataHoraPrevistaFunilEtapa = ( statusDataHoraPrevistaFunilEtapa ) => {

    return statusDataHoraPrevistaFunilEtapa === HARDCODE.statusDataHoraPrevistaFunilEtapaAtrasado
        ?
            'text-corDestaqueVermelho'
        :
            statusDataHoraPrevistaFunilEtapa === HARDCODE.statusDataHoraPrevistaFunilEtapaHoje
                ?
                    'text-corDestaqueAmarelo'
                :
                    'text-corDestaqueVerde'
}
import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

import AuthService from './AuthService'

import {
    usuarioInfo,
} from './UsuarioService'



class VwClienteAcessoService extends ApiService {

    constructor() {
        super(HARDCODE.apiVwClienteAcesso)
    }

    async buscarClienteList(variaveisGlobais, buscaCliente) {

        /*
        let params = `idUsuario=${AuthService.obterUsuarioLogado(variaveisGlobais)}`

        params = `${params}&jsonUsuarioSelecionado=${AuthService.usuarioSelecionadoList(variaveisGlobais)}`

        params = `${params}&tipoBusca=${buscaCliente.tipoBusca}`

        if (buscaCliente.valorMinimo) {
            params = `${params}&valorMinimo=${buscaCliente.valorMinimo}`
        }

        if (buscaCliente.funil) {
            params = `${params}&funil=${buscaCliente.funil}`
        }

        if (buscaCliente.etapa) {
            params = `${params}&etapa=${buscaCliente.etapa}`
        }

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoBuscarClienteList}/?${params}`, paramsAux, false)
        */

        const body = {
            getDTO: {
                idUsuario: AuthService.obterUsuarioLogado(variaveisGlobais),
                jsonUsuarioSelecionado: AuthService.usuarioSelecionadoListString(variaveisGlobais),
                tipoBusca: buscaCliente.tipoBusca,
                valorMinimo: buscaCliente.valorMinimo ? buscaCliente.valorMinimo : null,
                funil: buscaCliente.funil ? buscaCliente.funil : null,
                etapa: buscaCliente.etapa ? buscaCliente.etapa : null,
            },
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoBuscarClienteList, body, false)
    }

    async countCliente(variaveisGlobais, count) {

        /*
        let params = `idUsuario=${AuthService.obterUsuarioLogado(variaveisGlobais)}`

        params = `${params}&jsonUsuarioSelecionado=${AuthService.usuarioSelecionadoList(variaveisGlobais)}`

        params = `${params}&tipoCount=${count.tipoCount}`

        if (count.valorMinimo) {
            params = `${params}&valorMinimo=${count.valorMinimo}`
        }

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoCount}/?${params}`, paramsAux, false)
        */

        const body = {
            getDTO: {
                idUsuario: AuthService.obterUsuarioLogado(variaveisGlobais),
                jsonUsuarioSelecionado: AuthService.usuarioSelecionadoListString(variaveisGlobais),
                tipoCount: count.tipoCount,
                valorMinimo: count.valorMinimo ? count.valorMinimo : null,
            },
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoCount, body, false)
    }
    
    async countClienteFunilEtapas(variaveisGlobais) {

        /*
        let params = `idUsuario=${AuthService.obterUsuarioLogado(variaveisGlobais)}`
        
        params = `${params}&jsonUsuarioSelecionado=${AuthService.usuarioSelecionadoList(variaveisGlobais)}`

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoCountFunilEtapas}/?${params}`, paramsAux, false)
        */

        const body = {
            getDTO: {
                idUsuario: AuthService.obterUsuarioLogado(variaveisGlobais),
                jsonUsuarioSelecionado: AuthService.usuarioSelecionadoListString(variaveisGlobais),
            },
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoCountFunilEtapas, body, false)
    }
}

export default VwClienteAcessoService
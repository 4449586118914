import HARDCODE from '../../business-const/HardCode/HardCode'

import {
    importaApoliceAzos,
    importaListaApolicesAzosImplantacao,
} from '../../views/clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoServiceAzos'

import {
    importaApoliceBradescoVidaInteira,
    importaApoliceBradescoMultiPlanoVivaMais,
    importaCotacaoBradescoTabelaEvolucaoValorResgate,
} from '../../views/clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoServiceBradesco'

import {
    importaPropostaIcatuHorizonte,
    importaPropostaIcatuEssencial,
    importaListaPropostasIcatu,
    importaApoliceIcatuHorizonte,
    importaApoliceIcatuEssencial,
    importaListaApolicesIcatuImplantacao,
    importaCotacaoIcatuTabelaEvolucaoValorResgate,
} from '../../views/clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoServiceIcatu'

import {
    importaListaApolicesMAPFRE,
    importaListaCobrancasMAPFRE,
} from '../../views/clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoServiceMAPFRE'

import {
    importaPropostaMAG,
    importaListaPropostasMAG,
    importaListaPropostasMAGNovoLayout,
    importaListaPropostasImplantacaoMAG,
    importaApoliceMAGPrivateSolutions,
    importaApoliceMAGVidaToda,
    importaListaApolicesMAGImplantacao,
    importaListaComissoesMAG,
    importaCotacaoMAGTabelaEvolucaoValorResgate,
} from '../../views/clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoServiceMAG'

import {
    importaPropostaMetLife,
    importaPropostaMetLifeNovoLayout,
    importaListaPropostasImplantacaoMetLife,
    importaListaPropostasMetLife,
    importaApoliceMetLife,
    //importaApoliceMetLifeNovoLayout,
    importaListaApolicesMetLifeImplantacao,
    importaCotacaoMetLifeTabelaEvolucaoValorResgate,
} from '../../views/clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoServiceMetLife'

import {
    importaApoliceOmint,
} from '../../views/clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoServiceOmint'

import {
    importaListaTransferenciaClientesOutras,
    importaListaClientesAgentesOutras,
} from '../../views/clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoServiceOutras'

import {
    importaApolicePortoSeguro,
    importaConsorcioBensImoveisPortoSeguro,
} from '../../views/clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoServicePortoSeguro'

import {
    importaPropostaPrudential,
    importaApolicePrudential,
    importaApolicePrudentialSiteCliente,
    importaListaApolicesPrudentialImplantacaoConsultorFinanceiroLP,
    importaListaApolicesPrudentialImplantacaoSemAgente,
    importaCotacaoPrudentialTabelaEvolucaoValorResgate,
    importaCotacaoPrudentialXPTabelaEvolucaoValorResgateAtual,
    importaCotacaoPrudentialXPTabelaEvolucaoValorResgateAntiga,
} from '../../views/clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoServicePrudential'

import {
    importaPropostaXP,
    importaPosicaoInvestimentosXP,
} from '../../views/clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoServiceXP'

import {
    SvgImageParceirosBradescoLogo
} from '../../business-components/SvgImage/Parceiros/Bradesco/SvgImageParceirosBradesco'

import {
    SvgImageParceirosCNPConsorcioLogo
} from '../../business-components/SvgImage/Parceiros/CNP/SvgImageParceirosCNP'

import {
    SvgImageParceirosEmbraconConsorcioLogo
} from '../../business-components/SvgImage/Parceiros/Embracon/SvgImageParceirosEmbracon'

import {
    SvgImageParceirosIcatuLogo
} from '../../business-components/SvgImage/Parceiros/Icatu/SvgImageParceirosIcatu'

import {
    SvgImageParceirosItauConsorcioLogo
} from '../../business-components/SvgImage/Parceiros/Itau/SvgImageParceirosItau' 

import {
    SvgImageParceirosMAPFREConsorcioLogo
} from '../../business-components/SvgImage/Parceiros/MAPFRE/SvgImageParceirosMAPFRE' 

import {
    SvgImageParceirosMetLifeLogo
} from '../../business-components/SvgImage/Parceiros/MetLife/SvgImageParceirosMetLife'

import {
    SvgImageParceirosMAGLogo
} from '../../business-components/SvgImage/Parceiros/MAG/SvgImageParceirosMAG'

import {
    SvgImageParceirosPrudentialLogo
} from '../../business-components/SvgImage/Parceiros/Prudential/SvgImageParceirosPrudential'

import {
    SvgImageParceirosPortoSeguroLogo,
    SvgImageParceirosPortoSeguroConsorcioLogo,
} from '../../business-components/SvgImage/Parceiros/PortoSeguro/SvgImageParceirosPortoSeguro'



const tipoCoberturaSeguroVidaAzos = [
    {id: 'COBERTURA DE MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
    {id: 'MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},
    {id: 'MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
    {id: 'INVALIDEZ PERMANENTE TOTAL POR ACIDENTE MAJORADA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
    {id: 'DOENÇAS GRAVES', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
    {id: 'ASSISTÊNCIA FUNERAL FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'ASSISTÊNCIA FUNERAL INDIVIDUAL ATÉ', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'CIRURGIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaCirurgia},
    {id: 'DIÁRIA DE INTERNAÇÃO HOSPITALAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},
    {id: 'RENDA POR INCAPACIDADE TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT}
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDITA},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},
]

const statusApoliceAzos = [
    {id: 'VIGENTE', idConversao: HARDCODE.idStatusApoliceAtiva},
    {id: 'CANCELADA', idConversao: HARDCODE.idStatusApoliceCancelada},
]

const tipoCoberturaSeguroVidaBradesco = [
    {id: 'MORTE VITALICIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
    {id: 'MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
    //{id: 'MORTE TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
    //{id: 'MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},
    //{id: 'INVALIDEZ PERMANENTE TOTAL POR ACIDENTE MAJORADA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
    //{id: 'DOENÇAS GRAVES', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
    //{id: 'ASSISTÊNCIA FUNERAL FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    //{id: 'ASSISTÊNCIA FUNERAL INDIVIDUAL ATÉ', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    //{id: 'CIRURGIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaCirurgia},
    //{id: 'DIÁRIA DE INTERNAÇÃO HOSPITALAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},
    //{id: 'RENDA POR INCAPACIDADE TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT}
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDITA},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},
]

const tipoCoberturaSeguroVidaIcatu = [
    // Proposta
    {id: 'MORTE NATURAL OU ACIDENTAL - COM VIGÊNCIA TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
    {id: 'MORTE NATURAL OU ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
    {id: 'INDENIZAÇÃO ESPECIAL DE MORTE POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},
    {id: 'INDENIZAÇÃO ESPECIAL DE INVALIDEZ POR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca},
    {id: 'SERVIÇO DE ASSISTÊNCIA FUNERAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'DIARIA POR INTERNACAO HOSPITALAR (DIH)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},
    {id: 'DIÁRIA POR INTERNAÇÃO HOSPITALAR (DIH)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},
    // Apólice
    {id: 'MORTE QUALQUER CAUSA VITALÍCIO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
    {id: 'MORTE QUALQUER CAUSA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
    {id: 'INDENIZAÇÃO ESPECIAL POR MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},
    {id: 'ASSISTÊNCIA FUNERAL INDIVIDUAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'ASSISTÊNCIA FUNERAL FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'ASSISTÊNCIA FUNERAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'DIÁRIA POR INTERNAÇÃO HOSPITALAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDITA},
    {id: 'ADIANTAMENTO POR DOENÇA EM ESTÁGIO TERMINAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
    {id: 'INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
    {id: 'INDENIZAÇÃO ESPECIAL DE INVALIDEZ POR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},
    {id: 'INVALIDEZ FUNCIONAL PERMANENTE TOTAL POR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaCirurgia},
    // Proposta e Apólice
    {id: 'DIÁRIA POR INCAPACIDADE TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT},
    {id: 'DOENÇAS GRAVES', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
]

const statusApoliceIcatu = [
    {id: 'EMITIDA', idConversao: HARDCODE.idStatusApoliceAtiva},
    {id: 'CANCELADA', idConversao: HARDCODE.idStatusApoliceCancelada},
]

const formaPagtoPremioIcatu = [
    {id: 'STONE MUNDIPAG', idConversao: HARDCODE.idTipoFormaPagtoCartaoCredito},
    {id: '237 - BOLETO', idConversao: HARDCODE.idTipoFormaPagtoPremioBoleto},
    {id: 'DEBITO EM CONTA - SANTANDER', idConversao: HARDCODE.idTipoFormaPagtoPremioDebito},
    {id: 'DEBITO EM CONTA - CAIXA ECO', idConversao: HARDCODE.idTipoFormaPagtoPremioDebito},
    {id: 'DEBITO EM CONTA - ITAÚ', idConversao: HARDCODE.idTipoFormaPagtoPremioDebito},
    {id: 'DEBITO EM CONTA - B.BRASIL', idConversao: HARDCODE.idTipoFormaPagtoPremioDebito},
    {id: 'DEBITO EM CONTA - BRADESCO', idConversao: HARDCODE.idTipoFormaPagtoPremioDebito},
    {id: 'DÉBITO EM CONTA - INTER', idConversao: HARDCODE.idTipoFormaPagtoPremioDebito},
    {id: 'DÉBITO EM CONTA-UNICRED SC/PR', idConversao: HARDCODE.idTipoFormaPagtoPremioDebito},
    {id: 'DEBITO C/C SICREDI', idConversao: HARDCODE.idTipoFormaPagtoPremioDebito},
    {id: '756 - DEB C/C - CONV: 8490080', idConversao: HARDCODE.idTipoFormaPagtoPremioDebito},
    {id: 'PIX', idConversao: HARDCODE.idTipoFormaPagtoPremioPix},
]

const tipoCoberturaSeguroVidaMAG = [
    {id: 'DIT COM LER E DORT', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT}, //NOVO
    {id: 'DIT – BENEF. INVALIDEZ POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},//Novo
    {id: 'DIT – BENEF. INCAPACIDADE TEMP. (COB. PRINCIPAL)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT}, //Novo
    {id: 'DIT – BENEF. POR MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental}, //Novo
    {id: 'DIT – BENEF. INCAPACIDADE TEMP. (COB. PRINCIPAL)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT}, //Novo
    {id: 'DIT - DIÁRIA POR INCAPACIDADE TEMPORÁRIA (COB. PRINCIPAL)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT}, //Novo
    {id: 'DIT – DIÁRIA POR INCAPACIDADE TEMPORÁRIA (COB. PRINCIPAL)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT}, //Novo
    {id: 'DIT - BENEFÍCIO POR MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental}, //Novo
    {id: 'DIT - INVALIDEZ POR ACIDENTE MAJORADA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
    {id: 'DIT MÉDICOS + MQC 10 DIAS: MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Caso Especial
    {id: 'DIT MÉDICOS + MQC 10 DIAS - RIDER: MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Caso Especial
    {id: 'DIT MÉDICOS + MQC 7 DIAS: MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Caso Especial
    {id: 'DIT MÉDICOS + MQC 7 DIAS - RIDER: MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Caso Especial
    {id: 'DIT + MQC 7 DIAS: MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Caso Especial
    {id: 'DIT + MQC 7 DIAS - RIDER: MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Caso Especial
    {id: 'DIT + MQC 10 DIAS: MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Caso Especial
    {id: 'DIT + MQC 10 DIAS - RIDER: MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Caso Especial
    {id: 'DIT + MQC 7 DIAS - 2397', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Checar
    {id: 'DIT + MQC 7 DIAS - RIDER - 2419', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Checar
    {id: 'DIT + MQC 10 DIAS - 2399', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Checar
    {id: 'DIT + MQC 10 DIAS - RIDER - 2419', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Checar
    {id: 'DIT + MQC 10 DIAS - RIDER - 2420', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Checar
    {id: 'DIT + MAC + IPAM 7 DIAS - 2396', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},//Checar
    {id: 'DIT + MAC + IPAM 10 DIAS - 2398', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},//Checar
    {id: 'DIT + MAC + IPAM 10 DIAS - RIDER - 2422', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},//Checar
    {id: 'DIT MÉDICOS + MQC 7 DIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Checar
    {id: 'DIT MÉDICOS + MQC 10 DIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTradicional},//Checar
    {id: 'DIT MÉDICOS + MAC + IPAM 7 DIAS - 2400', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},//Checar
    {id: 'DIT MÉDICOS + MAC + IPAM 7 DIAS - RIDER - 2532', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},//Checar
    {id: 'DIT MÉDICOS + MAC + IPA 10 DIAS - 2403', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},//Checar
    {id: 'DIT MÉDICOS + MAC + IPAM 10 DIAS - RIDER - 2434', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},//Checar
    {id: 'PCHV MÓDULO II - PCHV + DIT - 2182', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT},//Checar
    {id: 'MASTER ACIDENTES DOMICILIARES - 2342', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT},//Checar
    {id: 'DIÁRIA POR INCAPACIDADE TEMPORÁRIA POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDITA},//Novo
    {id: 'DIARIA POR INCAPACIDADE TEMPORÁRIA POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDITA},//Novo
    {id: 'DIÁRIA DE INCAPACIDADE TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT},//novo
    {id: 'DIARIA DE INCAPACIDADE TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT},//Proposta
    {id: 'DIARIA DE INCAPACIDADE TEMPORARIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT},//proposta
    {id: 'DIÁRIA INCAPACIDADE TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT},//proposta
    {id: 'PRAZO CERTO: PRAZO CERTO - BENEFÍCIO POR MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//Caso Especial
    {id: 'PRAZO CERTO – BENEFÍCIO POR MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//Caso Especial
    {id: '2113MORTE + ADT + DPI', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario, descricao: 'MORTE + ADT + DPI'}, //NOVO
    {id: '2435MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario, descricao: 'MORTE'}, //RECENTE
    {id: '2420MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario, descricao: 'MORTE'}, //RECENTE
    {id: 'TERM LIFE - MORTE + ADT + DPI', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//Proposta
    {id: 'TERM LIFE - MORTE+ADT+DPI', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//Proposta
    {id: 'TERM LIFE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//Proposta
    {id: 'MORTE + ADT + DPI', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
    {id: 'VIDA INTEIRA RESGATÁVEL - MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},//Recente
    {id: 'VIDA INTEIRA – BENEFÍCIO POR MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},//novo
    {id: 'VIDA INTEIRA - BENEFÍCIO POR MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},//Caso Especial
    {id: 'VIDA INTEIRA - 2694', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},//Caso Especial
    {id: 'CAPITAL SEGURADO POR MORTE - 1546', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},//Checar
    {id: 'WHOLE LIFE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},//Recente
    {id: 'MORTE VIDA INTEIRA RESGATÁVEL - BENEFÍCIO POR MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},//Novo
    {id: 'MORTE VIDA INTEIRA RESGATÁVEL – BENEFÍCIO POR MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},//Novo
    {id: 'MORTE DECRESCENTE + ADT + DPI', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteiraDecrescente},
    {id: 'MORTE DECRESCENTE+ADT+DPI', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteiraDecrescente},
    {id: 'MORTE DECRESCENTE 10 ANOS + ADT + DPI', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteiraDecrescente},//Proposta
    {id: 'BENEFÍCIO POR MORTE VITALÍCIO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
    {id: 'BENEFÍCIO POR MORTE TEMPORÁRIO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//novo
    {id: 'TOTAL – BENEFÍCIO POR MORTE – COB. PRINCIPAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//recente
    {id: 'TOTAL – BENEFÍCIO POR MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},//recente
    {id: 'BENEFÍCIO POR MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},
    {id: 'BENEFICIO POR MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},//Novo
    {id: 'BENEFÍCIO POR MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//novo
    {id: 'DOENÇAS GRAVES PREMIUM', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
    {id: 'DOENÇAS GRAVES PLUS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
    {id: 'DOENÇAS GRAVES ESSENCIAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},//RECENTE
    {id: 'TOTAL – DOENÇAS GRAVES', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},//recente
    {id: 'DOENÇAS GRAVES', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves}, //NOVO
    {id: 'CIRUGIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaCirurgia},
    {id: 'CIRURGIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaCirurgia},//Novo
    {id: 'SAF - SEGURO DE ASSISTÊNCIA FUNERAL SUPER LUXO FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral}, //NOVO
    {id: 'SAF SEGURO DE ASSISTENCIA FUNERAL SUPER LUXO FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral}, //NOVO
    {id: 'SEGURO ASSISTENCIA FUNERAL INDIVIDUAL SUPERLUXO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'SEGURO ASSISTENCIA FUNERAL FAMILIAR SUPERLUXO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    //{id: 'SEGURO DE ASSISTENCIA FUNERAL LUXO INDIVIDUAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//N-o-v-o
    {id: 'SEGURO DE ASSISTÊNCIA FUNERAL FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Novo
    {id: 'SEGURO DE ASSISTENCIA FUNERAL LUXO INDIVIDUAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Caso Especial
    {id: 'SEGURO DE ASSISTENCIA FUNERAL INDIVIDUAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Caso Especial
    {id: 'SEGURO DE ASSISTENCIA FUNERAL LUXO FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Caso Especial
    {id: 'SEGURO DE ASSISTÊNCIA FUNERAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'SEGURO ASSISTÊNCIA FUNERAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'SEGURO ASSISTENCIA FUNERAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Proposta
    {id: 'SEG. ASSIST. FUNERAL LUXO FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//novo
    {id: 'SEG. ASSIST. FUNERAL SUPERLUXO INDIVIDUAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Novo
    {id: 'SEG. ASSIST. FUNERAL SUPERLUXO FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Novo
    {id: 'SAF SEGURO DE ASSISTENCIA FUNERAL SUPER LUXO INDIVIDUAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Novo
    {id: 'SAF SEGURO DE ASSISTENCIA FUNERAL SUPER LUXO FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Novo
    {id: 'SAF FAMILIAR SUPERLUXO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Novo
    {id: 'SAF FAMILIAR LUXO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Novo
    {id: 'SAF INDIVIDUAL SUPERLUXO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Novo
    {id: 'DIARIA POR INTERNACAO HOSPITALAR VIDA INTEIRA RESGATÁVEL - DIÁRIA POR INTERNAÇÃO HOSPITALAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},//Novo
    {id: 'DIÁRIA POR INTERNAÇÃO HOSPITALAR – 150 DIÁRIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},
    {id: 'DIÁRIA POR INTERNAÇÃO HOSPITALAR – 200 DIÁRIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},//Novo
    {id: 'DIÁRIA POR INTERNAÇÃO HOSPITALAR – 250 DIÁRIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},//Novo
    {id: 'DIH - 150 DIÁRIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},//Novo
    {id: 'DIH - 200 DIÁRIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},//Novo
    {id: 'DIH - 250 DIÁRIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},//Novo
    {id: 'INTERNAÇÃO HOSPITALAR 150 DIARIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},//RECENTE
    {id: 'INTERNAÇÃO HOSPITALAR 200 DIARIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},//N-o-v-o
    {id: 'INTERNAÇÃO HOSPITALAR 250 DIARIAS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},//RECENTE
    {id: 'INTERNAÇÃO HOSPITALAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},//Proposta
    {id: 'ADICIONAL UTI', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAdicionalUTI},
    {id: 'ADICIONAL – UTI', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAdicionalUTI},
    {id: 'ADICIONAL - UTI', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAdicionalUTI},
    {id: 'FERIDAS ACIDENTAIS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaFeridasAcidentais},
    {id: 'REEMBOLSO AMBULANCIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaReembolsoAmbulancia},
    {id: 'REEMBOLSO EMERGENCIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaReembolsoEmergencia},
    {id: 'IPA MAJORADA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
    {id: 'IPA COM MAJORAÇÃO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial}, //novo
    {id: 'CAPTAL SEGURADO POR IPA - 1537', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial}, //novo
    {id: 'INVALIDEZ POR ACIDENTE VIDA INTEIRA RESGATÁVEL - INVALIDEZ POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},//Novo
    {id: 'INVALIDEZ POR ACIDENTE COM MAJORAÇÃO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},//Novo
    {id: 'INVALIDEZ POR ACIDENTE MAJORADA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
    {id: 'INVALIDEZ POR ACIDENTE (COB.PRINCIPAL)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},//novo
    {id: 'INVALIDEZ POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},//novo
    {id: 'ASSISTÊNCIA PERSONAL CARE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},//novo
    {id: 'INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},//Novo
    {id: 'INVALIDEZ PERMANENTE POR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca},
    {id: 'INVALIDEZ TOTAL POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},//Proposta
    {id: 'IPTA - RIDER - 2427', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},//Proposta
    {id: 'IPTA - 1548', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},//Proposta
    {id: 'TOTAL – INVALIDEZ POR ACIDENTE MAJORADA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},//recente
    {id: 'INVALIDEZ FUNCIONAL POR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},//novo
    {id: 'INVALIDEZ FUNCIONAL PERMANENTE TOTAL POR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca},
    {id: 'INVALIDEZ POR DOENÇA VIDA INTEIRA RESGATÁVEL - INVALIDEZ POR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},//Novo
    {id: 'INVALIDEZ POR DOENÇA MAJORADA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},//N-o-v-o
    {id: 'INVALIDEZ POR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},//Novo
    {id: 'CAPITAL SEGURADO POR IFPD/IPA - 1538', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},//Novo
    {id: 'ASSISTENCIA PREMIUM - AUTO E MOTO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaAutoMoto},//proposta
    {id: 'ASSISTENCIA PREMIUM - RESIDENCIAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaAutoMoto},//proposta
    {id: 'ASSISTENCIA PREMIUM - DESCONTO EM MEDICAMENTOS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaDescontoMedicamentos},//proposta
    {id: 'ASSISTÊNCIA TELESSAUDE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaTelessaude},//RECENTE
    {id: 'SEGURANÇA EXCLUSIVA PREMIUM', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//proposta
    {id: 'RENDA POR INVALIDEZ', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteRenda},//RECENTE
    {id: 'MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental}, //NOVO
    {id: 'MORTE ACIDENAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental}, //Proposta
    {id: 'MORTE POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental}, //Proposta
    {id: '2096 – MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira}, //RECENTE
    {id: '2011 – MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira}, //RECENTE
    {id: '2104 – MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira}, //RECENTE
    {id: '2399 – MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario}, //RECENTE
    {id: '2397 – MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario}, //RECENTE
    {id: '2401 – MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario}, //RECENTE
]

const statusPropostaMAG = [
    {id: 'PENDENTE', idConversao: HARDCODE.idStatusPropostaPendente},
    {id: 'ACEITA', idConversao: HARDCODE.idStatusPropostaPendente},
    {id: 'ELEGÍVEL PARA RECUSA', idConversao: HARDCODE.idStatusPropostaPendente},
    {id: 'EM ANÁLISE', idConversao: HARDCODE.idStatusPropostaEmAnalise},
    {id: 'ACEITE AUTOMÁTICO', idConversao: HARDCODE.idStatusPropostaEmAnalise},
    {id: 'EM CONFERÊNCIA', idConversao: HARDCODE.idStatusPropostaEmAnalise},
    {id: 'SUSPENSA', idConversao: HARDCODE.idStatusPropostaPendente},
    {id: 'IMPLANTADA', idConversao: HARDCODE.idStatusPropostaImplantada},
    {id: 'IMPLANTADA - EMITIDA', idConversao: HARDCODE.idStatusPropostaPaga},
    {id: 'IMPLANTADA - CANCELADA', idConversao: HARDCODE.idStatusPropostaRecusada},
    {id: 'IMPLANTADA - EMITIDA CANCELADA', idConversao: HARDCODE.idStatusPropostaRecusada},//novo
    {id: 'CANCELADA', idConversao: HARDCODE.idStatusPropostaRecusada},
    {id: 'RECUSADA', idConversao: HARDCODE.idStatusPropostaRecusada},
]

const tipoPeriodicidadePagtoPremioMAG = [
    {id: 30, idConversao: HARDCODE.idTipoPeriodicidadeMensal},
    {id: 180, idConversao: HARDCODE.idTipoPeriodicidadeSemestral},
    {id: 365, idConversao: HARDCODE.idTipoPeriodicidadeAnual},
]

const statusCoberturaMAG = [
    {id: 'ATIVA', idConversao: HARDCODE.idStatusCoberturaAtivaPagandoPremio},
    {id: 'REMIDO', idConversao: HARDCODE.idStatusCoberturaAtivaPagandoPremio},
    {id: 'PAGAMENTO ÚNICO', idConversao: HARDCODE.idStatusCoberturaAtivaPremioPago},
    //{id: 'EXPIRED', idConversao: HARDCODE.idStatusCoberturaExpirada},
    //{id: 'CONVERTED', idConversao: HARDCODE.idStatusCoberturaConvertida},
    {id: 'RESGATE', idConversao: HARDCODE.idStatusCoberturaResgatada},
    {id: 'SALDAMENTO', idConversao: HARDCODE.idStatusCoberturaSaldada},
    {id: 'DESISTÊNCIA', idConversao: HARDCODE.idStatusCoberturaCancelada},
    {id: 'INADIMPLÊNCIA', idConversao: HARDCODE.idStatusCoberturaCancelada},
    {id: 'CANCELADO PELA REGULAÇÃO', idConversao: HARDCODE.idStatusCoberturaCancelada},
    //{id: 'DEATH', idConversao: HARDCODE.idStatusCoberturaSinistroEmAnalise},
    {id: 'PECÚLIO', idConversao: HARDCODE.idStatusCoberturaBeneficioPago},
    //{id: 'REJECTED', idConversao: HARDCODE.idStatusCoberturaRejeitada},
    //{id: 'REPLACED', idConversao: HARDCODE.idStatusCoberturaSubstituida},
    {id: 'CONTRATO ANULADO', idConversao: HARDCODE.idStatusCoberturaAnulada},
]

const tipoCoberturaSeguroVidaMetLife = [
    {id: 'VIDA TOTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
    {id: 'VIDA SEGURA MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//NOVO
    {id: 'MORTE TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//NOVO
    {id: 'MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},//novo
    {id: 'MORTE COM CAPITAL DECRESCENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporarioDecrescente},
    {id: 'INVALIDEZ PERMANENTE POR DOENÇA FUNCIONAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca},
    {id: 'INVALIDEZ ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteParcial},
    {id: 'DOENÇAS GRAVES', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
    {id: 'FRATURA ÓSSEA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaQuebraOssos},//NOVO
    {id: 'DIÁRIA POR INTERNAÇÃO HOSPITALAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},
    {id: 'DIÁRIA POR INCAPACIDADE TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT},
    {id: 'FUNERAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'RENDA POR INCAPACIDADE TEMP. COM DOENÇA CRÔNICA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT}, //Novo
]

const statusPropostaMetLife = [
    {id: 'AGUARDANDO ENVIO DAS INFORMAÇÕES COMPLEMENTARES', idConversao: HARDCODE.idStatusPropostaPendente},
    {id: 'AGUARDANDO CARTA ADENDO ASSINADA', idConversao: HARDCODE.idStatusPropostaPendente},
    {id: 'AGUARDANDO ANÁLISE TÉCNICA', idConversao: HARDCODE.idStatusPropostaPendente},
    {id: 'PROPOSTA EM REVISÃO TÉCNICA', idConversao: HARDCODE.idStatusPropostaPendente},
    {id: 'AGUARDANDO CONFERÊNCIA DE TAXA', idConversao: HARDCODE.idStatusPropostaPendente},
    {id: 'INFORMAÇÕES ADICIONAIS DISPONIBILIZADAS', idConversao: HARDCODE.idStatusPropostaPendente},
    {id: 'AGUARDANDO REALIZAÇÃO/ENVIO DE EXAMES DE PERFIL (SAMPLEMED)', idConversao: HARDCODE.idStatusPropostaPendente},
    {id: 'CARTA ADENDO RECEBIDA - AGUARDANDO ANÁLISE', idConversao: HARDCODE.idStatusPropostaEmAnalise},
    {id: 'PROPOSTA ACEITA – EM PROCESSO DE IMPLANTAÇÃO', idConversao: HARDCODE.idStatusPropostaEmAnalise},
    {id: 'EM ANÁLISE', idConversao: HARDCODE.idStatusPropostaEmAnalise},
    {id: 'PROPOSTA IMPLANTADA', idConversao: HARDCODE.idStatusPropostaImplantada},
    //{id: '', idConversao: HARDCODE.idStatusPropostaPaga},
    {id: 'CANCELADO PELO USUÁRIO', idConversao: HARDCODE.idStatusPropostaCancelada},
    {id: 'PROPOSTA ENCERRADA A PEDIDO DO CLIENTE', idConversao: HARDCODE.idStatusPropostaCancelada},
    {id: 'PROPOSTA RECUSADA', idConversao: HARDCODE.idStatusPropostaRecusada},
    {id: 'PROPOSTA RECUSADA AUTOMATICAMENTE', idConversao: HARDCODE.idStatusPropostaRecusada},
    {id: 'PROPOSTA RECUSADA TEMPORARIAMENTE', idConversao: HARDCODE.idStatusPropostaRecusada},
]

const tipoCoberturaSeguroVidaOmint = [
    {id: 'MORTE – COBERTURA TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
    {id: 'MORTE - M', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
    {id: 'MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},
    {id: 'INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
    {id: 'INVALIDEZ PERMANENTE POR ACIDENTE MAJORADA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteParcial},
    {id: 'INVALIDEZ FUNCIONAL PERMANENTE TOTAL DOR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca},
    {id: 'INVALIDEZ FUNCIONAL PERMANENTE TOTAL POR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca},
    {id: 'IPA MAJORADA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
    {id: 'DOENÇAS GRAVES AMPLIADA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
    {id: 'DOENÇAS GRAVES', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
    {id: 'DIÁRIA DE INTERNAÇÃO HOSPITALAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},
    {id: 'FUNERAL INDIVIDUAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'FUNERAL FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'JAZIGO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT}
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaCirurgia},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDITA},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},
]

const statusApolicePrudential = [
    {id: 'ATIVA', idConversao: HARDCODE.idStatusApoliceAtiva},
    {id: 'VIGENTE', idConversao: HARDCODE.idStatusApoliceAtiva},//Excel
    {id: 'CANCELADA', idConversao: HARDCODE.idStatusApoliceCancelada},
    {id: 'CANCELADO', idConversao: HARDCODE.idStatusApoliceCancelada},//Excel
    {id: 'REJEITADA', idConversao: HARDCODE.idStatusApoliceRejeitada},//Nova
    {id: 'CONVERTIDA', idConversao: HARDCODE.idStatusApoliceConvertida},//Nova
    {id: 'SUBSTITUÍDA', idConversao: HARDCODE.idStatusApoliceSubstituida},//Nova
    {id: 'ANULADA', idConversao: HARDCODE.idStatusApoliceAnulada},//Nova
    {id: 'COBERTURA TERMINADA', idConversao: HARDCODE.idStatusApoliceTerminada},//Nova
    {id: 'SINISTRADA', idConversao: HARDCODE.idStatusApoliceSinistrada},////Nova
]

const tipoCoberturaSeguroVidaPrudential = [
    {id: 'VIDA INTEIRA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
    {id: 'VIDA E SAUDE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},////novo
    {id: 'TEMPORÁRIO DECRESCENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporarioDecrescente},
    {id: 'TEMPORARIO DECRESCENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporarioDecrescente},//novo*
    {id: 'TEMPORARI0 DECRESCENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporarioDecrescente},//novo*
    {id: 'TEMPORÁRIO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
    {id: 'TEMPORARIO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},//novo
    {id: 'RENDA FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaRendaFamiliar},
    {id: 'DOTAL MISTO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDotalMisto},
    {id: 'DOTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDotal},
    {id: 'MORTE ACID.', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},
    {id: 'MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},
    {id: 'INVALIDEZ PARCIAL POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteParcial},
    {id: 'INVALIDEZ PARCIAL ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteParcial},
    {id: 'INVALIDEZ ACIDENTAL POR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteParcial},//novo
    {id: 'INVALIDEZ ACIDENTAL 5', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteParcial},//Novo
    {id: 'INVALIDEZ TOTAL E PARCIAL POR  ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
    {id: 'INVALIDEZ TOTAL E PARCIAL POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
    {id: 'PERDA DA AUTONOMIA PESSOAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},
    {id: 'PERDA AUTONOMIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},//nobo
    {id: 'DOENÇAS GRAVES', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
    {id: 'DOENCAS GRAVES', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},//novo
    {id: 'DOENÇAS MODULAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
    {id: 'DOENCAS MODULAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},//novo*
    {id: 'DOENÇAS PLUS ASSIST', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},//super novo
    {id: 'DOENCAS PLUS ASSIST', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},//super novo
    {id: 'CIRURGIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaCirurgia},
    {id: 'QUEBRA DE OSSOS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaQuebraOssos},
    {id: 'RENDA HOSPITALAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},
    {id: 'ASSISTÊNCIA FUNERAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'ASSISTENCIA FUNERAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//novo*
    {id: 'ASSISTENCIA FUNERAL POR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},//Novo
    {id: 'ASSIST FUNERAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'A_RPU SEGURO COM VALOR SALDADO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaValorSaldado},
    {id: 'B_RPU SEGURO COM VALOR SALDADO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaValorSaldado},
    {id: 'C_RPU SEGURO COM VALOR SALDADO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaValorSaldado},
    {id: '**RPU SEGURO COM VALOR SALDADO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaValorSaldado},
    {id: 'F_RPU SEGURO COM VALOR SALDADO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaValorSaldado},
    {id: 'RPU - VALOR SALDADO (A_RPU)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaValorSaldado},//Excel
    {id: 'RPU - VALOR SALDADO (B_RPU)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaValorSaldado},//Excel
    {id: 'RPU - VALOR SALDADO (C_RPU)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaValorSaldado},//Excel
    {id: 'RPU - VALOR SALDADO (F_RPU)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaValorSaldado},//Excel
    {id: 'RPU - VALOR SALDADO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaValorSaldado},//Excel
    {id: 'B_ETI TEMPORÁRIO COM BENEFÍCIO PROLONGADO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaBeneficioProlongado},
    {id: 'B_ETI TEMPORARIO COM BENEFICIO PROLONGADO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaBeneficioProlongado},//novo*
    {id: 'C_ETI TEMPORÁRIO COM BENEFÍCIO PROLONGADO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaBeneficioProlongado},
    {id: 'C_ETI TEMPORARIO COM BENEFICIO PROLONGADO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaBeneficioProlongado},//novo*
    {id: 'ETI - BENEFÍCIO PROLONGADO (C_ETI)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaBeneficioProlongado},//Excel
    {id: 'ETI - BENEFÍCIO PROLONGADO (F_ETI)', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaBeneficioProlongado},//Excel
    {id: 'ETI - BENEFÍCIO PROLONGADO', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaBeneficioProlongado},//Excel
]

const statusCoberturaPrudential = [
    {id: 'PREMIUM PAYING', idConversao: HARDCODE.idStatusCoberturaAtivaPagandoPremio, linhas: 2},
    {id: 'PREMIUM', idConversao: HARDCODE.idStatusCoberturaAtivaPagandoPremio, linhas: 3},
    {id: 'PAID-UP LIFE', idConversao: HARDCODE.idStatusCoberturaAtivaPremioPago, linhas: 1},
    {id: 'PAID-UP ANNUIT', idConversao: HARDCODE.idStatusCoberturaAtivaPremioPago, linhas: 1},
    {id: 'ATIVA - PRÊMIO DISPENSADO (WAIVER)', idConversao: HARDCODE.idStatusCoberturaAtivaPremioDispensado, linhas: 1},
    {id: 'PAGO', idConversao: HARDCODE.idStatusCoberturaAtivaPremioPago, linhas: 1},//Novo
    {id: 'EXPIRED', idConversao: HARDCODE.idStatusCoberturaExpirada, linhas: 1},
    {id: 'CONVERTED', idConversao: HARDCODE.idStatusCoberturaConvertida, linhas: 1},
    {id: 'SURRENDERED', idConversao: HARDCODE.idStatusCoberturaResgatada, linhas: 1},
    {id: 'LAPSED', idConversao: HARDCODE.idStatusCoberturaCancelada, linhas: 1},
    {id: 'DEATH', idConversao: HARDCODE.idStatusCoberturaSinistroEmAnalise, linhas: 1},
    {id: 'BENEFÍCIO PAGO', idConversao: HARDCODE.idStatusCoberturaBeneficioPago, linhas: 1},
    {id: 'MATURED', idConversao: HARDCODE.idStatusCoberturaBeneficioPago, linhas: 1},//Novo
    {id: 'REJECTED', idConversao: HARDCODE.idStatusCoberturaRejeitada, linhas: 1},//Novo
    {id: 'REPLACED', idConversao: HARDCODE.idStatusCoberturaSubstituida, linhas: 1},//Novo
    {id: 'NOT TAKEN', idConversao: HARDCODE.idStatusCoberturaAnulada, linhas: 1},//Novo
]



const tipoCoberturaSeguroVidaPortoSeguro = [
    {id: 'MORTE NATURAL OU ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
    //{id: 'MORTE - M', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
    {id: 'MORTE ACIDENTAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},
    {id: 'INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR ACIDENTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
    {id: 'ANTECIPAÇÃO ESPECIAL POR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},
    //{id: 'INVALIDEZ PERMANENTE POR ACIDENTE MAJORADA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteParcial},
    //{id: 'INVALIDEZ FUNCIONAL PERMANENTE TOTAL DOR DOENÇA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca},
    {id: 'DOENÇAS GRAVES - 17 TIPOS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
    //{id: 'DIÁRIA DE INTERNAÇÃO HOSPITALAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},
    {id: 'DIÁRIA POR INCAPACIDADE TEMPORÁRIA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT},
    {id: 'ASSISTÊNCIA FUNERAL - TITULAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'ASSISTÊNCIA FUNERAL - FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    {id: 'FUNERAL EXTENSIVO AOS PAIS', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    //{id: 'FUNERAL FAMILIAR', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
    //{id: 'MORTE', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaCirurgia},
    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDITA},
]



const instituicaoFinanceiraList = [
    //{
    //    id: 5,
    //    descricao: 'Âncora Consórcios',
    //    administradoraConsorcio: 'S',
    //    corretoraValores: 'N',
    //    seguradora: 'N',
    //    ordem: 5,
    //},
    {
        id: 10,
        descricao: 'Alfa',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 10,
    },
    {
        id: 15,
        descricao: 'Azos',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        integracao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoApolice,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Apólice de seguro',
                        'EXCELSIOR AZOS - PESSOAS INDIVIDUAL',
                        'Os prêmios apresentados na tabela acima indicam o prêmio de cada',
                        'O Capital Segurado será atualizado anualmente pelo IPCA/IBGE, conforme previsto nas',
                        'A COMPANHIA EXCELSIOR DE SEGUROS, a seguir denominada Seguradora, emite',
                        'Comprometida com os padrões de ética e integridade no atendimento a seus clientes,',
                    ],
                rotinaIntegracao: importaApoliceAzos,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaAzos,
                statusApolice: statusApoliceAzos,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaApolicesImplantacao,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                cabecalho: 0,
                chaveIntegracao:
                [
                    'razao_social',
                    'email_corretor',
                    'cpf_cnpj_corretor',
                    'plataforma',
                    'data_entrada_plataforma',
                    'data_saida_plataforma',
                    'numero_da_proposta',
                    'id_proposta',
                    'link_proposta',
                    'status_proposta',
                    'numero_da_apolice',
                    'id_apolice',
                    'link_apolice',
                    'status_apolice',
                    'valor_total',
                    'valor_anualizado',
                    'frequencia_pagamento',
                    'iof_total',
                    'comissao',
                    'nome_cliente',
                    'cpf_cliente',
                    'email_cliente',
                    'data_proposta',
                    'data_analise',
                    'inicio_vigencia_apolice',
                    'data_emissao_fatura',
                    'data_vencimento_fatura',
                    'link_fatura',
                    'data_pagamento',
                    'status_ultimo_pagamento',
                    'metodo_pagamento',
                    'tipo_acesso',
                    'id_broker',
                    'id_grupo',
                    'take_rate',
                    'data_cancelamento',
                    'mes_analise',
                    'ano_analise',
                ],
                rotinaIntegracao: importaListaApolicesAzosImplantacao,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaAzos,
                statusApolice: statusApoliceAzos,
                //statusCobertura: statusCoberturaMAG,
            },
        ],
        ordem: 15,
    },
    {
        id: 20,
        descricao: 'BB Seguros',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 20,
    },
    {
        id: 30,
        descricao: 'Bradesco',
        SvgLogo: SvgImageParceirosBradescoLogo,
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        integracao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoApoliceVidaInteira,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Agradecemos por escolher o Seguro SEGURO BRADESCO PARA VIDA INTEIRA',
                        'BRADESCO VIDA E PREVIDENCIA S.A.',
                        'CNPJ 51.990.695/0001-37',
                        'da vigência da Apólice, salvo as hipóteses de cancelamento previstas nas Condições Gerais deste',
                        'ESTA PARTE NÃO INTEGRA A APÓLICE E/OU CERTIFICADO DE SEGURO',
                    ],
                rotinaIntegracao: importaApoliceBradescoVidaInteira,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaBradesco,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoApoliceMultiPlanoVivaMais,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Agradecemos por escolher o Seguro  MULTIPLANO GERACAO 3 BRADESCO !  A partir',
                        'BRADESCO VIDA E PREVIDENCIA S.A.',
                        'CNPJ 51.990.695/0001-37',
                        'Apólice, salvo as hipóteses de cancelamento previstas nas Condições Gerais deste Seguro.',
                        'ESTA PARTE NÃO INTEGRA A APÓLICE E/OU CERTIFICADO DE SEGURO',
                    ],
                rotinaIntegracao: importaApoliceBradescoMultiPlanoVivaMais,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaBradesco,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoApoliceMultiPlanoVivaMais,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Agradecemos por escolher o Seguro VIVA MAIS BRADESCO - SEGURO RESGATAVEL !',
                        'BRADESCO VIDA E PREVIDENCIA S.A.',
                        'CNPJ 51.990.695/0001-37',
                        'Apólice, salvo as hipóteses de cancelamento previstas nas Condições Gerais deste Seguro.',
                        'ESTA PARTE NÃO INTEGRA A APÓLICE E/OU CERTIFICADO DE SEGURO',
                    ],
                rotinaIntegracao: importaApoliceBradescoMultiPlanoVivaMais,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaBradesco,
            },
        ],
        integracaoCotacao: [
            {
                XLSXPDF: HARDCODE.PDF,
                chaveIntegracao:
                    [
                        'Seguro Bradesco para',
                        'Vida Inteira',
                        'Seu Estudo Personalizado',
                        'Data da cotação:',
                        'Para obter maiores informações sobre como os seus dados pessoais são tratados pela Bradesco Vida e Previdência S.A., consulte a www.bradescoseguranca.com.br',
                    ],
                rotinaIntegracao: importaCotacaoBradescoTabelaEvolucaoValorResgate,
            },
        ],
        ordem: 30,
    },
    {
        id: 35,
        descricao: 'BTG',
        administradoraConsorcio: 'N',
        corretoraValores: 'S',
        seguradora: 'N',
        ordem: 35,
    },
    {
        id: 40,
        descricao: 'Capemisa',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 40,
    },
    {
        id: 50,
        descricao: 'Caixa',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 50,
    },
    {
        id: 60,
        descricao: 'Centauro ON',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 60,
    },
    //{
    //    id: 65,
    //    descricao: 'Consórcio Magalu',
    //    administradoraConsorcio: 'S',
    //    corretoraValores: 'N',
    //    seguradora: 'N',
    //    ordem: 65,
    //},
    {
        id: 70,
        descricao: 'Chubb',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 70,
    },
    {
        id: 73, // HARDCODE.idInstituicaoFinanceiraCNP
        descricao: 'CNP',
        administradoraConsorcio: 'S',
        SvgConsorcioLogo: SvgImageParceirosCNPConsorcioLogo,
        svgConsorcioLogoMl: '22',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 73,
    },
    {
        id: 74, // HARDCODE.idInstituicaoFinanceiraEmbracon
        descricao: 'Embracon',
        administradoraConsorcio: 'S',
        SvgConsorcioLogo: SvgImageParceirosEmbraconConsorcioLogo,
        svgConsorcioLogoMl: '22',
        primeirasParcelasList: [
            {id: 1, descricao: '1 parcela'},
            {id: 2, descricao: '2 parcelas'},
            {id: 12, descricao: '12 parcelas'},
        ],
        corretoraValores: 'N',
        seguradora: 'N',
        ordem: 74,
    },
    {
        id: 75,
        descricao: 'Excelsior',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 75,
    },
    {
        id: 80,
        descricao: 'Generalli',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 80,
    },
    //{
    //    id: 85,
    //    descricao: 'HS Consórcios',
    //    administradoraConsorcio: 'S',
    //    corretoraValores: 'N',
    //    seguradora: 'N',
    //    ordem: 85,
    //},
    {
        id: 90, // view fintrackssch.vw_cliente_acesso_proposta 
        descricao: 'Icatu',
        SvgLogo: SvgImageParceirosIcatuLogo,
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        integracao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoPropostaHorizonte,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoProposta,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Proposta de Contratação Seguro de Vida 3 vias - 1ª seguradora / 2ª corretor / 3ª proponente',
                        'Uma vez aprovada esta proposta pela Icatu Seguros S.A., declaro estar ciente e de acordo que:', 
                        'Estou ciente que a proposta nova de seguro, quando isoladamente ou cumulada com outros seguros ativos na Icatu, tiver capital segurado para a cobertura',
                    ],
                rotinaIntegracao: importaPropostaIcatuHorizonte,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaIcatu,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoPropostaEssencial,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoProposta,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'pago será descontado da indenização. A Icatu Seguros S.A está autorizada a buscar novos subsídios para a análise no momento da aceitação ou', 
                        'ocorrência de sinistro. As informações contidas nesta proposta são completas e verdadeiras. Estou ciente de que qualquer omissão por mim contida',
                        'Estou ciente que a proposta nova de seguro, quando isoladamente ou cumulada com outros seguros ativos na Icatu, tiver capital segurado',
                        'concorda em receber este seguro não contributário. Declara, ainda, ter',
                    ],
                rotinaIntegracao: importaPropostaIcatuEssencial,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaIcatu,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaPropostas,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: null,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoProposta,
                cabecalho: 0,
                chaveIntegracao:
                    [
                        "Cliente",
                        "Proposta",
                        "Premio Mensal",
                        "Premio Anual",
                        "Corretora Lider",
                        "Co Corretagem",
                        "Data da Transmissão",
                        "Data da Assinatura",
                        "Status Atual",
                        "Prazo Subscrição",
                        "Vencimento da 1ª Parcela",
                        "Modulo",
                    ],
                rotinaIntegracao: importaListaPropostasIcatu,
                statusProposta: [
                    {id: 'ACEITA, AGUARDANDO PAGAMENTO', idConversao: HARDCODE.idStatusPropostaImplantada, atualizaDataUltimaAlteracao: false},
                    {id: 'ACEITA AGUARDANDO PAGAMENTO', idConversao: HARDCODE.idStatusPropostaImplantada, atualizaDataUltimaAlteracao: false},
                    {id: 'ACEITA, AGUARDANDO RETORNO DO CARTÃO DE CREDITO', idConversao: HARDCODE.idStatusPropostaImplantada, atualizaDataUltimaAlteracao: false},
                    {id: 'ACEITA, AGAURDANDO PAGAMENTO', idConversao: HARDCODE.idStatusPropostaImplantada, atualizaDataUltimaAlteracao: false},
                    {id: 'ACEITO, AGUARDANDO PAGAMENTO', idConversao: HARDCODE.idStatusPropostaImplantada, atualizaDataUltimaAlteracao: false},
                    {id: 'ACEITO, ENVIADO CARTA DE NOVAS CONDIÇÕES', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'ANALISE', idConversao: HARDCODE.idStatusPropostaEmAnalise, atualizaDataUltimaAlteracao: false},
                    {id: 'CANCELADA PELO CORRETOR', idConversao: HARDCODE.idStatusPropostaCancelada, atualizaDataUltimaAlteracao: false},
                    {id: 'CARTA ASSINADA E ENVIADA AO SETOR', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'CARTA DE NOVAS CONDIÇÕES ENVIADA', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'CLIENTE PPE', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: false},
                    {id: 'DESISTÊNCIA DO CLIENTE', idConversao: HARDCODE.idStatusPropostaCancelada, atualizaDataUltimaAlteracao: true},
                    {id: 'DOC COMPLEMENTAR ENVIADO', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'DOC. COMPLEMENTAR ENVIADO', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'ENVIADO AO SETOR', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'ENVIADO DOC COMPLEMENTAR', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'ENVIADO DOC. COMPLEMENTAR', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'IMPLANTADA', idConversao: HARDCODE.idStatusPropostaImplantada, atualizaDataUltimaAlteracao: true},
                    {id: 'IMPLATADA', idConversao: HARDCODE.idStatusPropostaImplantada, atualizaDataUltimaAlteracao: true},
                    {id: 'PENDENCIA', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'RECUSADA - PENDENCIA', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'RECUSADA A PEDIDO', idConversao: HARDCODE.idStatusPropostaCancelada, atualizaDataUltimaAlteracao: false},
                    {id: 'RECUSADA APOS 60 DIAS', idConversao: HARDCODE.idStatusPropostaCancelada, atualizaDataUltimaAlteracao: false},
                    {id: 'RECUSADA', idConversao: HARDCODE.idStatusPropostaRecusada, atualizaDataUltimaAlteracao: false},
                    {id: 'RECUSADO TEMPO DE PENDENCIA - PENDENCIA', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'RECUSADO', idConversao: HARDCODE.idStatusPropostaRecusada, atualizaDataUltimaAlteracao: false},
                    {id: 'RECUSA A PEDIDO', idConversao: HARDCODE.idStatusPropostaCancelada, atualizaDataUltimaAlteracao: false},
                    {id: 'RECUSA TEMPO DE PENDENCIA - ACEITO, ENVIADO CARTA DE NOVAS CONDIÇÕES', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'RECUSA TEMPO DE PENDENCIA - PENDENCIA', idConversao: HARDCODE.idStatusPropostaPendente, atualizaDataUltimaAlteracao: true},
                    {id: 'RECUSA', idConversao: HARDCODE.idStatusPropostaRecusada, atualizaDataUltimaAlteracao: false},
                    {id: 'REVERSÃO DA RECUSA - ACEITA, AGUARDANDO PAGAMENTO - CLIENTE VAI CADASTRAR O CARTÃO DE CREDITO ENVIADO LINK', idConversao: HARDCODE.idStatusPropostaImplantada, atualizaDataUltimaAlteracao: true},
                ],
                tipoPeriodicidadePagtoPremio: [
                    {id: 30, idConversao: HARDCODE.idTipoPeriodicidadeMensal},
                    {id: 180, idConversao: HARDCODE.idTipoPeriodicidadeSemestral},
                    {id: 365, idConversao: HARDCODE.idTipoPeriodicidadeAnual},
                ],
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoApoliceHorizonte,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'CERTIFICADO INDIVIDUAL',
                        'A ICATU SEGUROS S/A,',
                        'CNPJ 42.283.770/0001-39 certifica que',
                        'é participante do HORIZONTE',
                        'DADOS DA APÓLICE',
                        'COBERTURAS E CAPITAIS SEGURADOS',
                        'Os valores de capital e prêmio serão atualizados anualmente pelo índice constante neste',
                    ],
                rotinaIntegracao: importaApoliceIcatuHorizonte,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaIcatu,
                statusApolice: statusApoliceIcatu,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoApoliceEssencial,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'A ICATU SEGUROS S/A,',
                        'CNPJ 42.283.770/0001-39 certifica que',
                        'é participante do ESSENCIAL',
                        'CERTIFICADO INDIVIDUAL',
                        'DADOS DA APÓLICE',
                        'COBERTURAS E CAPITAIS SEGURADOS',
                        'Os valores de capital e prêmio serão atualizados anualmente pelo índice constante neste',
                    ],
                rotinaIntegracao: importaApoliceIcatuEssencial,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaIcatu,
                statusApolice: statusApoliceIcatu,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaApolicesImplantacao,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                cabecalho: 0,
                chaveIntegracao:
                [
                    "Número do Estipulante",
                    "Nome do Estipulante",
                    "apolice_principal",
                    "numero_subestipulante",
                    "nome_subestipulante",
                    "numero_certificado",
                    "nome_segurado",
                    "data_nascimento",
                    "CPF",
                    "descricao_modulo",
                    "nome_conjuge",
                    "data_nascimento_conjuge",
                    "cpf_conjuge",
                    "inicio_vigencia",
                    "fim_vigencia",
                    "data_cancelamento",
                    "status_segurado",
                    "Motivo_Cancelamento",
                    "nm_forma_pagamento",
                    "banco",
                    "Agencia",
                    "conta_corrente",
                    "matricula_ih",
                    "matricula_funcional",
                    "numero_proposta",
                    "mqc_titular",
                    "mqc_conjuge",
                    "mqc_filhos",
                    "ma_titular",
                    "ma_conjuge",
                    "ma_filhos",
                    "ipa_titular",
                    "ipa_conjuge",
                    "iea_titular",
                    "iea_conjuge",
                    "ipd_titular",
                    "ipd_conjuge",
                    "ifpd_titular",
                    "ipta_titular",
                    "ipta_conjuge",
                    "rit_titular",
                    "dcmg_titular",
                    "dg_titular",
                    "adet_titular",
                    "auxdepesa_titular",
                    "tpo_titular",
                    "premio_titular",
                    "premio_conjuge",
                    "premio_filho",
                    "premio_saf",
                    "premio_total",
                    "dia_debito",
                    "data_ultimo_pagto",
                    "numero_sorte",
                    "serie_titulo_sorte",
                    "valor_titulo_sorte",
                    "bairro",
                    "cidade",
                    "uf",
                    "cep",
                    "Endereco",
                    "telefone",
                    "email",
                    "plano_senior",
                    "pagamento_carne",
                    "primeira_ato",
                    "periodicidade_pagto",
                    "qtd_parcelas_pagas",
                    "agencia_venda",
                    "unidade_atendimento",
                    "Sexo",
                    "dt_ultima_competencia_paga",
                    "capital_funcionarios",
                    "nr_funcionarios",
                    "capital_socios",
                    "nr_socios",
                    "dt_aceitacao_proposta",
                    "nm_corretor_lider",
                    "nm_corretor_segundo",
                    "nm_corretor_terceiro",
                    "cobertura_dg11",
                    "ipefpc_capital",
                    "ipefpc_premio",
                    "dt_ultima_competencia_gerada",
                    "nr_meses_vigencia_contr_cert",
                    "dt_inicio_contrato",
                    "dt_fim_contrato",
                    "ipam_titular",
                    "dmhap_titular",
                    "mqc_pai",
                    "mqc_mae",
                    "mqc_pais",
                ],
                rotinaIntegracao: importaListaApolicesIcatuImplantacao,
                //tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaIcatu,
                statusApolice: statusApoliceIcatu,
                formaPagtoPremio: formaPagtoPremioIcatu,
                //statusCobertura: statusCobertura???,
            },
        ],
        integracaoCotacao: [
            {
                XLSXPDF: HARDCODE.PDF,
                chaveIntegracao:
                    [
                        'Seguro de Vida Horizonte ideal para você.',
                        'ENTENDA COMO FUNCIONA O SALDAMENTO',
                        'EVOLUÇÃO DO VALOR DE RESGATE – SIMULAÇÃO',
                        'Veja a evolução do valor de resgate para a(s) cobertura(s) escolhida(s):',
                    ],
                rotinaIntegracao: importaCotacaoIcatuTabelaEvolucaoValorResgate,
            },
        ],
        ordem: 90,
    },
    {
        id: 100, // HARDCODE.idInstituicaoFinanceiraItau
        descricao: 'Itaú',
        administradoraConsorcio: 'S',
        SvgConsorcioLogo: SvgImageParceirosItauConsorcioLogo,
        svgConsorcioLogoMl: '8',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 100,
    },
    {
        id: 110,
        descricao: 'Liberty',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 110,
    },
    {
        id: 120,
        descricao: 'MAG (Mongeral)',
        SvgLogo: SvgImageParceirosMAGLogo,
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        integracao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoProposta,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoProposta,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'NÚMERO DA PROPOSTA:',
                        'A aceitação do seguro estará sujeita à análise do risco e a MAG Seguros tem o prazo', 
                        'Declaro, como Corretor nesta contratação, que, na forma da legislação vigente, dei cumprimento integral às disposições contidas na',
                        'Resolução CNSP nº 382/2020, inclusive quanto à prévia disponibilização ao proponente das informações previstas no art. 4º, § 1º, da',
                    ],
                rotinaIntegracao: importaPropostaMAG,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaMAG,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaPropostas,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: null,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoProposta,
                cabecalho: 0,
                chaveIntegracao:
                    [
                        "PROPOSTA",
                        "STATUS",
                        "NOME PROPONENTE",
                        "ACEITE AUTOMÁTICO",
                        "UP",
                        "ENTRADA",
                        "ACEITE",
                        "IMPLANTAÇÃO",
                        "ULTIMA ALTERAÇÃO",
                        "PRIMEIRO PAGAMENTO",
                        "PRIMEIRA APROPRIAÇÃO",
                        "DATA PRIMEIRA ANGARIAÇÃO",
                        "EMISSÃO",
                        "CICLO PROPOSTA (DIAS)",
                        "PRÊMIO TOTAL",
                        "PRÊMIO RISCO",
                        "PRÊMIO ANUALIZADO RISCO",
                        "PRÊMIO RISCO LÍQUIDO",
                        "VALOR LIQUIDO EMITIDO",
                        "VALOR LIQUIDO ANUALIZADO EMITIDO",
                        "PERIODICIDADE",
                        "FORMA PAGAMENTO",
                        "FORMA PRIMEIRA COBRANÇA",
                        "OFERTA COMERCIAL",
                        "AM",
                        "AD",
                        "TEXTO SUSPENSÃO",
                        "TEXTO RECUSA",
                        "CPF / CNPJ",
                        "SEXO",
                        "NASCIMENTO",
                        "PROFISSÃO",
                        "RENDA",
                        "UF",
                        "CIDADE",
                        "CEP",
                        "BAIRRO",
                        "EMAIL",
                        "DDD",
                        "TELEFONE",
                        "PRODUTOR 1",
                        "NOME PRODUTOR 1",
                        "PRODUTOR 2",
                        "NOME PRODUTOR 2",
                        "CODIGO AGENTE MAG",
                        "NOME AGENTE MAG",
                        "CODIGO AGENTE FIDELIZAÇÃO",
                        "NOME AGENTE FIDELIZAÇÃO",
                        "CODIGO GERENTE COMERCIAL",
                        "NOME GERENTE COMERCIAL",
                        "CODIGO GERENTE SUCURSAL",
                        "NOME GERENTE SUCURSAL",
                        "OPERAÇÃO",
                        "CANAL",
                        "EMPRESA ACORDO COMERCIAL",
                    ],
                rotinaIntegracao: importaListaPropostasMAG,
                statusProposta: statusPropostaMAG,
                tipoPeriodicidadePagtoPremio: tipoPeriodicidadePagtoPremioMAG,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaPropostasNovoLayout,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: null,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoProposta,
                cabecalho: 0,
                chaveIntegracao:
                /*[
                    "PROPOSTA",
                    "STATUS",
                    "PROPONENTE",
                    "OFERTA",
                    "GRUPO",
                    "NEGÓCIO",
                    "ACEITE AUTOMÁTICO",
                    "ENTRADA",
                    "ACEITE",
                    "ÚLTIMA ALTERAÇÃO",
                    "IMPLANTAÇÃO",
                    "EMISSÃO",
                    "PRÊMIO TOTAL",
                    "PRÊMIO RISCO",
                    "PRÊMIO ANUALIZADO RISCO",
                    "PRÊMIO MENSALIZADO RISCO",
                    "PRÊMIO SGV",
                    "PERIODICIDADE",
                    "TEM PRIMEIRO PAGAMENTO ?",
                    "PRÊMIO PRIMEIRO PAGAMENTO",
                    "FORMA PAGAMENTO",
                    "PRIMEIRA COBRANÇA",
                    "PAGAMENTO",
                    "APROPRIAÇÃO",
                    "CANCELAMENTO",
                    "MOTIVO",
                    "AM",
                    "MIGRAÇÃO",
                    "AD",
                    "DATA SUSPENSÃO",
                    "TEXTO SUSPENSÃO",
                    "DATA RECUSA",
                    "TEXTO RECUSA",
                    "UNIDADE DE PRODUÇÃO",
                    "REGIONAL",
                    "DIRETORIA",
                    "CPF",
                    "TELEFONE",
                    "E-MAIL",
                    "SEXO",
                    "NASCIMENTO",
                    "ESTADO CIVIL",
                    "RENDA",
                    "PROFISSÃO",
                    "CEP",
                    "CIDADE",
                    "UF",
                    "EMPRESA ACORDO COMERCIAL",
                    "CNPJ EMPRESA",
                    "OPERACAO",
                    "PRODUTOR 1",
                    "PRODUTOR 2",
                    "PRODUTOR 3",
                    "PRODUTOR 4",
                    "PRODUTOR 5",
                    "PRODUTOR 6",
                    "PRODUTOR 7",
                    "PRODUTOR 8",
                    "PRODUTOR 9"
                ]*/
                [
                    "PROPOSTA",
                    "STATUS",
                    "PROPONENTE",
                    "OFERTA",
                    "GRUPO",
                    "NEGÓCIO",
                    "ACEITE AUTOMÁTICO",
                    "ENTRADA",
                    "ACEITE",
                    "ÚLTIMA ALTERAÇÃO",
                    "IMPLANTAÇÃO",
                    "SGV", // Novo
                    "EMISSÃO",
                    "PRÊMIO TOTAL",
                    "PRÊMIO RISCO",
                    "PRÊMIO ANUALIZADO RISCO",
                    "PRÊMIO MENSALIZADO RISCO",
                    "PRÊMIO SGV",
                    "PERIODICIDADE",
                    "TEM PRIMEIRO PAGAMENTO ?",
                    "PRÊMIO PRIMEIRO PAGAMENTO",
                    "FORMA PAGAMENTO",
                    "FICHA FINANCEIRA",
                    "PRIMEIRA COBRANÇA",
                    "VENCIMENTO",
                    "PAGAMENTO",
                    "APROPRIAÇÃO",
                    "CANCELAMENTO",
                    "MOTIVO",
                    "AM",
                    "MIGRAÇÃO",
                    "AD",
                    "DATA SUSPENSÃO",
                    "TEXTO SUSPENSÃO",
                    "DATA RECUSA",
                    "TEXTO RECUSA",
                    "UNIDADE DE PRODUÇÃO",
                    "REGIONAL",
                    "DIRETORIA",
                    "CPF",
                    "TELEFONE",
                    "E-MAIL",
                    "SEXO",
                    "NASCIMENTO",
                    "ESTADO CIVIL",
                    "RENDA",
                    "PROFISSÃO",
                    "CEP",
                    "CIDADE",
                    "UF",
                    "EMPRESA ACORDO COMERCIAL",
                    "CNPJ EMPRESA",
                    "OPERACAO",
                    "PRODUTOR PRINCIPAL",
                    "AGENCIA / ASSESSORIA",
                    "CORRETOR ESTRUTURADO",
                    "CORRETOR MICRO PJ",
                    "CORRETOR PJ",
                    "CORRETOR PL",
                    "CORRETOR NIVEL 4",
                    "CORRETOR",
                    "CONSULTOR B2C",
                    "CONSULTOR / REPRESENTANTE",
                    "PROMOTOR / AGENTE COMERCIAL",
                    "TREINANDO / OPERADOR",
                    "GERENTE COMERCIAL",
                    "GERENTE EXECUTIVO",
                    "MTZ / ISLA",
                    "SUPERINTENDENTE COMERCIAL",
                    "SUPERINTENDENTE REGIONAL"
                ],
                rotinaIntegracao: importaListaPropostasMAGNovoLayout,
                statusProposta: statusPropostaMAG,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaPropostasImplantacao,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoProposta,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                cabecalho: 0,
                chaveIntegracao:
                    [
                        "PROPOSTA IMPLANTAÇÃO",
                        "STATUS",
                        "NOME PROPONENTE",
                        "ACEITE AUTOMÁTICO",
                        "UP",
                        "ENTRADA",
                        "ACEITE",
                        "IMPLANTAÇÃO",
                        "ULTIMA ALTERAÇÃO",
                        "PRIMEIRO PAGAMENTO",
                        "PRIMEIRA APROPRIAÇÃO",
                        "DATA PRIMEIRA ANGARIAÇÃO",
                        "EMISSÃO",
                        "CICLO PROPOSTA (DIAS)",
                        "PRÊMIO TOTAL",
                        "PRÊMIO RISCO",
                        "PRÊMIO ANUALIZADO RISCO",
                        "PRÊMIO RISCO LÍQUIDO",
                        "VALOR LIQUIDO EMITIDO",
                        "VALOR LIQUIDO ANUALIZADO EMITIDO",
                        "PERIODICIDADE",
                        "FORMA PAGAMENTO",
                        "FORMA PRIMEIRA COBRANÇA",
                        "OFERTA COMERCIAL",
                        "AM",
                        "AD",
                        "TEXTO SUSPENSÃO",
                        "TEXTO RECUSA",
                        "CPF / CNPJ",
                        "SEXO",
                        "NASCIMENTO",
                        "PROFISSÃO",
                        "RENDA",
                        "UF",
                        "CIDADE",
                        "CEP",
                        "BAIRRO",
                        "EMAIL",
                        "DDD",
                        "TELEFONE",
                        "PRODUTOR 1",
                        "NOME PRODUTOR 1",
                        "PRODUTOR 2",
                        "NOME PRODUTOR 2",
                        "CODIGO AGENTE MAG",
                        "NOME AGENTE MAG",
                        "CODIGO AGENTE FIDELIZAÇÃO",
                        "NOME AGENTE FIDELIZAÇÃO",
                        "CODIGO GERENTE COMERCIAL",
                        "NOME GERENTE COMERCIAL",
                        "CODIGO GERENTE SUCURSAL",
                        "NOME GERENTE SUCURSAL",
                        "OPERAÇÃO",
                        "CANAL",
                        "EMPRESA ACORDO COMERCIAL",
                    ],
                rotinaIntegracao: importaListaPropostasImplantacaoMAG,
                statusProposta: statusPropostaMAG,
                tipoPeriodicidadePagtoPremio: [
                    {id: 30, idConversao: HARDCODE.idTipoPeriodicidadeMensal},
                    {id: 180, idConversao: HARDCODE.idTipoPeriodicidadeSemestral},
                    {id: 365, idConversao: HARDCODE.idTipoPeriodicidadeAnual},
                ],
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoApolicePrivateSolutions,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Private Solutions',
                        'Mongeral Aegon Seguros e Previdência S.A.', 
                        'da Apólice',
                    ],
                rotinaIntegracao: importaApoliceMAGPrivateSolutions,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaMAG,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoApoliceVidaToda,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'CERTIFICADO / APÓLICE DE SEGURO / PLANO DE PREVIDÊNCIA',
                        'Mongeral Aegon Seguros e Previdência S.A.',
                        'DADOS DOS PLANOS DE MORTE OU INVALIDEZ',
                        'Controle da Apólice',
                    ],
                rotinaIntegracao: importaApoliceMAGVidaToda,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaMAG,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaApolicesImplantacao,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                cabecalho: 0,
                chaveIntegracao:
                [
                    "NEGÓCIO",
                    "CPF",
                    "SEGURADO",
                    "MATRICULA",
                    "PROPOSTA",
                    "DATA ENTRADA", //novo
                    "DATA IMPLANTAÇÃO",
                    "DATA EMISSÃO",
                    "UNIDADE DE PRODUÇÃO",
                    "REGIONAL",
                    "DIRETORIA",
                    "OFERTA COMERCIAL",
                    "GRUPO OFERTA",
                    "AM",
                    "AD",
                    "INSCRIÇÃO",
                    "SITUAÇÃO INSCRIÇÃO",
                    "DATA SITUAÇÃO",
                    "CANCELAMENTO AGENDADO",
                    "ITEM CONTRATADO",
                    "PRODUTO",
                    "TEMPO CONTRIBUIÇÃO (ANOS)", //novo
                    "PRAZO DECRECIMO", //novo
                    "PRAZO DIFERIMENTO", //novo
                    "CAPITAL SEGURADO",
                    "REGIME FINANCEIRO",
                    "ÍNICIO VIGÊNCIA",
                    "FIM VIGÊNCIA",
                    "STATUS ITEM",
                    "DATA STATUS",
                    "PERIODICIDADE",
                    "FORMA COBRANÇA",
                    "DIA VENCIMENTO",
                    "PRÊMIO IMPLANTADO",
                    "PRÊMIO IMPLANTADO LÍQUIDO",
                    "IOF IMPLANTADO",
                    "PRÊMIO ATUAL",
                    "IOF ATUAL",
                    "PRÊMIO ATUAL MENSALIZADO",
                    "PRÊMIO ATUAL ANUALIZADO",
                    "PRIMEIRO PAGAMENTO",
                    "FORMA COBRANÇA PRIMEIRO PAGAMENTO", //novo
                    "DATA PRIMEIRO VENCIMENTO", //novo
                    "DATA PAGAMENTO",
                    "FICHA COMISSÃO",
                    "TELEFONE CLIENTE",
                    "E-MAIL CLIENTE",
                    "ENDEREÇO CLIENTE",
                    "SEXO CLIENTE",
                    "QTD FILHOS",
                    "DATA NASCIMENTO",
                    "PROFISSÃO CLIENTE",
                    "RENDA CLIENTE",
                    "EMPRESA ACORDO COMERCIAL",
                    "CNPJ EMPRESA ACORDO COMERCIAL",
                    "OPERAÇÃO",
                    "PRODUTOR PRINCIPAL",
                    "PRODUTOR AUXILIAR",
                    "AGENCIA / ASSESSORIA",
                    "CORRETOR ESTRUTURADO",
                    "CORRETOR MICRO PJ",
                    "CORRETOR PJ",
                    "CORRETOR PL",
                    "CORRETOR NIVEL 4",
                    "CORRETOR",
                    "CONSULTOR B2C",
                    "CONSULTOR / REPRESENTANTE",
                    "PROMOTOR / AGENTE COMERCIAL",
                    "TREINANDO / OPERADOR",
                    "PRODUTOR ACORDO COMERCIAL", //novo2
                    "GERENTE COMERCIAL",
                    "GERENTE EXECUTIVO",
                    "MTZ / ISLA",
                    "SUPERINTENDENTE COMERCIAL",
                    "SUPERINTENDENTE REGIONAL",
                ],
                rotinaIntegracao: importaListaApolicesMAGImplantacao,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaMAG,
                statusApolice: [
                    {id: 'ATIVO', idConversao: HARDCODE.idStatusApoliceAtiva},
                    {id: 'CANCELADO', idConversao: HARDCODE.idStatusApoliceCancelada},
                    {id: 'CANCELADO POR BENEFICIO', idConversao: HARDCODE.idStatusApoliceSinistrada},
                ],
                statusCobertura: statusCoberturaMAG,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaComissoes,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: null,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoComissao,
                cabecalho: 0,
                chaveIntegracao:
                    [
                        'Neg',
                        'Prop / Cont',
                        'Insc / Subest',
                        'Mat / Ind',
                        'Cliente',
                        'Produtor',
                        'UP',
                        '% Part',
                        'Alt',
                        'Prod',
                        'Parc. Comissão',
                        'Competência Arrecadação',
                        'Parc. Arrecadação',
                        '$ Base', '% Com',
                        '$ COM', 'Evento',
                        'Envio', 'Agend',
                        'Pagto',
                        'DG',
                        'AD',
                        'AM',
                        'Fato',
                        'Desc Prov',
                        'Condição Comercial',
                        'Provisão',
                        'Tipo Pagto',
                        '% Co-Corretagem',
                        'Comissão Co-Corretagem',
                        'Produtor Co-Corretagem',
                        'CPF Cliente',
                        'Prazo tempo / idade',
                        'Ativo',
                        'Com total'
                    ],
                rotinaIntegracao: importaListaComissoesMAG,
            },
        ],
        integracaoCotacao: [
            {
                XLSXPDF: HARDCODE.PDF,
                chaveIntegracao:
                    [
                        'Preparamos um estudo personalizado',
                        'Private Solutions.',
                        'Essa linha também possui opções de planos de previdência PGBL e VGBL',
                        'não constituem nenhuma garantia de rentabilidade por parte da MAG Seguros para seus participantes.',
                    ],
                rotinaIntegracao: importaCotacaoMAGTabelaEvolucaoValorResgate,
            },
        ],
        ordem: 120,
    },
    {
        id: 130,
        descricao: 'MBM',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 130,
    },
    {
        id: 140, // HARDCODE.idInstituicaoFinanceiraMAPFRE
        descricao: 'MAPFRE',
        administradoraConsorcio: 'S',
        SvgConsorcioLogo: SvgImageParceirosMAPFREConsorcioLogo,
        svgConsorcioLogoMl: '27',
        tipoLanceList: [
            {id: 'TF', descricao: 'Lance Troca Fácil'},
            {id: 'CF', descricao: 'Lance Contrufácil'},
            {id: 'QF', descricao: 'Lance Quitafácil'},
        ],
        corretoraValores: 'N',
        seguradora: 'S',
        integracao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaApolicesPlataforma,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                cabecalho: 1,
                chaveIntegracao:
                    [
                        "Código Arvore",
                        "Código Agente",
                        "Número Apolice",
                        "Cotação",
                        "Cliente",
                        "CPF",
                        "Data da Cotação",
                        "Data Inicio Vigencia",
                        "Status Apolice",
                        "Vlr IS Morte",
                        "Premio Morte",
                        "Prazo Cobertura Morte",
                        "VLR Sobrev",
                        "Premio Sobrev",
                        "Prazo Cobertura Sobrevivência",
                        "Vlr IS IPA",
                        "Premio IPA",
                        "Prazo Cobertura Invalidez",
                        "Vlr DG",
                        "Premio DG",
                        "Prazo DG",
                        "Sexo",
                        "Classificação Risco",
                        "Data de Nascimento",
                        "Idade atual",
                        "Idade de saida",
                        "Capital morte",
                        "Capital Sobrevivência",
                        "Periodicidade de Pagamento",
                        "Tipo de Plano",
                        "Percentual Rentabilidade Estimada",
                        "Prêmio Total",
                        "Estado Civil",
                        "Profissão",
                        "Telefone Residencial",
                        "Telefone Comercial",
                        "Telefone Celular",
                        "Tipo de Documento",
                        "Orgão Expedidor",
                        "UF Expedidor",
                        "No. Documento",
                        "Data de Expedição",
                        "Nacionalidade",
                        "Faixa de Renda",
                        "Logradouro Residencial",
                        "Número Residencial",
                        "Complemento Residencial",
                        "Bairro Residencial",
                        "Municipio Residencial",
                        "UF Residencial",
                        "CEP Residencial",
                        "Pais Residencial",
                        "Reside no pais",
                        "Receber informação eletrônica",
                        "Email",
                        "Logradouro Correspondência",
                        "Número Correspondência",
                        "Complemento Correspondência",
                        "Bairro Correspondência",
                        "Município Correspondência",
                        "UF Correspondência",
                        "CEP Correspondência",
                        "País  Correspondência",
                        "Nome Beneficiário",
                        "Data Nascimento Beneficiário",
                        "Grau de Parentesco Beneficiário",
                        "CPF Beneficiário",
                        "Percentual participação benfeciario",
                        "Tipo de Cobertura"
                    ],
                rotinaIntegracao: importaListaApolicesMAPFRE,
                statusApolice: [
                    {id: 'ATIVA', idConversao: HARDCODE.idStatusApoliceAtiva},
                    {id: 'CANCELADA', idConversao: HARDCODE.idStatusApoliceCancelada},
                ],
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaApolicesCorretorSeguros,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                cabecalho: 0,
                chaveIntegracao:
                    [
                        "Código Arvore",
                        "Código Agente",
                        "Número Apolice",
                        "Cotação",
                        "Cliente",
                        "CPF",
                        "Data da Cotação",
                        "Data Inicio Vigencia",
                        "Status Apolice",
                        "Vlr IS Morte",
                        "Premio Morte",
                        "Prazo Cobertura Morte",
                        "VLR Sobrev",
                        "Premio Sobrev",
                        "Prazo Cobertura Sobrevivência",
                        "Vlr IS IPA",
                        "Premio IPA",
                        "Prazo Cobertura Invalidez",
                        "Vlr DG",
                        "Premio DG",
                        "Prazo DG",
                        "Sexo",
                        "Classificação Risco",
                        "Data de Nascimento",
                        "Idade atual",
                        "Idade de saida",
                        "Capital morte",
                        "Capital Sobrevivência",
                        "Periodicidade de Pagamento",
                        "Tipo de Plano",
                        "Percentual Rentabilidade Estimada",
                        "Prêmio Total",
                        "Estado Civil",
                        "Profissão",
                        "Telefone Residencial",
                        "Telefone Comercial",
                        "Telefone Celular",
                        "Tipo de Documento",
                        "Orgão Expedidor",
                        "UF Expedidor",
                        "No. Documento",
                        "Data de Expedição",
                        "Nacionalidade",
                        "Faixa de Renda",
                        "Logradouro Residencial",
                        "Número Residencial",
                        "Complemento Residencial",
                        "Bairro Residencial",
                        "Municipio Residencial",
                        "UF Residencial",
                        "CEP Residencial",
                        "Pais Residencial",
                        "Reside no pais",
                        "Receber informação eletrônica",
                        "Email",
                        "Logradouro Correspondência",
                        "Número Correspondência",
                        "Complemento Correspondência",
                        "Bairro Correspondência",
                        "Município Correspondência",
                        "UF Correspondência",
                        "CEP Correspondência",
                        "País  Correspondência",
                        "Nome Beneficiário",
                        "Data Nascimento Beneficiário",
                        "Grau de Parentesco Beneficiário",
                        "CPF Beneficiário",
                        "Percentual participação benfeciario",
                        "Tipo de Cobertura"
                    ],
                rotinaIntegracao: importaListaApolicesMAPFRE,
                statusApolice: [
                    {id: 'ATIVA', idConversao: HARDCODE.idStatusApoliceAtiva},
                    {id: 'CANCELADA', idConversao: HARDCODE.idStatusApoliceCancelada},
                ],
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaCobrancasPlataforma,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: null,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCobranca,
                cabecalho: 1,
                chaveIntegracao:
                    [
                        "Código Arvore",
                        "Código Agente",
                        "Número Apolice",
                        "Cliente",
                        "CPF",
                        "Data Inicio Vigencia",
                        "Status Apolice",
                        "Forma de Pagamento",
                        "Banco",
                        "Agência",
                        "Conta",
                        "Segmento",
                        "Dia de Vencimento",
                        "Número da Parcela",
                        "Estado Parcela",
                        "Retorno de OMF",
                        "Tipo",
                        "Data Vencimento",
                        "Data de Competência",
                        "Início de vigência",
                        "Fim da Vigência",
                        "Data de Pagamento",
                        "Data de Corte",
                        "Valor a Pagar",
                        "Valor Pago",
                        "Periodicidade de Pagamento",
                        "Repiques",
                        "OMF",
                    ],
                rotinaIntegracao: importaListaCobrancasMAPFRE,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaCobrancasCorretorSeguros,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: null,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCobranca,
                cabecalho: 0,
                chaveIntegracao:
                    [
                        "Código Arvore",
                        "Código Agente",
                        "Número Apolice",
                        "Cliente",
                        "CPF",
                        "Data Inicio Vigencia",
                        "Status Apolice",
                        "Forma de Pagamento",
                        "Banco",
                        "Agência",
                        "Conta",
                        "Segmento",
                        "Dia de Vencimento",
                        "Número da Parcela",
                        "Estado Parcela",
                        "Retorno de OMF",
                        "Tipo",
                        "Data Vencimento",
                        "Data de Competência",
                        "Início de vigência",
                        "Fim da Vigência",
                        "Data de Pagamento",
                        "Data de Corte",
                        "Valor a Pagar",
                        "Valor Pago",
                        "Periodicidade de Pagamento",
                        "Repiques",
                        "OMF",
                    ],
                rotinaIntegracao: importaListaCobrancasMAPFRE,
            },
        ],
        ordem: 140,
    },
    {
        id: 150,
        descricao: 'MetLife',
        SvgLogo: SvgImageParceirosMetLifeLogo,
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        integracao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoProposta,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoProposta,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Proposta de Contratação de seguro de vida', 
                        'a favor da Metropolitan Life Seguros e Previdência Privada S.A.',
                        'INFORMAÇÕES MÉDICAS e DECLARAÇÃO PESSOAL DE SAÚDE, bem como que a presente contratação está sendo efetuada',
                    ],
                rotinaIntegracao: importaPropostaMetLife,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaMetLife,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoPropostaNovoLayout,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoProposta,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Aceita receber as comunicações e informações sobre o seu seguro de forma eletrônica?',
                        'Proposta de Contratação de seguro de vida', 
                        'Metropolitan Life Seguros e Previdência Privada S.A.',
                        'item INFORMAÇÕES MÉDICAS e DECLARAÇÃO PESSOAL DE SAÚDE, bem como que a presente contratação',
                    ],
                rotinaIntegracao: importaPropostaMetLifeNovoLayout,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaMetLife,
            },
            
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaPropostasImplantacao,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoProposta,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                cabecalho: 1,
                chaveIntegracao:
                    [
                        'ID Documento Implantação',
                        'Origem',
                        'Número Proposta',
                        'Status',
                        'Data Protocolo',
                        'Nome Proponente',
                        'CPF',
                        'Data alteração status',
                        'Consultor',
                        'LU',
                        'LA',
                        'Diretor',
                        'Prêmio',
                        'Data Agendamento 1',
                        'Data Agendamento 2',
                        'Data Agendamento 3',
                        'Periodicidade do Pagamento',
                        'Informações Adicionais',
                        'Morte',
                        'Morte com capital decrescente',
                        'Morte Acidental',
                        'Invalidez Acidental',
                        'Doenças graves e proc. Cirúrgico',
                        'Doenças graves e proc. Cirúrgico Premium',
                        'Diária Internação Hospitalar',
                        'Funeral Individual',
                        'Funeral Conjugue',
                        'Proteção Incapacidade Temporaria',
                        'Telefone Proponente',
                        'Email Proponente',
                        'Estado Civil',
                        'Cidade',
                        'Data Nascimento',
                        'Forma Pagamento',
                        'Idade',
                        'Profissão',
                        'Renda',
                        'Plano',
                        'Histórico'
                    ],
                rotinaIntegracao: importaListaPropostasImplantacaoMetLife,
                statusProposta: statusPropostaMetLife,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaPropostas,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: null,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoProposta,
                cabecalho: 1,
                chaveIntegracao:
                    [
                        'ID Documento',
                        'Origem',
                        'Número Proposta',
                        'Status',
                        'Data Protocolo',
                        'Nome Proponente',
                        'CPF',
                        'Data alteração status',
                        'Consultor',
                        'LU',
                        'LA',
                        'Diretor',
                        'Prêmio',
                        'Data Agendamento 1',
                        'Data Agendamento 2',
                        'Data Agendamento 3',
                        'Periodicidade do Pagamento',
                        'Informações Adicionais',
                        'Morte',
                        'Morte com capital decrescente',
                        'Morte Acidental',
                        'Invalidez Acidental',
                        'Doenças graves e proc. Cirúrgico',
                        'Doenças graves e proc. Cirúrgico Premium',
                        'Diária Internação Hospitalar',
                        'Funeral Individual',
                        'Funeral Conjugue',
                        'Proteção Incapacidade Temporaria',
                        'Telefone Proponente',
                        'Email Proponente',
                        'Estado Civil',
                        'Cidade',
                        'Data Nascimento',
                        'Forma Pagamento',
                        'Idade',
                        'Profissão',
                        'Renda',
                        'Plano',
                        'Histórico'
                    ],
                rotinaIntegracao: importaListaPropostasMetLife,
                statusProposta: statusPropostaMetLife,
            },
            //{
            //    tipoArquivo: HARDCODE.idTipoArquivoApoliceNovoLayout,
            //    XLSXPDF: HARDCODE.PDF,
            //    tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
            //    tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
            //    chaveIntegracao:
            //        [
            //            'Apólice individual do seguro', 
            //            'Metropolitan Life Seguros e Previdência Privada S',
            //            //'O valor indicado representa o capital máximo da garantia, sendo que o cálculo da indenização observará',
            //            'As Coberturas contratadas terão seu início e término de vigência às 24 horas das datas acima indicadas. Os Capitais de',
            //        ],
            //    rotinaIntegracao: importaApoliceMetLifeNovoLayout,
            //},
            {
                tipoArquivo: HARDCODE.idTipoArquivoApolice,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Apólice individual do seguro', 
                        'Metropolitan Life Seguros e Previdência Privada S',
                        //'ou recomendação a sua comercialização. As condições contratuais deste produto encontram-se registradas na Susep',
                        'controle dos mercados de seguro, previdência complementar aberta, capitalização, resseguro e corretagem de ',
                    ],
                rotinaIntegracao: importaApoliceMetLife,
                cobertura1: [
                    {
                        id: 1,
                        chave: 'Morte 91-Vida Individual',
                        descricaoCobertura: 'MORTE (91-VIDA INDIVIDUAL)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira,
                    },
                    {
                        id: 2,
                        chave: 'Morte Acidental 81-Acidentes Pessoais',
                        descricaoCobertura: 'MORTE ACIDENTAL (81-ACIDENTES PESSOAIS)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental,
                    },
                    {
                        id: 3,
                        chave: 'Invalidez Total ou Parcial por Acidente 81-Acidentes Pessoais',
                        descricaoCobertura: 'INVALIDEZ TOTAL OU PARCIAL POR ACIDENTE (81-ACIDENTES PESSOAIS)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial,
                    },
                    {
                        id: 4,
                        chave: 'Cirúrgicos 84-Doença Grave ou Terminal',
                        descricaoCobertura: 'DOENÇAS GRAVES E PROCEDIMENTOS CIRÚRGICOS (84-DOENÇA GRAVE OU TERMINAL)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves,
                    },
                    {
                        id: 5,
                        chave: '84-Doença Grave ou Terminal',
                        descricaoCobertura: 'DOENÇAS GRAVES E PROCEDIMENTOS CIRÚRGICOS PREMIUM (84-DOENÇA GRAVE OU TERMINAL)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves,
                    },
                    {
                        id: 6,
                        chave: 'Doenças Incapacitantes Ampliada 90-Eventos Aleatórios',
                        descricaoCobertura: 'DOENÇAS INCAPACITANTES AMPLIADA (90-EVENTOS ALEATÓRIOS)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves,
                    },
                    {
                        id: 7,
                        chave: 'Diária de Internação Hospitalar 90-Eventos Aleatórios',
                        descricaoCobertura: 'DIÁRIA DE INTERNAÇÃO HOSPITALAR (90-EVENTOS ALEATÓRIOS)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH,
                    },
                    {
                        id: 8,
                        chave: 'Funeral Individual 29-Funeral',
                        descricaoCobertura: 'FUNERAL INDIVIDUAL (29-FUNERAL)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral,
                    },
                    {
                        id: 9,
                        chave: 'Funeral Cônjuge 29-Funeral',
                        descricaoCobertura: 'FUNERAL CONJUGE (29-FUNERAL)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral,
                    },
                    {
                        id: 10,
                        chave: 'Decrescente A Cada Ano 91-Vida Individual', // '91-Vida Individual',
                        descricaoCobertura: 'MORTE COM CAPITAL SEGURADO DECRESCENTE A CADA ANO (91-VIDA INDIVIDUAL)', // null,
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporarioDecrescente,
                    },
                ],
                premio1: [
                    {
                        id: 2,
                        chave: 'Morte Acidental',
                        linha: 0,
                        linha2: 0,
                    },
                    {
                        id: 3,
                        chave: 'Invalidez Total ou Parcial por Acidente',
                        linha: 0,
                        linha2: 0,
                    },
                    {
                        id: 5,
                        chave: 'Cirúrgicos Premium',
                        linha: 1,
                        linha2: 0,
                    },
                    {
                        id: 4,
                        chave: 'Cirúrgicos',
                        linha: 1,
                        linha2: 1,
                    },
                    {
                        id: 6,
                        chave: 'Doenças Incapacitantes Ampliada',
                        linha: 0,
                        linha2: 0,
                    },
                    {
                        id: 7,
                        chave: 'Diária de Internação Hospitalar',
                        linha: 0,
                        linha2: 0,
                    },
                    {
                        id: 8,
                        chave: 'Funeral Individual',
                        linha: 0,
                        linha2: 0,
                    },
                    {
                        id: 9,
                        chave: 'Funeral Cônjuge',
                        linha: 0,
                        linha2: 0,
                    },
                    {
                        id: 10,
                        chave: 'Decrescente A Cada Ano', // 'Morte com Capital Segurado',
                        linha: 0,//2,
                        linha2: 0,//2,
                    },
                    {
                        id: 1,
                        chave: 'Morte',
                        linha: 0,
                        linha2: 0,
                    },
                ],
                cobertura2: [
                    {
                        id: 1,
                        chave: 'Morte 91-Vida Individual',
                        descricaoCobertura: 'MORTE (91-VIDA INDIVIDUAL)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira,
                    },
                    {
                        id: 2,
                        chave: '81-Acidentes Pessoais',
                        descricaoCobertura: null,
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial,
                    },
                    {
                        id: 3,
                        chave: 'Terminal',
                        descricaoCobertura: 'DOENÇAS GRAVES E PROCEDIMENTOS CIRÚRGICOS PREMIUM (84-DOENÇA GRAVE OU TERMINAL)',
                        proximaLinha: true,
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves,
                    },
                    {
                        id: 4,
                        chave: 'Diária de Internação Hospitalar 90-Eventos Aleatórios',
                        descricaoCobertura: 'DIÁRIA DE INTERNAÇÃO HOSPITALAR (90-EVENTOS ALEATÓRIOS)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH,
                    },
                    {
                        id: 5,
                        chave: 'Funeral Individual 29-Funeral',
                        descricaoCobertura: 'FUNERAL INDIVIDUAL (29-FUNERAL)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral,
                    },
                    {
                        id: 6,
                        chave: '91-Vida Individual',
                        descricaoCobertura: null,
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporarioDecrescente,
                    },
                    {
                        id: 7,
                        chave: 'Morte Acidental 81-Acidentes Pessoais',
                        descricaoCobertura: 'MORTE ACIDENTAL (81-ACIDENTES PESSOAIS)',
                        tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental,
                    },
                ],
                premio2: [
                    {
                        id: 2,
                        chave: 'Invalidez Total ou Parcial por',
                        linha: 2,
                    },
                    {
                        id: 3,
                        chave: 'Cirúrgicos Premium',
                        linha: 1,
                    },
                    {
                        id: 4,
                        chave: 'Diária de Internação Hospitalar',
                        linha: 0,
                    },
                    {
                        id: 5,
                        chave: 'Funeral Individual',
                        linha: 0,
                    },
                    {
                        id: 6,
                        chave: 'Morte com Capital Segurado',
                        linha: 2,
                    },
                    {
                        id: 7,
                        chave: 'Morte Acidental',
                        linha: 0,
                    },
                    {
                        id: 1,
                        chave: 'Morte',
                        linha: 0,
                    },
                ]
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaApolicesImplantacao,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                cabecalho: 1,
                chaveIntegracao:
                [
                    "Nome Cliente",
                    "Sobrenome",
                    "Relacionamento",
                    "Nome Produto",
                    "Plano Apolice",
                    "Status Apolice",
                    "Numero Apolice",
                    "Nome Acordo",
                    "Origem",
                    "Codigo Ramo",
                    "Premio",
                    "Inicio Vigencia",
                    "Tipo",
                    "Administradora",
                    "Capital",
                    "Nome Ramo",
                    "Forma Pagamento Primo",
                    "Forma Pagamento",
                    "Moeda",
                    "Info Pagamento",
                    "Frequencia",
                    "Dia Faturamento",
                    "Data Ultima Atualizacao",
                    "Responsavel",
                    "Tipo Validade",
                    "Idade Emissao",
                    "Numero Sorte",
                    "Cartao Expiracao",
                    "Data Expiracao",
                    "Dia Fechamento",
                    "Data Encerramento",
                    "Data Renovacao",
                    "Automatico",
                    "Data Cancelamento",
                    "Quantidade Term",
                    "Modo",
                    "Numero Vidas",
                    "Numero Certificado",
                    "Cnpj Corretor",
                    "Codigo Administradora",
                    "Codigo Filial",
                    "Data Emissao",
                    "Proxima Renovacao",
                    "Cpf Cliente",
                    "Nascimento Cliente",
                    "Sexo Cliente",
                ],
                rotinaIntegracao: importaListaApolicesMetLifeImplantacao,
                statusApolice: [
                    {id: 'A', idConversao: HARDCODE.idStatusApoliceAtiva},
                    {id: 'C', idConversao: HARDCODE.idStatusApoliceCancelada},
                ],
            },
        ],
        integracaoCotacao: [
            {
                XLSXPDF: HARDCODE.PDF,
                chaveIntegracao:
                    [
                        'MetLife.', 
                        'Data que a primeira cotação foi gerada:',
                        'Informações do CorretorInformações do Segurado',
                        'Ano Idade PrêmioAcumulado Resgate',
                        'Produto registrado no Processo SUSEP: Vida Total sob nº 15414.605163/2023-93, sob responsabilidade da Metropolitan Life Seguros e',
                    ],
                rotinaIntegracao: importaCotacaoMetLifeTabelaEvolucaoValorResgate,
            },
            {
                XLSXPDF: HARDCODE.PDF,
                chaveIntegracao:
                    [
                        'MetLife.', 
                        'Informações do Segurado Informações do Corretor',
                        'Esta simulação/cotação é válida por 30 dias, contados a partir da data de elaboração.',
                        'Produto registrado no Processo SUSEP: Vida Total',
                        'sob  responsabilidade da Metropolitan Life Seguros e',
                    ],
                rotinaIntegracao: importaCotacaoMetLifeTabelaEvolucaoValorResgate,
            },
        ],
        ordem: 150,
    },
    {
        id: 160,
        descricao: 'Mitsui',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 160,
    },
    {
        id: 165,
        descricao: 'MunichRe',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 165,
    },
    {
        id: 170,
        descricao: 'Omint',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        integracao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoApolice,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'APÓLICE SEGURO DE VIDA INDIVIDUAL',
                        'OMINT',
                        'COBERTURAS ADICIONAIS CAPITALSEGURADO INÍCIO DE VIGÊNCIA FIM DE VIGÊNCIA PRÊMIO LÍQUIDO',
                        'assim como outros dados pessoais que venham ser coletados durante a execução do Contrato, a Omint Seguros observará as regras da Lei n. 13.709/2018',
                    ],
                rotinaIntegracao: importaApoliceOmint,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaOmint, // Vital e Foco
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoApolice,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'APÓLICE DE SEGURO DE VIDA INDIVIDUAL',
                        'OMINT',
                        'COBERTURAS CAPITAL SEGURADO INÍCIO DE VIGÊNCIA FIM DE VIGÊNCIA PRÊMIO LÍQUIDO',
                        'Este seguro é por prazo determinado, tendo a Omint Seguros a faculdade de não renovar a Apólice ao final da vigência, sem devolução dos prêmios pagos, conforme previsto nas Condições',
                    ],
                rotinaIntegracao: importaApoliceOmint,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaOmint, // Ideal
            },
        ],
        ordem: 170,
    },
    {
        id: 180,
        descricao: 'PASI',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 180,
    },
    {
        id: 190, // HARDCODE.idInstituicaoFinanceiraPortoSeguro
        descricao: 'Porto Seguro',
        SvgLogo: SvgImageParceirosPortoSeguroLogo,
        administradoraConsorcio: 'S',
        SvgConsorcioLogo: SvgImageParceirosPortoSeguroConsorcioLogo,
        svgConsorcioLogoMl: '25',
        primeirasParcelasList: [
            {id: 1, descricao: '1 parcela'},
            {id: 3, descricao: '3 parcelas'},
            {id: 5, descricao: '5 parcelas'},
            {id: 12, descricao: '12 parcelas'},
            {id: 24, descricao: '24 parcelas'},
        ],
        corretoraValores: 'N',
        seguradora: 'S',
        integracao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoApolice,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Porto Seguro Cia de Seguros Gerais',
                        'APÓLICE Nº',
                        'SEGURO DE VIDA INDIVIDUAL',
                        'Os Capitais Segurados e Prêmios deste Seguro serão atualizados anualmente, no aniversário da apólice, com base na variação',
                    ],
                rotinaIntegracao: importaApolicePortoSeguro,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaPortoSeguro,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoConsorcio,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoConsorcio,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'PROPOSTA E CONTRATO DE PARTICIPAÇÃO EM GRUPO', 
                        'DE CONSÓRCIO SEGMENTO BENS IMÓVEIS',
                        'PORTO SEGURO ADMINISTRADORA DE CONSÓRCIOS LTDA',
                    ],
                rotinaIntegracao: importaConsorcioBensImoveisPortoSeguro,
            },
        ],
        ordem: 190,
    },
    {
        id: 200,
        descricao: 'Previsul',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 200,
    },
    {
        id: 210,
        descricao: 'Prudential',
        SvgLogo: SvgImageParceirosPrudentialLogo,
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        integracao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoProposta,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoProposta,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Prudential do Brasil Seguros de Vida S.A.',
                        'PROPOSTA DE CONTRATAÇÃO DE SEGURO', 
                        'A seguradora terá o prazo de 15 (quinze) dias para manifestar-se sobre a Proposta. A contagem do prazo de 15 (quinze) dias',
                    ],
                rotinaIntegracao: importaPropostaPrudential,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaPrudential,
                classeAjuste: [
                    {id: 'STANDARD', idConversao: 'STANDARD'},
                    {id: 'CLASSE-A', idConversao: 'CLASSE A'},
                    {id: 'CLASSE-B', idConversao: 'CLASSE B'},
                    {id: 'CLASSE-C', idConversao: 'CLASSE C'},
                    {id: 'CLASSE-D', idConversao: 'CLASSE D'},
                    {id: 'CLASSE-E', idConversao: 'CLASSE E'},
                    {id: 'CLASSE-F', idConversao: 'CLASSE F'},
                    {id: 'FUMANTE-STANDARD', idConversao: 'FUMANTE-STANDARD'},
                    {id: 'NÃO-FUMANTE-STANDARD', idConversao: 'NÃO FUMANTE-STANDARD'},
                    {id: 'NÃO-FUMANTE-CLASSE-A', idConversao: 'NÃO FUMANTE-CLASSE A'},
                    {id: 'NÃO-FUMANTE-CLASSE-B', idConversao: 'NÃO FUMANTE-CLASSE B'},
                    {id: 'NÃO-FUMANTE-CLASSE-C', idConversao: 'NÃO FUMANTE-CLASSE C'},
                    {id: 'NÃO-FUMANTE-CLASSE-D', idConversao: 'NÃO FUMANTE-CLASSE D'},
                    {id: 'NÃO-FUMANTE-CLASSE-E', idConversao: 'NÃO FUMANTE-CLASSE E'},
                    {id: 'NÃO-FUMANTE-CLASSE-F', idConversao: 'NÃO FUMANTE-CLASSE F'},
                ],
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoApolice,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        //'The Prudential Insurance Company of America', 
                        'Detalhes da Apólice',
                        'Dados da Apólice',
                        'Não contestação a partir de:',
                        'Idade na Emissão:',
                        'Descrição das Coberturas',
                        '1) Valor já incluso no prêmio líquido por cobertura.',
                    ],
                rotinaIntegracao: importaApolicePrudential,
                statusApolice: statusApolicePrudential,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaPrudential,
                statusCobertura: statusCoberturaPrudential,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoApoliceSiteCliente,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Dados do Seguro',
                        'Descrição das situações protegidas',
                        '1) Valor já incluso no prêmio líquido por cobertura.',
                        //'Prudential do Brasil reforça a importância de preservar o sigilo das informações enviadas pelo Banco',
                        'Os valores apresentados indicam prêmio de cada cobertura e estão sujeitos a correções e atualizações monetárias.',
                        'O Capital Segurado será atualizado anualmente pelo IPCA/IBGE, conforme previsto nas Condições Gerais',
                    ],
                rotinaIntegracao: importaApolicePrudentialSiteCliente,
                statusApolice: statusApolicePrudential,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaPrudential,
                statusCobertura: statusCoberturaPrudential,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaApolicesImplantacaoConsultorFinanceiro,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                cabecalho: 0,
                chaveIntegracao:
                [
                    "Apólice",
                    "Proposta",
                    "Consultor Financeiro Serviço",
                    "Emissão",
                    "Status Apólice",
                    "Tipo de Grupo Status Apólice",
                    "Consultor Financeiro Emissão",
                    "Nome ou Nome Social do Cliente",//"Nome do Cliente",
                    "Sexo",
                    "Data de Nascimento",
                    "CPF",
                    "Endereço",
                    "Bairro",
                    "Cidade",
                    "Estado",
                    "CEP",
                    "Telefone",
                    "Celular",
                    "Tel.Comercial",
                    "E-mail",
                    "Nome ou Nome Social do Resp. Pagto.",//"Nome do Resp. Pagto.",
                    "CPF / CNPJ Resp. Pagto.",
                    "Endereço Resp. Pagto.",
                    "Bairro Resp. Pagto.",
                    "Cidade Resp. Pagto.",
                    "Estado Resp. Pagto.",
                    "CEP Resp. Pagto.",
                    "Telefone Resp. Pagto.",
                    "Celular Resp. Pagto.",
                    "Telefone (Comercial) Resp. Pagto.",
                    "E-mail Resp. Pagto.",
                    "Periodicidade Pagto.",
                    "Forma Pagto.",
                    "Cobertura",
                    "Produto",
                    "Produto",
                    "Classe Ajuste",
                    "Status Cobertura",
                    "Tipo de Grupo Status Cobertura",
                    "Tipo de Renovação",
                    "Cobertura Tem Renovação",
                    "Prêmio Aproximado (*)",
                    "Valor do Prêmio Após Renovação Core (c/imposto)",
                    "Valor do Prêmio Após Renovação (c/imposto)",
                    "Data Renovação/Extensão de Vigência",
                    "Término Cobertura",
                    "Vr. Face",
                    "Vr. Taxa Prêmio Extra",
                    "Motivo Prêmio Extra",
                    "Prêmio Extra Anual",
                    "Duração Prêmio Extra",
                    "Valor do Benefício",
                    "CS",
                    "Prêmio Líquido",
                    "IOF",
                    "Prêmio",
                    "PA",
                ],
                rotinaIntegracao: importaListaApolicesPrudentialImplantacaoConsultorFinanceiroLP,
                statusApolice: statusApolicePrudential,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaPrudential,
                statusCobertura: statusCoberturaPrudential,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaApolicesImplantacaoLP,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                cabecalho: 0,
                chaveIntegracao:
                [
                    "Apólice",
                    "Proposta",
                    "LP Serviço",
                    "Emissão",
                    "Status Apólice",
                    "Tipo de Grupo Status Apólice",
                    "LP Emissão",
                    "Nome ou Nome Social do Cliente",//"Nome do Cliente",
                    "Sexo",
                    "Data de Nascimento",
                    "CPF",
                    "Endereço",
                    "Bairro",
                    "Cidade",
                    "Estado",
                    "CEP",
                    "Telefone",
                    "Celular",
                    "Tel.Comercial",
                    "E-mail",
                    "Nome ou Nome Social do Resp. Pagto.",//"Nome do Resp. Pagto.",
                    "CPF / CNPJ Resp. Pagto.",
                    "Endereço Resp. Pagto.",
                    "Bairro Resp. Pagto.",
                    "Cidade Resp. Pagto.",
                    "Estado Resp. Pagto.",
                    "CEP Resp. Pagto.",
                    "Telefone Resp. Pagto.",
                    "Celular Resp. Pagto.",
                    "Telefone (Comercial) Resp. Pagto.",
                    "E-mail Resp. Pagto.",
                    "Periodicidade Pagto.",
                    "Forma Pagto.",
                    "Cobertura",
                    "Produto",
                    "Produto",
                    "Classe Ajuste",
                    "Status Cobertura",
                    "Tipo de Grupo Status Cobertura",
                    "Tipo de Renovação",
                    "Cobertura Tem Renovação",
                    "Prêmio Aproximado (*)",
                    "Valor do Prêmio Após Renovação Core (c/imposto)",
                    "Valor do Prêmio Após Renovação (c/imposto)",
                    "Data Renovação/Extensão de Vigência",
                    "Término Cobertura",
                    "Vr. Face",
                    "Vr. Taxa Prêmio Extra",
                    "Motivo Prêmio Extra",
                    "Prêmio Extra Anual",
                    "Duração Prêmio Extra",
                    "Valor do Benefício",
                    "CS",
                    "Prêmio Líquido",
                    "IOF",
                    "Prêmio",
                    "PA",
                ],
                rotinaIntegracao: importaListaApolicesPrudentialImplantacaoConsultorFinanceiroLP,
                statusApolice: statusApolicePrudential,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaPrudential,
                statusCobertura: statusCoberturaPrudential,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaApolicesImplantacaoSemAgente,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoApolice,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                cabecalho: 0,
                chaveIntegracao:
                [
                    "Apólice",
                    "Proposta",
                    "Emissão",
                    "Status Apólice",
                    "Tipo de Grupo Status Apólice",
                    "Nome ou Nome Social do Cliente",//"Nome do Cliente",
                    "Sexo",
                    "Data de Nascimento",
                    "CPF",
                    "Endereço",
                    "Bairro",
                    "Cidade",
                    "Estado",
                    "CEP",
                    "Telefone",
                    "Celular",
                    "Tel.Comercial",
                    "E-mail",
                    "Nome ou Nome Social do Resp. Pagto.",//"Nome do Resp. Pagto.",
                    "CPF / CNPJ Resp. Pagto.",
                    "Endereço Resp. Pagto.",
                    "Bairro Resp. Pagto.",
                    "Cidade Resp. Pagto.",
                    "Estado Resp. Pagto.",
                    "CEP Resp. Pagto.",
                    "Telefone Resp. Pagto.",
                    "Celular Resp. Pagto.",
                    "Telefone (Comercial) Resp. Pagto.",
                    "E-mail Resp. Pagto.",
                    "Periodicidade Pagto.",
                    "Forma Pagto.",
                    "Cobertura",
                    "Produto",
                    "Produto",
                    "Classe Ajuste",
                    "Status Cobertura",
                    "Tipo de Grupo Status Cobertura",
                    "Tipo de Renovação",
                    "Cobertura Tem Renovação",
                    "Prêmio Aproximado (*)",
                    "Valor do Prêmio Após Renovação Core (c/imposto)",
                    "Valor do Prêmio Após Renovação (c/imposto)",
                    "Data Renovação/Extensão de Vigência",
                    "Término Cobertura",
                    "Vr. Face",
                    "Vr. Taxa Prêmio Extra",
                    "Motivo Prêmio Extra",
                    "Prêmio Extra Anual",
                    "Duração Prêmio Extra",
                    "Valor do Benefício",
                    "CS",
                    "Prêmio Líquido",
                    "IOF",
                    "Prêmio",
                    "PA",
                ],
                rotinaIntegracao: importaListaApolicesPrudentialImplantacaoSemAgente,
                statusApolice: statusApolicePrudential,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaPrudential,
                statusCobertura: statusCoberturaPrudential,
            },
        ],
        integracaoCotacao: [
            {
                XLSXPDF: HARDCODE.PDF,
                chaveIntegracao:
                    [
                        'VIDA PRUDENTIAL', 
                        'Contratando o seguro de vida da Prudential do Brasil você e as pessoas escolhidas por vocês estarão preparadas',
                        'EVOLUÇÃO DA COBERTURA BÁSICA',
                        'Essa tabela exibe os pagamentos ao longo do tempo e o valor que estará disponível para resgate, caso haja interrupção do plano.',
                    ],
                rotinaIntegracao: importaCotacaoPrudentialTabelaEvolucaoValorResgate,
            },
            {
                XLSXPDF: HARDCODE.XLSX,
                cabecalho: 7,
                chaveIntegracao:
                    [
                        null,
                        "Anos",
                        "Capital Segurado",
                        "Aporte Mensal",
                        "Aporte Anual",
                        "Aporte Acumulado",
                        "Resgate Bruto*",
                        null,
                        "Invalidez Acidental",
                        null,
                        null,
                    ],
                rotinaIntegracao: importaCotacaoPrudentialXPTabelaEvolucaoValorResgateAtual,
            },
            {
                XLSXPDF: HARDCODE.XLSX,
                cabecalho: 7,
                chaveIntegracao:
                    [
                        null,
                        "Anos",
                        "Capital Segurado",
                        "Aporte Anual",
                        "Aporte Acumulado",
                        "Resgate Bruto*",
                        null,
                        "Invalidez Acidental",
                        null,
                    ],
                rotinaIntegracao: importaCotacaoPrudentialXPTabelaEvolucaoValorResgateAntiga,
            },
        ],
        ordem: 210,
    },
    {
        id: 220,
        descricao: 'Sancor',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 220,
    },
    {
        id: 230,
        descricao: 'Seguros Unimed',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 230,
    },
    {
        id: 240,
        descricao: 'Sompo',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 240,
    },
    {
        id: 245,
        descricao: 'SwissRe',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 245,
    },
    {
        id: 250,
        descricao: 'SulAmérica',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        /*integracaoCotacao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoProposta,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoProposta,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'SulAmérica',
                        'ESTE DOCUMENTO FOI PRODUZIDO PELA SULAMÉRICA INVESTIMENTOS DTVM, COM FINS MERAMENTE INFORMATIVOS NÃO SE CARACTERIZANDO COMO OFERTA OU SOLICITAÇÃO DE INVESTIMENTO OU DESINVESTIMENTO DE ATIVOS.', 
                        'FUNDOS DE INVESTIMENTO NÃO CONTAM COM GARANTIA DO ADMINISTRADOR, DO GESTOR, DE QUALQUER MECANISMO DE SEGURO OU DO FUNDO GARANTIDOR DE CRÉDITO- FGC.',
                        'PARA AVALIAÇÃO DA PERFORMANCE DE UM FUNDO DE INVESTIMENTO, É RECOMENDÁVEL A ANÁLISE DE, NO MÍNIMO, 12 (DOZE) MESES.',
                    ],
                rotinaIntegracao: importaPropostaMAG,
                tipoCoberturaSeguroVida: tipoCoberturaSeguroVidaMAG,
            },
        ],*/
        ordem: 250,
    },
    {
        id: 260,
        descricao: 'Sura',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 260,
    },
    {
        id: 270,
        descricao: 'Tokio Marine',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 270,
    },
    {
        id: 275, // HARDCODE.idInstituicaoFinanceiraXP
        descricao: 'XP',
        administradoraConsorcio: 'N',
        corretoraValores: 'S',
        seguradora: 'S',
        integracao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoProposta,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoProposta,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Essa é a proposta do seu seguro de vida. Neste documento estão as características do produto que',
                        'O seguro de vida é garantido pela XP Vida e Previdência S.A., empresa do grupo XP Inc.', 
                        'Ao assinar esta proposta, o cliente declara que as informações fornecidas são verdadeiras e',
                        'O artigo 765 do Código Civil que estabelece: "o Segurado e o Segurador são obrigados a guardar na',
                    ],
                rotinaIntegracao: importaPropostaXP,
                tipoCoberturaSeguroVida: [
                    {id: 'MORTE QUALQUER CAUSA', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaVidaInteira},
                    {id: 'DOENÇA TERMINAL', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves},
                    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaTemporario},
                    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAcidental},
                    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial},
                    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaAssistenciaFuneral},
                    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaCirurgia},
                    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIH},
                    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca},
                    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDITA},
                    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca},
                    //{id: 'x', tipoCoberturaSeguroVida: HARDCODE.idTipoCoberturaSeguroVidaDIT},
                ],
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoPosicaoInvestimentos,
                XLSXPDF: HARDCODE.PDF,
                tipoAcessoArquivoNULL: HARDCODE.tipoAcessoArquivoPosicaoInvestimentos,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoCliente,
                chaveIntegracao:
                    [
                        'Data de Referência', 
                        'R E S U M O  D E  I N F O R M A Ç Õ E S  D A  C A R T E I R A',
                        'E S T AT Í S T I C A  H I S T Ó R I C A',//  ( D E S D E  I N I C I O )',
                        'H I S T Ó R I C O  P O R  E S T R AT É G I A',
                        'R E N T A B I L I D A D E  P O R  C L A S S E',
                        'R E N T A B I L I D A D E  X  B E N C H M A R K S  M E N S A L',
                        'Este material foi elaborado pela XP INVESTIMENTOS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS S.A., tem caráter meramente informativo, não constitui e nem deve ser',
                    ],
                rotinaIntegracao: importaPosicaoInvestimentosXP,
                tipoRecursoFinanceiro: [
                    {id: 'PÓS FIXADO', idConversao: HARDCODE.idTipoRecursoFinanceiroPosFixado},
                    {id: 'INFLAÇÃO', idConversao: HARDCODE.idTipoRecursoFinanceiroInflacao},
                    {id: 'PRÉ FIXADO', idConversao: HARDCODE.idTipoRecursoFinanceiroPreFixado},
                    {id: 'MULTIMERCADO', idConversao: HARDCODE.idTipoRecursoFinanceiroMultimercado},
                    {id: 'ALTERNATIVO', idConversao: HARDCODE.idTipoRecursoFinanceiroMultimercado}, // novo
                    {id: 'RENDA VARIÁVEL', idConversao: HARDCODE.idTipoRecursoFinanceiroRendaVariavel},
                    {id: 'RENDA VARIÁVEL BRASIL', idConversao: HARDCODE.idTipoRecursoFinanceiroRendaVariavel}, // novo
                    {id: 'INTERNACIONAL', idConversao: HARDCODE.idTipoRecursoFinanceiroInternacional},
                ],
            },
        ],
        ordem: 275,
    },
    {
        id: 280,
        descricao: 'Zurich',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        ordem: 280,
    },
    {
        id: 900, // HARDCODE.idSeguradoraOutras
        descricao: 'Outras',
        administradoraConsorcio: 'N',
        corretoraValores: 'N',
        seguradora: 'S',
        integracao: [
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaTransferenciaClientes,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: null,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoTransferenciaCliente,
                cabecalho: 0,
                chaveIntegracao:
                    [
                        "CPF Cliente",
                        "Agente",
                    ],
                rotinaIntegracao: importaListaTransferenciaClientesOutras,
            },
            {
                tipoArquivo: HARDCODE.idTipoArquivoListaClientesAgentes,
                XLSXPDF: HARDCODE.XLSX,
                tipoAcessoArquivoNULL: null,
                tipoAtualizacao: HARDCODE.tipoAtualizacaoClienteAgente,
                cabecalho: 0,
                chaveIntegracao:
                    [
                        "CPF Cliente",
                        "Nome Cliente",
                        "Instituição Financeira",
                        "Número Conta Cliente",
                        "Código Agente",
                    ],
                rotinaIntegracao: importaListaClientesAgentesOutras,
            },
        ],
        ordem: 900,
    },
]

export default instituicaoFinanceiraList
import history from 'history.js'

import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

import PATH from '../business-const/Path/Path'

import AuthService from './AuthService'

import {
    osName,
    osVersion,
    browserName,
    fullBrowserVersion,
    mobileModel,
    mobileVendor,
    engineName,
    engineVersion,
    deviceType,
    getUA,
} from 'react-device-detect'

import publicIp from 'public-ip'



const getIp = async () => {

    try {

        return await publicIp.v4()

    } catch (erro) {

        return HARDCODE.ipNaoIdentificado
    }
}

export const usuarioInfo = async () => {

    return {
        ip: await getIp(),
        osName: osName,
        osVersion: osVersion,
        browserName: browserName,
        fullBrowserVersion: fullBrowserVersion,
        mobileModel: mobileModel,
        mobileVendor: mobileVendor,
        engineName: engineName,
        engineVersion: engineVersion,
        deviceType: deviceType,
        ua: getUA,
    }
}



class UsuarioService extends ApiService {

    constructor() {
        super(HARDCODE.apiUsuarios)
    }

    async autenticar(variaveisGlobais, credenciais, validarNecessidadePin, pin, retentativaLogin) {

        const body = {
            usuarioDTO: credenciais,
            logAcessoApiDTO: await usuarioInfo(),
            validarNecessidadePin: validarNecessidadePin,
            pin: pin,
            retentativaLogin: retentativaLogin,
            acessoCliente: false
        }

        return this.post(variaveisGlobais, HARDCODE.apiUsuariosAutenticar, body, true)
    }

    logout(variaveisGlobais, manterEmailUsuarioLogado) {

        AuthService.removerUsuarioLogado()

        if (!manterEmailUsuarioLogado) {

            AuthService.removerEmailUsuarioLogado()
        }

        AuthService.removerIdentificacaoUsuarioLogado()

        AuthService.removerToken()
        
        AuthService.removerDataInicioFreetrackUsuarioLogado()
        AuthService.removerUsuarioSelecionado()

        if (AuthService.obterAcessoCliente(variaveisGlobais)) {

            window.location.href = AuthService.obterLinkLogoTopbar(variaveisGlobais)

        } else {

            history.push(PATH.pageSignin)
        }

        //window.location.reload() // Estava causando erro quando havia alguma pendência de gravação - o reload foi tratado em Signin toda vez que faz login
    }

    salvar(variaveisGlobais, usuario) {

        return this.post(variaveisGlobais, HARDCODE.apiUsuariosSalvar, usuario, true)
    }

    atualizarSenha(variaveisGlobais, usuario) {

        return this.put(variaveisGlobais, HARDCODE.apiUsuariosAtualizarSenha, usuario, true)
    }

    reiniciarFreetrack(variaveisGlobais, email) {

        return this.put(variaveisGlobais, HARDCODE.apiUsuariosReiniciarFreetrack, {email: email}, true)
    }

    atualizarCamposCalculadosUsuarioNovaVersao(variaveisGlobais, idUsuario) {

        let params = `idUsuario=${idUsuario}`

        return this.get(variaveisGlobais, `${HARDCODE.apiUsuariosAtualizarCamposCalculadosUsuarioNovaVersao}/?${params}`, false)
    }

    buscarEquipeList(variaveisGlobais, idUsuario, idConfiguracao) {

        let params = `idUsuario=${idUsuario}`

        params = `${params}&idConfiguracao=${idConfiguracao}`

        return this.get(variaveisGlobais, `${HARDCODE.apiUsuariosBuscarEquipeList}/?${params}`, false)
    }
    
    buscarEquipeForm(variaveisGlobais, idUsuario, emailEquipeForm) {

        let params = `idUsuario=${idUsuario}`

        params = `${params}&emailEquipeForm=${emailEquipeForm}`

        return this.get(variaveisGlobais, `${HARDCODE.apiUsuariosBuscarEquipeForm}/?${params}`, false)
    }

    salvarEquipeForm(variaveisGlobais, usuario) {

        return this.post(variaveisGlobais, HARDCODE.apiUsuariosSalvarEquipeForm, usuario, false)
    }

    atualizarEquipeForm(variaveisGlobais, usuario) {

        return this.put(variaveisGlobais, HARDCODE.apiUsuariosAtualizarEquipeForm, usuario, false)
    }

    deletarEquipe(variaveisGlobais, idUsuario, idEquipe) {

        let params = `${idUsuario}`

        params = `${params}/${idEquipe}`

        return this.delete(variaveisGlobais, `${HARDCODE.apiUsuariosDeletarEquipe}/${params}`, false)
    }
    


    static setarEquipeList(variaveisGlobais, equipeList) {

        variaveisGlobais.equipeList = equipeList
    }

    static obterEquipeList(variaveisGlobais) {

        return variaveisGlobais.equipeList
    }
}

export default UsuarioService
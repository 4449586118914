import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import LABELESPECIAL from '../../../business-const/Label/LabelEspecial'

import {
    round
} from '../../../business-components/round/round'

import {
    ano,
    mes,
} from '../../../business-components/Date/Date'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    formataTableCellBodyExibeDadosTitulo,
    formataTableCellBodyExibeDadosTexto,
    formataTableCellBodyExibeDadosValor,
    formataTableCellBodyExibeDadosSubTotalTitulo,
    formataTableCellBodyExibeDadosSubTotalValor,
    formataTableCellBodyExibeDadosSubTotal0Titulo,
    formataTableCellBodyExibeDadosSubTotal0Valor,
    formataTableCellBodyExibeDadosTotalTitulo,
    formataTableCellBodyExibeDadosTotalValor,
} from '../../../business-components/Formata/Formata'

import {
    pesquisaList,
} from '../../../business-rules/List/ListService'

import tipoCoberturaSeguroVidaList from '../../../business-rules/List/SeguroVida/tipoCoberturaSeguroVidaList'

import familiaresList from '../../../business-rules/List/familiaresList'

import tipoRecursoFinanceiroList from '../../../business-rules/List/tipoRecursoFinanceiroList'

import {
    grupoCoberturaSeguroVida1aParteList,
    grupoCoberturaSeguroVida2aParteList,
} from '../../../business-rules/List/SeguroVida/grupoCoberturaSeguroVidaList'

import {
    calculaTempoCoberturaSeguroVida,
    calculaValorAcumuladoCoberturaSeguroVida,
} from '../seguroVida-form/SeguroVidaService'

import {
    dadosCompletosLinhaSeguroVidaApolice,
    seguroVidaApoliceAtiva,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaApolice'

import {
    dadosCompletosLinhaSeguroVidaCobertura,
    seguroVidaCoberturaAtiva,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaCobertura'

import {
    acumulaTotalRecursosFinanceirosCalculoMediaPonderadaTaxa,
} from './EstudoServiceGarantiasRecursosFinanceiros'

import {
    inicializaSerieGrafico,
    calculaGraficoAcumulacaoMensal,
} from './EstudoServiceCalculo'



export const calculaSeguroVida = (values, estudo, report) => {
    let seguroVidaTemp = []
    let seguroVida = []
    let panelGarantiasAtuaisSeguroVida

    if (values.seguroVidaApolice && values.seguroVidaCobertura) {
        let linhaTipoCoberturaSeguroVida = ''
        let descricaoTipoCoberturaSeguroVida = null

        let familiaresListAux = familiaresList(values)
        let linhaFamiliaresList = ''
        let nomeCurtoFamiliar = null

        let mesInicialSobrevivencia
        let anoInicialSobrevivencia
        let anoFinalSobrevivencia = values.anoFinalVitalicioClienteConjuge
        
        let linhaTipoRecursoFinanceiroGrafico
        let indexGraficoRecursosFinanceirosAcumulados

        let valor = 0
        let valorPremio = 0
        let tempoCoberturaSeguroVida = ''
        let tempoCoberturaSeguroVidaGrafico = ''
        let maiorTempoCoberturaSeguroVida = ''
        let valorAcumuladoAux = 0
        let valorAcumulado = 0
        let valorGarantiaCliente = 0
        let valorGarantiaConjuge = 0

        let saldoCalculoMediaPonderadaTaxa

        let valorPremioTotal = 0
        let valorAcumuladoTotal = 0
        let valorGarantiaClienteTotal = 0
        let valorGarantiaConjugeTotal = 0
        
        values.seguroVidaApolice.forEach( (linhaSeguroVidaApolice, index) => {

            if (
                    dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaApolice) &&
                    seguroVidaApoliceAtiva(linhaSeguroVidaApolice.statusApolice) &&
                    !pesquisaList(values.exclusaoFamiliares, linhaSeguroVidaApolice.familiar).id &&
                    !pesquisaList(values.exclusaoDespesasGarantias, LABEL.seguroVida + '/' +
                        linhaSeguroVidaApolice.id).id
            ) {
                linhaFamiliaresList = pesquisaList(familiaresListAux, linhaSeguroVidaApolice.familiar)

                nomeCurtoFamiliar = linhaFamiliaresList.descricao

                values.seguroVidaCobertura.forEach( (linhaSeguroVidaCobertura, index) => {

                    if (
                            linhaSeguroVidaCobertura.idSeguroVidaApolice === linhaSeguroVidaApolice.id &&
                            dadosCompletosLinhaSeguroVidaCobertura(linhaSeguroVidaCobertura, false) &&
                            seguroVidaCoberturaAtiva(linhaSeguroVidaCobertura.statusCobertura)
                    ) {

                        linhaTipoCoberturaSeguroVida = pesquisaList(tipoCoberturaSeguroVidaList, linhaSeguroVidaCobertura.tipoCoberturaSeguroVida)

                        descricaoTipoCoberturaSeguroVida = linhaTipoCoberturaSeguroVida.descricao

                        tempoCoberturaSeguroVida =
                            round(calculaTempoCoberturaSeguroVida(values, linhaSeguroVidaCobertura, linhaFamiliaresList), 0)

                        valorAcumuladoAux =
                            calculaValorAcumuladoCoberturaSeguroVida(linhaSeguroVidaCobertura, tempoCoberturaSeguroVida)

                        if (linhaTipoCoberturaSeguroVida.tipoValorInformado === "Diaria") {

                            tempoCoberturaSeguroVidaGrafico = 1

                            tempoCoberturaSeguroVida = tempoCoberturaSeguroVida + " " + LABEL.dias
                        } else {

                            if (!maiorTempoCoberturaSeguroVida || tempoCoberturaSeguroVida > maiorTempoCoberturaSeguroVida) {
                                maiorTempoCoberturaSeguroVida = tempoCoberturaSeguroVida
                            }

                            tempoCoberturaSeguroVidaGrafico = tempoCoberturaSeguroVida

                            if (tempoCoberturaSeguroVida) {
                                tempoCoberturaSeguroVida = tempoCoberturaSeguroVida + " " + LABELESPECIAL.anos
                            }
                        }

                        valor = round(linhaSeguroVidaCobertura.valorCS, 2)

                        valorPremio = round(linhaSeguroVidaCobertura.valorPremio, 2)

                        valorAcumulado = 0
                        valorGarantiaCliente = 0
                        valorGarantiaConjuge = 0

                        if (
                                (
                                    (
                                        (
                                            values.morteInvalidezEstudo === "A" ||
                                            values.morteInvalidezEstudo === "M"
                                        ) && 
                                        linhaTipoCoberturaSeguroVida.cobreMorte === "S"
                                    ) ||
                                    (
                                        values.morteInvalidezEstudo === "I" &&
                                        linhaTipoCoberturaSeguroVida.cobreInvalidez === "S"
                                    )
                                ) &&
                                (
                                    linhaSeguroVidaApolice.familiar === HARDCODE.idFamiliarCliente ||
                                    linhaSeguroVidaApolice.familiar === HARDCODE.idFamiliarConjuge
                                )
                        ) {

                            valorAcumulado = valorAcumuladoAux

                            if (linhaSeguroVidaApolice.familiar === HARDCODE.idFamiliarCliente) {
                                valorGarantiaCliente = valorAcumulado
                            }

                            if (linhaSeguroVidaApolice.familiar === HARDCODE.idFamiliarConjuge) {
                                valorGarantiaConjuge = valorAcumulado
                            }
                        }

                        if (
                                linhaSeguroVidaApolice.familiar === HARDCODE.idFamiliarCliente ||
                                linhaSeguroVidaApolice.familiar === HARDCODE.idFamiliarConjuge
                        ) {
                            if (linhaTipoCoberturaSeguroVida.informaCSSobrevivencia === "S") {
                                linhaTipoRecursoFinanceiroGrafico = pesquisaList(tipoRecursoFinanceiroList,
                                    HARDCODE.idTipoRecursoFinanceiroSeguroVidaSobrevivencia)

                                indexGraficoRecursosFinanceirosAcumulados =
                                    inicializaSerieGrafico(values, estudo.current.graficoRecursosFinanceirosAcumulados,
                                        linhaTipoRecursoFinanceiroGrafico, linhaTipoRecursoFinanceiroGrafico.id + '-N-' +
                                            linhaTipoRecursoFinanceiroGrafico.descricao, linhaTipoRecursoFinanceiroGrafico.descricao)

                                mesInicialSobrevivencia = mes(linhaSeguroVidaCobertura.dataTerminoCobertura)
                                anoInicialSobrevivencia = ano(linhaSeguroVidaCobertura.dataTerminoCobertura)

                                if (mesInicialSobrevivencia === 12) {

                                    mesInicialSobrevivencia = 1
                                    anoInicialSobrevivencia++

                                } else {

                                    mesInicialSobrevivencia++
                                }

                                saldoCalculoMediaPonderadaTaxa =
                                    calculaGraficoAcumulacaoMensal(mesInicialSobrevivencia, anoInicialSobrevivencia, null,
                                        anoFinalSobrevivencia,
                                            round(linhaSeguroVidaCobertura.valorCSSobrevivencia, 2), 0, null,
                                                estudo.current.graficoAnos,
                                                    estudo.current.graficoRecursosFinanceirosAcumulados[
                                                        indexGraficoRecursosFinanceirosAcumulados].serie,
                                                            estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                                values, estudo, values.taxaRetornoAnualReal,
                                                                    values.taxaRetornoAnualRealAposAposentadoria,
                                                                        true, null, null,
                                                                            null, estudo.current.evolucaoReservaFinanceiraEstudo, null)

                                acumulaTotalRecursosFinanceirosCalculoMediaPonderadaTaxa(values, linhaTipoRecursoFinanceiroGrafico,
                                    saldoCalculoMediaPonderadaTaxa, values.taxaRetornoAnualReal)
                            }
                        }

                        seguroVidaTemp.push({
                            ordem: ("0000" + linhaSeguroVidaApolice.familiar).slice(-4) +
                                ("0000" + linhaSeguroVidaCobertura.grupoCoberturaSeguroVida).slice(-4),
                            familiar: linhaSeguroVidaApolice.familiar,
                            nomeCurtoFamiliar: nomeCurtoFamiliar,
                            grupoCoberturaSeguroVida: linhaSeguroVidaCobertura.grupoCoberturaSeguroVida,
                            descricaoTipoCoberturaSeguroVida:
                                (linhaSeguroVidaApolice.numeroApolice
                                    ?
                                        LABEL.apolice + ' ' + linhaSeguroVidaApolice.numeroApolice + ": "
                                    :
                                        ''
                                ) + descricaoTipoCoberturaSeguroVida,
                            valor: valor,
                            valorPremio: valorPremio,
                            tempoCoberturaSeguroVida: tempoCoberturaSeguroVida,
                            tempoCoberturaSeguroVidaGrafico: tempoCoberturaSeguroVidaGrafico,
                            valorAcumulado: valorAcumulado,
                            valorGarantiaCliente: valorGarantiaCliente,
                            valorGarantiaConjuge: valorGarantiaConjuge,
                        })

                        valorPremioTotal += valorPremio
                        valorAcumuladoTotal += valorAcumulado
                        valorGarantiaClienteTotal += valorGarantiaCliente
                        valorGarantiaConjugeTotal += valorGarantiaConjuge
                    }
                })
            }
        })

        seguroVidaTemp = sortAsc(seguroVidaTemp)

        let familiarAnt = null

        let grupoCoberturaSeguroVidaAnt = null

        let indexGraficoSeguroVidaCobertura = -1

        seguroVidaTemp.forEach( (linhaSeguroVida, index) => {

            if (linhaSeguroVida.familiar !== familiarAnt) {

                valorPremio = 0

                seguroVidaTemp.forEach( (linhaSeguroVidaTotal, index) => {
                    if (linhaSeguroVidaTotal.familiar === linhaSeguroVida.familiar) {
                        valorPremio = valorPremio +
                            linhaSeguroVidaTotal.valorPremio
                    }
                })

                seguroVida.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotal0Titulo(linhaSeguroVida.nomeCurtoFamiliar, report, true),
                    valorPremio: 
                        formataTableCellBodyExibeDadosSubTotal0Valor(valorPremio, report, true),
                })

                estudo.current.graficoSeguroVidaCobertura.push({
                    id: linhaSeguroVida.familiar,
                    descricao: linhaSeguroVida.nomeCurtoFamiliar,
                    series: [],
                    categories: [],
                })

                indexGraficoSeguroVidaCobertura++
            }

            if (
                    linhaSeguroVida.familiar !== familiarAnt ||
                    linhaSeguroVida.grupoCoberturaSeguroVida !== grupoCoberturaSeguroVidaAnt
            ) {

                familiarAnt = linhaSeguroVida.familiar

                grupoCoberturaSeguroVidaAnt = linhaSeguroVida.grupoCoberturaSeguroVida

                valor = 0
                valorPremio = 0
                valorAcumulado = 0
                valorGarantiaCliente = 0
                valorGarantiaConjuge = 0

                seguroVidaTemp.forEach( (linhaSeguroVidaTotal, index) => {

                    if (
                            linhaSeguroVidaTotal.familiar === linhaSeguroVida.familiar &&
                            linhaSeguroVidaTotal.grupoCoberturaSeguroVida === linhaSeguroVida.grupoCoberturaSeguroVida
                    ) {

                        valor += linhaSeguroVidaTotal.valor
                        valorPremio += linhaSeguroVidaTotal.valorPremio
                        valorAcumulado += linhaSeguroVidaTotal.valorAcumulado
                        valorGarantiaCliente += linhaSeguroVidaTotal.valorGarantiaCliente
                        valorGarantiaConjuge += linhaSeguroVidaTotal.valorGarantiaConjuge
                    }
                })
                
                seguroVida.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(
                            grupoCoberturaSeguroVida1aParteList[linhaSeguroVida.grupoCoberturaSeguroVida] + " " + 
                                grupoCoberturaSeguroVida2aParteList[linhaSeguroVida.grupoCoberturaSeguroVida],
                                    report, 5),
                    valor: 
                        formataTableCellBodyExibeDadosSubTotalValor(valor, report),
                    valorPremio: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorPremio, report),
                    valorAcumulado: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorAcumulado, report),
                    valorGarantiaCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaCliente, report),
                    valorGarantiaConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaConjuge, report),
                })
            }

            seguroVida.push({
                descricao: 
                    formataTableCellBodyExibeDadosTitulo(
                        linhaSeguroVida.descricaoTipoCoberturaSeguroVida, report, null, 10),
                valor: 
                    formataTableCellBodyExibeDadosValor(linhaSeguroVida.valor, report),
                tempoCoberturaSeguroVida:
                    linhaSeguroVida.tempoCoberturaSeguroVida
                        ?
                            formataTableCellBodyExibeDadosTexto(linhaSeguroVida.tempoCoberturaSeguroVida, report)
                        :
                            '',
                valorPremio: 
                    formataTableCellBodyExibeDadosValor(linhaSeguroVida.valorPremio, report),
                valorAcumulado: 
                    formataTableCellBodyExibeDadosValor(linhaSeguroVida.valorAcumulado, report),
                valorGarantiaCliente: 
                    formataTableCellBodyExibeDadosValor(linhaSeguroVida.valorGarantiaCliente, report),
                valorGarantiaConjuge: 
                    formataTableCellBodyExibeDadosValor(linhaSeguroVida.valorGarantiaConjuge, report),
            })

            if (linhaSeguroVida.tempoCoberturaSeguroVidaGrafico) {
                estudo.current.graficoSeguroVidaCobertura[indexGraficoSeguroVidaCobertura].series.push(
                    linhaSeguroVida.tempoCoberturaSeguroVidaGrafico,
                )

                estudo.current.graficoSeguroVidaCobertura[indexGraficoSeguroVidaCobertura].categories.push(
                    linhaSeguroVida.descricaoTipoCoberturaSeguroVida,
                )
            }
        })

        seguroVida.push({
            descricao: formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report),
            valorPremio: 
                formataTableCellBodyExibeDadosTotalValor(valorPremioTotal, report),
            valorAcumulado: 
                formataTableCellBodyExibeDadosTotalValor(valorAcumuladoTotal, report),
            valorGarantiaCliente: 
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaClienteTotal, report),
            valorGarantiaConjuge: 
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaConjugeTotal, report),
        })

        panelGarantiasAtuaisSeguroVida = {
            descricao: LABEL.seguroVida,
            valorAcumuladoPainel: valorAcumuladoTotal,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: valorGarantiaClienteTotal,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: valorGarantiaConjugeTotal,
            anosProtegerProtegido: maiorTempoCoberturaSeguroVida,
        }
        
    } else {

        panelGarantiasAtuaisSeguroVida = {
            descricao: LABEL.seguroVida,
            valorAcumuladoPainel: 0,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: '',
        }
    }

    estudo.current.seguroVida = seguroVida

    estudo.current.panelGarantiasAtuais[HARDCODE.indexEstudoSeguroVida] = panelGarantiasAtuaisSeguroVida
}
// alterado - revisado

const tipoGrupoDespesaList = [
    {
        id: 10,
        descricao: 'Diversas',
        ordem: 10,
    },
    {
        id: 20,
        descricao: 'Casa',
        ordem: 20,
    },
    {
        id: 30,
        descricao: 'Alimentação',
        ordem: 30,
    },
    {
        id: 40,
        descricao: 'Empregados',
        ordem: 40,
    },
    {
        id: 50,
        descricao: 'Lazer',
        ordem: 50,
    },
    {
        id: 60,
        descricao: 'Veículo',
        ordem: 60,
    },
    {
        id: 70,
        descricao: 'Cuidados Pessoais',
        ordem: 70,
    },
    {
        id: 80,
        descricao: 'Saúde',
        ordem: 80,
    },
    {
        id: 90,
        descricao: 'Pensão',
        ordem: 90,
    },
    {
        id: 95,
        descricao: 'Pet',
        ordem: 95,
    },
    {
        id: 100,
        descricao: 'Seguro de Vida',
        ordem: 100,
    },
    {
        id: 105,
        descricao: 'Transporte',
        ordem: 105,
    },
    {
        id: 110,
        descricao: 'Viagem',
        ordem: 110,
    },
    {
        id: 610,
        descricao: 'Educação',
        ordem: 610,
    },
    {
        id: 710,
        descricao: 'Financiamento',
        ordem: 710,
    },
    {
        id: 810,
        descricao: 'Essencial',
        ordem: 810,
    },
    {
        id: 820,
        descricao: 'Supérfluo',
        ordem: 820,
    },
    {
        id: 830,
        descricao: 'Temporárias',
        ordem: 820,
    },
    {
        id: 900, // HARDCODE.idGrupoDespesaOutras
        descricao: 'Outras',
        ordem: 900,
    },
]

export default tipoGrupoDespesaList
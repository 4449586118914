import consorcioLogoSvg from './consorcioLogo.svg'

export const SvgImageParceirosMAPFREConsorcioLogo = ( props ) => {
    return (
        <img
            className='w-full'
            width="50px"
            height="50px"
            src={consorcioLogoSvg}
            alt={''}
            {...props}
        />
    )
}
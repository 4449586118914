import LABELESPECIAL from '../../business-const/Label/LabelEspecial'

import AuthService from '../../business-api/AuthService'

import {
    tituloSimulacaoMorteClienteConjuge,
} from '../../views/clientes/estudo-form/EstudoService'

export const morteInvalidezEstudoList = ( values, idadeMorteAte ) => {

    let morteInvalidezEstudoList = []

    if (values.anoFinalSimulacaoMorteClienteConjuge !== values.anoFinalVitalicioClienteConjuge && values.exibirMorteAte) {
        morteInvalidezEstudoList.push(
            {
                id: 'A',
                descricao: AuthService.obterTextoMorte() + ' até ' + tituloSimulacaoMorteClienteConjuge(values),
                descricaoResumida: AuthService.obterTextoMorte() + ' até ' + idadeMorteAte + ' ' + LABELESPECIAL.anos,
                ordem: 10,
            },
        )
    }

    morteInvalidezEstudoList.push(
        {
            id: 'M',
            descricao: AuthService.obterTextoMorte() + ' Vitalícia',
            descricaoResumida: AuthService.obterTextoMorte() + ' Vitalícia',
            ordem: 20,
        },
        {
            id: 'I',
            descricao: 'Invalidez',
            descricaoResumida: 'Invalidez',
            ordem: 30,
        },
    )

    return morteInvalidezEstudoList
}

export default morteInvalidezEstudoList
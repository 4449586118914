import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import {
    pesquisaList
} from '../../business-rules/List/ListService'

import formList from './formList'



const reportList = (idFormList, linhaTipoLicencaNULL, configuracaoPagina, jsonPaginaNULL, paginaAdicionalNULL) => {

    let reportListAux = []

    const linhaFormList = pesquisaList(formList, idFormList)

    const abertura = 'a) '
    const gestaoPatrimonial = 'b) '
    const aposentadoria = 'c) '
    const protecao = 'd) '
    const previdenciaPrivada = 'e) '
    const consorcio = 'f) '
    const comparacaoFinanciamentos = 'g) '
    const comparacaoCenarios = 'h) '
    const comparacaoEstudos = 'i) '
    const pagina = 'p) '
    const paginaAdicional = 'pa) '
    const simulacaoFinanceira = 'sf) '
    const consideracaoFinal = 'cf) '

    if (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirSomentePrevidenciaPrivada === 'S') {

        reportListAux.push(
            {
                id: HARDCODE.estudoFormCapa,
                descricao: LABEL.abertura + ': ' + LABEL.estudoReportCapa,
                content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.estudoReportCapa,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.estudoReportConferenciaDados,
                descricao: LABEL.abertura + ': ' + LABEL.conferenciaDados,
                content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.conferenciaDados,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.estudoFormObjetivos,
                descricao: LABEL.abertura + ': ' + LABEL.objetivosPrincipaisPreocupacoesPFCMLP,
                content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.objetivosPrincipaisPreocupacoesPFCMLP,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.previdenciaPrivadaFormPrevidenciaPrivada,
                descricao: LABEL.previdenciaPrivada + ': ' + LABEL.analiseBeneficiosPrevidenciaPrivada,
                content: '<b>' + LABEL.previdenciaPrivada + '</b>: ' + LABEL.analiseBeneficiosPrevidenciaPrivada,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.estudoFormConsideracoesFinais,
                descricao: LABEL.conclusao + ': ' + linhaFormList.labelTabClienteFormListConsideracoesFinais,
                content: '<b>' + LABEL.conclusao + '</b>: ' + linhaFormList.labelTabClienteFormListConsideracoesFinais,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
        )

    } else {

        if (!configuracaoPagina) {

            reportListAux.push(
                {
                    id: HARDCODE.estudoFormCapa,
                    descricao: abertura + LABEL.abertura + ': ' + LABEL.estudoReportCapa,
                    content: '<b>' + abertura +  LABEL.abertura + '</b>: ' + LABEL.estudoReportCapa,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
            )

            reportListAux.push(
                {
                    id: HARDCODE.estudoFormOQueEsperarDesseTrabalho,
                    descricao: abertura + LABEL.abertura + ': ' + LABEL.oQueEsperarDesseTrabalho,
                    content: '<b>' + abertura + LABEL.abertura + '</b>: ' + LABEL.oQueEsperarDesseTrabalho,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
            )

            //???if (linhaFormList.perguntasObjetivos === HARDCODE.perguntasObjetivosPerguntas) {
            //
            //    reportListAux.push(
            //        {
            //            id: ???'EstudoFormPerguntas',
            //            descricao: '<b>' + LABEL.abertura + '</b>: ' + LABEL.perguntas,
            //            content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.perguntas,
            //            ////validaInformaMapaFinanceiro: 'N',
            //            imprimir: 'S',
            //        },
            //    )
            //}

            if (linhaFormList.perguntasObjetivos === HARDCODE.perguntasObjetivosObjetivos) {

                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormObjetivos,
                        descricao: abertura + LABEL.abertura + ': ' + LABEL.objetivosPrincipaisPreocupacoesPFCMLP,
                        content: '<b>' + abertura + LABEL.abertura + '</b>: ' + LABEL.objetivosPrincipaisPreocupacoesPFCMLP,
                        ////validaInformaMapaFinanceiro: 'N',
                        imprimir: 'S',
                    },
                )
            }
        }

        reportListAux.push(
            {
                id: HARDCODE.estudoReportConferenciaDados,
                descricao: abertura + LABEL.abertura + ': ' + LABEL.conferenciaDados,
                content: '<b>' + abertura + LABEL.abertura + '</b>: ' + LABEL.conferenciaDados,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
        )

        if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirSomenteConsorcio === 'N')) {

            reportListAux.push(
                {
                    id: HARDCODE.estudoReportConferenciaDadosClienteConjugeFamiliares,
                    descricao: abertura + LABEL.abertura + ': ' + LABEL.conferenciaDadosClienteConjugeFamiliares,
                    content: '<b>' + abertura + LABEL.abertura + '</b>: ' + LABEL.conferenciaDadosClienteConjugeFamiliares,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: '-',
                },
            )

            reportListAux.push(
                {
                    id: HARDCODE.estudoReportConferenciaDadosClienteConjugeFamiliaresObjetivos,
                    descricao: abertura + LABEL.abertura + ': ' + LABEL.conferenciaDadosClienteConjugeFamiliaresObjetivos,
                    content: '<b>' + abertura + LABEL.abertura + '</b>: ' + LABEL.conferenciaDadosClienteConjugeFamiliaresObjetivos,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: '-',
                },
            )

            reportListAux.push(
                {
                    id: HARDCODE.estudoReportConferenciaDadosClienteConjugeFamiliaresTaxas,
                    descricao: LABEL.abertura + ': ' + LABEL.conferenciaDadosClienteConjugeFamiliaresTaxas,
                    content: '<b>' + abertura + LABEL.abertura + '</b>: ' + LABEL.conferenciaDadosClienteConjugeFamiliaresTaxas,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: '-',
                },
            )

            if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S')) {

                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormPlanejamentoEspecializacaoPeriodoSabaticoResumo,
                        descricao: abertura + LABEL.abertura + ': ' + LABEL.planejamentoEspecializacaoPeriodoSabatico,
                        content: '<b>' + abertura + LABEL.abertura + '</b>: ' + LABEL.planejamentoEspecializacaoPeriodoSabatico,
                        ////validaInformaMapaFinanceiro: 'N',
                        imprimir: 'S',
                    },
                )
            }

            //if (
            //        !linhaTipoLicencaNULL ||
            //        (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S') ||
            //        linhaFormList.exibirGestaoPatrimonial === "S"
            //) {
                
            if (!configuracaoPagina) {
                
                reportListAux.push(
                    {
                        id: HARDCODE.gestaoPatrimonialFormEstruturaFamiliar,
                        descricao: gestaoPatrimonial + LABEL.gestaoPatrimonial + ': ' + LABEL.estruturaFamiliar,
                        content: '<b>' + gestaoPatrimonial + LABEL.gestaoPatrimonial + '</b>: ' + LABEL.estruturaFamiliar,
                        ////validaInformaMapaFinanceiro: 'N',
                        imprimir: '-',
                    },
                )
            }

            reportListAux.push(
                {
                    id: HARDCODE.carteiraInvestimentosChart,
                    descricao: gestaoPatrimonial + LABEL.gestaoPatrimonial + ': ' + LABEL.carteiraInvestimentos,
                    content: '<b>' + gestaoPatrimonial + LABEL.gestaoPatrimonial + '</b>: ' + LABEL.carteiraInvestimentos,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
            )

            if (!configuracaoPagina) {
                
                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormTabelaEvolucaoValorResgate,
                        descricao: gestaoPatrimonial + LABEL.gestaoPatrimonial + ': ' + LABEL.tabelaEvolucaoValorResgate,
                        content: '<b>' + gestaoPatrimonial + LABEL.gestaoPatrimonial + '</b>: ' + LABEL.tabelaEvolucaoValorResgate,
                        ////validaInformaMapaFinanceiro: 'N',
                        imprimir: '-',
                    },
                )

                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormTabelaEvolucaoValorResgateComparacaoPreco,
                        descricao: gestaoPatrimonial + LABEL.gestaoPatrimonial + ': ' + LABEL.tabelaEvolucaoValorResgate + ' - ' + LABEL.comparacaoPreco,
                        content: '<b>' + gestaoPatrimonial + LABEL.gestaoPatrimonial + '</b>: ' + LABEL.tabelaEvolucaoValorResgate + ' - ' + LABEL.comparacaoPreco,
                        ////validaInformaMapaFinanceiro: 'N',
                        imprimir: '-',
                    },
                )
            }
        }

        reportListAux.push(
            {
                id: HARDCODE.analisePatrimonialChart,
                descricao: aposentadoria + LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.analisePatrimonial,
                content: '<b>' + aposentadoria + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.analisePatrimonial,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.receitaAnualChart,
                descricao: aposentadoria + LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.receitaAnualLongoTempo,
                content: '<b>' + aposentadoria + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.receitaAnualLongoTempo,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.despesaAnualChart,
                descricao: aposentadoria + LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.despesaAnualLongoTempo,
                content: '<b>' + aposentadoria + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.despesaAnualLongoTempo,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.balancoReceitaXDespesaAnualChart,
                descricao: aposentadoria + LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.balancoReceitaDespesaAnual,
                content: '<b>' + aposentadoria + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.balancoReceitaDespesaAnual,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
        )
        //}

        if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S')) {
            
            reportListAux.push(
                {
                    id: HARDCODE.consumoReservaAposentadoriaChart,
                    descricao: aposentadoria + LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.consumoReservaAposentadoria,
                    content: '<b>' + aposentadoria + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.consumoReservaAposentadoria,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
                {
                    id: HARDCODE.diagnosticoAposentadoriaChart,
                    descricao: aposentadoria + LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.diagnosticoAposentadoria,
                    content: '<b>' + aposentadoria + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.diagnosticoAposentadoria,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
                {
                    id: HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraChart,
                    descricao: aposentadoria + LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.diagnosticoAposentadoriaIndependenciaFinanceira,
                    content: '<b>' + aposentadoria + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.diagnosticoAposentadoriaIndependenciaFinanceira,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
            )
        }
        
        if (!configuracaoPagina) {

            if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S')) {

                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormEvolucaoReservaFinanceiraEstudo,
                        descricao: aposentadoria + LABEL.aposentadoria + ': ' + LABEL.evolucaoReservaFinanceira,
                        content: '<b>' + aposentadoria + LABEL.aposentadoria + '</b>: ' + LABEL.evolucaoReservaFinanceira,
                        ////validaInformaMapaFinanceiro: 'N',
                        imprimir: '-',
                    },
                )
            }
        }

        if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S')) {
            
            reportListAux.push(
                {
                    id: HARDCODE.estudoFormProjetoVidaSonhoTracarMeta,
                    descricao: aposentadoria + LABEL.projetoVidaSonho + ': ' + LABEL.projetoVidaSonhoTracarMeta,
                    content: '<b>' + aposentadoria + LABEL.projetoVidaSonho + '</b>: ' + LABEL.projetoVidaSonhoTracarMeta,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
            )

            if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirSomenteConsorcio === 'N')) {

                reportListAux.push(
                    {
                        id: HARDCODE.previdenciaPrivadaFormPrevidenciaPrivada,
                        descricao: previdenciaPrivada + LABEL.previdenciaPrivada + ': ' + LABEL.analiseBeneficiosPrevidenciaPrivada,
                        content: '<b>' + previdenciaPrivada + LABEL.previdenciaPrivada + '</b>: ' + LABEL.analiseBeneficiosPrevidenciaPrivada,
                        ////validaInformaMapaFinanceiro: 'N',
                        imprimir: '-',
                    },
                )
            }

            reportListAux.push(
                {
                    id: HARDCODE.consorcioFormSimulacao,
                    descricao: consorcio + LABEL.consorcio + ': ' + LABEL.simulacao,
                    content: '<b>' + consorcio + LABEL.consorcio + '</b>: ' + LABEL.simulacao,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: '-',
                },
            )

            reportListAux.push(
                {
                    id: HARDCODE.estudoFormComparacaoFinanciamentos,
                    descricao: comparacaoFinanciamentos + LABEL.comparacaoFinanciamentos + ': ' + LABEL.analiseFinanciamentoSugerido,
                    content: '<b>' + comparacaoFinanciamentos + LABEL.comparacaoFinanciamentos + '</b>: ' + LABEL.analiseFinanciamentoSugerido,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: '-',
                },
            )
        }

        if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirSomenteConsorcio === 'N')) {

            reportListAux.push(
                {
                    id: HARDCODE.estudoFormProtecaoSugerida,
                    descricao: protecao + LABEL.protecao + ': ' + LABEL.protecaoSugerida,
                    content: '<b>' + protecao + LABEL.protecao + '</b>: ' + LABEL.protecaoSugerida,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
                {
                    id: HARDCODE.estudoFormSucessaoVitalicia,
                    descricao: protecao + LABEL.protecao + ': ' + LABEL.sucessaoVitalicia,
                    content: '<b>' + protecao + LABEL.protecao + '</b>: ' + LABEL.sucessaoVitalicia,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
                {
                    id: HARDCODE.estudoFormComposicaoInventario,
                    descricao: protecao + LABEL.protecao + ': ' + LABEL.composicaoInventario,
                    content: '<b>' + protecao + LABEL.protecao + '</b>: ' + LABEL.composicaoInventario,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.projecaoSucessaoVitaliciaChart,
                    descricao: protecao + LABEL.protecao + ': ' + LABEL.projecaoSucessaoVitalicia,
                    content: '<b>' + protecao + LABEL.protecao + '</b>: ' + LABEL.projecaoSucessaoVitalicia,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
                {
                    id: HARDCODE.despesasAcumuladasChart,
                    descricao: protecao + LABEL.protecao + ': ' + LABEL.grafico + ' ' + LABEL.despesasAcumuladasLongoTempo,
                    content: '<b>' + protecao + LABEL.protecao + '</b>: ' + LABEL.grafico + ' ' + LABEL.despesasAcumuladasLongoTempo,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
                {
                    id: HARDCODE.estudoReportResumoNecessidadeProtecao,
                    descricao: protecao + LABEL.protecao + ': ' + LABEL.necessidadeProtecao,
                    content: '<b>' + protecao + LABEL.protecao + '</b>: ' + LABEL.necessidadeProtecao,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
                {
                    id: HARDCODE.estudoFormDespesasDiaDiaDespesasVitalicias,
                    descricao: protecao + LABEL.despesasTotais + ': ' + LABEL.diaDiaDespesasVitalicias,
                    content: '<b>' + protecao + LABEL.despesasTotais + '</b>: ' + LABEL.diaDiaDespesasVitalicias,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormDespesasEducacao,
                    descricao: protecao + LABEL.despesasTotais + ': ' + LABEL.educacao,
                    content: '<b>' + protecao + LABEL.despesasTotais + '</b>: ' + LABEL.educacao,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormDespesasFinanciamento,
                    descricao: protecao + LABEL.despesasTotais + ': ' + LABEL.financiamento,
                    content: '<b>' + protecao + LABEL.despesasTotais + '</b>: ' + LABEL.financiamento,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormDespesasDiaDiaDespesasTemporarias,
                    descricao: protecao + LABEL.despesasTotais + ': ' + LABEL.diaDiaDespesasTemporarias,
                    content: '<b>' + protecao + LABEL.despesasTotais + '</b>: ' + LABEL.diaDiaDespesasTemporarias,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormProjetoVidaSonho,
                    descricao: protecao + LABEL.despesasTotais + ': ' + LABEL.projetoVidaSonho,
                    content: '<b>' + protecao + LABEL.despesasTotais + '</b>: ' + LABEL.projetoVidaSonho,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormGarantiasPatrimonioImobilizado,
                    descricao: protecao + LABEL.garantiasAtuais + ': ' + LABEL.patrimonioImobilizado,
                    content: '<b>' + protecao + LABEL.garantiasAtuais + '</b>: ' + LABEL.patrimonioImobilizado,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormGarantiasReceitaMensal,
                    descricao: protecao + LABEL.garantiasAtuais + '>: ' + LABEL.receitaMensal,
                    content: '<b>' + protecao + LABEL.garantiasAtuais + '</b>: ' + LABEL.receitaMensal,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormGarantiasRecursosFinanceiros,
                    descricao: LABEL.garantiasAtuais + ': ' + LABEL.recursosFinanceiros,
                    content: '<b>' + protecao + LABEL.garantiasAtuais + '</b>: ' + LABEL.recursosFinanceiros,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormSeguroVida,
                    descricao: protecao + LABEL.garantiasAtuais + ': ' + LABEL.seguroVida,
                    content: '<b>' + protecao + LABEL.garantiasAtuais + '</b>: ' + LABEL.seguroVida,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
            )

            if (!configuracaoPagina) {
            
                if (
                        !linhaTipoLicencaNULL ||
                        (
                            linhaTipoLicencaNULL &&
                            linhaTipoLicencaNULL.exibirSimulacaoFinanceira === 'S'
                        )
                ) {

                    
                    
                    reportListAux.push(
                        {
                            id: HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraPrimeiraReuniaoChart,
                            descricao: simulacaoFinanceira + LABEL.simulacaoFinanceira + ': ' + LABEL.diagnosticoAposentadoriaIndependenciaFinanceira,
                            content: '<b>' + simulacaoFinanceira + LABEL.simulacaoFinanceira + '</b>: ' + LABEL.diagnosticoAposentadoriaIndependenciaFinanceira,
                            ////validaInformaMapaFinanceiro: 'N',
                            imprimir: '-',
                        },
                        {
                            id: HARDCODE.simulacaoFinanceiraFormEvolucaoReservaFinanceira,
                            descricao: simulacaoFinanceira + LABEL.simulacaoFinanceira + ': ' + LABEL.evolucaoReservaFinanceira,
                            content: '<b>' + simulacaoFinanceira + LABEL.simulacaoFinanceira + '</b>: ' + LABEL.evolucaoReservaFinanceira,
                            ////validaInformaMapaFinanceiro: 'N',
                            imprimir: '-',
                        },
                    )
                }
            }

            if (
                    (
                        !linhaTipoLicencaNULL ||
                        (
                            linhaTipoLicencaNULL && 
                            linhaTipoLicencaNULL.exibirSimulacaoFinanceira === 'S'
                        )
                    ) &&
                    linhaFormList.exibirMapaFinanceiro === 'S' &&
                    linhaFormList.reportPrimeiraReuniaoInvestimentoSeguroVida === "N"
            ) {

                reportListAux.push(
                    {
                        id: HARDCODE.mapaFinanceiroFormGrafico,
                        descricao: simulacaoFinanceira + LABEL.mapaFinanceiro + ': ' + LABEL.grafico,
                        content: '<b>' + simulacaoFinanceira + LABEL.mapaFinanceiro + '</b>: ' + LABEL.grafico,
                        validaInformaMapaFinanceiro: 'S',
                        imprimir: 'N',
                    },
                )
            }
        }

        if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S')) {

            reportListAux.push(
                {
                    id: HARDCODE.estudoFormComparacaoCenariosGrafico,
                    descricao: comparacaoCenarios + LABEL.cenarios + ': ' + LABEL.comparacaoCenarios + ' (' + LABEL.grafico + ')',
                    content: '<b>' + comparacaoCenarios + LABEL.cenarios + '</b>: ' + LABEL.comparacaoCenarios + ' (' + LABEL.grafico + ')',
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormComparacaoCenarios,
                    descricao: comparacaoCenarios + LABEL.cenarios + ': ' + LABEL.comparacaoCenarios,
                    content: '<b>' + comparacaoCenarios + LABEL.cenarios + '</b>: ' + LABEL.comparacaoCenarios,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormComparacaoEstudosGrafico,
                    descricao: comparacaoEstudos + LABEL.estudos + ': ' + LABEL.comparacaoEstudos + ' (' + LABEL.grafico + ')',
                    content: '<b>' + comparacaoEstudos + LABEL.estudos + '</b>: ' + LABEL.comparacaoEstudos + ' (' + LABEL.grafico + ')',
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormComparacaoEstudos,
                    descricao: comparacaoEstudos + LABEL.estudos + ': ' + LABEL.comparacaoEstudos,
                    content: '<b>' + comparacaoEstudos + LABEL.estudos + '</b>: ' + LABEL.comparacaoEstudos,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
            )
        }

        if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirSomenteConsorcio === 'N')) {

            if (!configuracaoPagina) {

                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormConsideracoesFinais,
                        descricao: consideracaoFinal + LABEL.conclusao + ': ' + linhaFormList.labelTabClienteFormListConsideracoesFinais,
                        content: '<b>' + consideracaoFinal + LABEL.conclusao + '</b>: ' + linhaFormList.labelTabClienteFormListConsideracoesFinais,
                        ////validaInformaMapaFinanceiro: 'N',
                        imprimir: 'S',
                    },
                )
            }
        }

        if (jsonPaginaNULL && jsonPaginaNULL.configuracaoPagina && jsonPaginaNULL.configuracaoPagina.length > 0) {

            jsonPaginaNULL.configuracaoPagina?.forEach( (linhaConfiguracaoPagina, indexConfiguracaoPagina) => {

                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormPagina + linhaConfiguracaoPagina.id,
                        //descricao: linhaConfiguracaoPagina.descricaoPagina,
                        content: '<b>' + pagina + LABEL.pagina + '</b>: ' + linhaConfiguracaoPagina.descricaoPagina,
                        ////validaInformaMapaFinanceiro: 'N',
                        especial: true,
                        imprimir: '-',
                    },
                )
            })
        }

        if (paginaAdicionalNULL && paginaAdicionalNULL.length > 0) {

            paginaAdicionalNULL?.forEach( (linhaPaginaAdicional, indexPaginaAdicional) => {

                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormPagina + linhaPaginaAdicional.id,
                        //descricao: linhaConfiguracaoPagina.descricaoPagina,
                        content: '<b>' + paginaAdicional + LABEL.paginaAdicional + '</b>: ' + linhaPaginaAdicional.descricaoPagina,
                        ////validaInformaMapaFinanceiro: 'N',
                        especial: true,
                        imprimir: '-',
                    },
                )
            })
        }
    }

    return reportListAux
}

export default reportList
import HARDCODE from '../../business-const/HardCode/HardCode'



const acumulacaoList = (linhaTipoLicencaNULL) => {

    const acumulacaoListAux = 
        [
            {
                id: HARDCODE.analisePatrimonialChart,
            },
            {
                id: HARDCODE.receitaAnualChart,
            },
            {
                id: HARDCODE.despesaAnualChart,
            },
            {
                id: HARDCODE.balancoReceitaXDespesaAnualChart,
            },
        ]

    if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === "S")) {

        acumulacaoListAux.push(
            {
                id: HARDCODE.consumoReservaAposentadoriaChart,
            },
            {
                id: HARDCODE.diagnosticoAposentadoriaChart,
            },
            {
                id: HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraEstudoChart,
            },
            {
                id: HARDCODE.estudoFormProjetoVidaSonhoTracarMeta,
            },
        )
    }
    
    return acumulacaoListAux
}
  
 export default acumulacaoList
import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'



const formPrimeiraReuniaoPadraoList = [
    {
        id: 5,
        descricao: 'Vai trabalhar até / Expectativa de Vida',
        default: false,
        grupoPergunta: '',
        type: HARDCODE.formTypeAposentadoriaExpectativaVidaClienteConjuge,
        compoeCompleto: true,
        ordem: 1,
    },
    //Foi tratado em inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient para zerar a ligação
    //das perguntas com o patrimônio quando idFormList = HARDCODE.idFormListDefault, que era até
    //então o único idFormList ligado a formPrimeiraReuniaoPadraoList
    //{
    //    id: 10,
    //    descricao: 'Qual é o valor do seu patrimônio imobilizado?',
    //    descricaoResumida: 'Patrimônio (Imobilizado)',
    //    default: true,
    //    grupoPergunta: '',
    //    type: 'ValorPerc',
    //    atualiza: HARDCODE.formListAtualizaPatrimonioImobilizado,
    //    ordem: 10,
    //},
    {
        id: 15,
        descricao: 'Possui patrimônio imobilizado?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypePatrimonioImobilizado,
        compoeCompleto: true,
        ordem: 50,
    },
    //Foi tratado em inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient para zerar a ligação
    //das perguntas com os recursos financeiros quando idFormList = HARDCODE.idFormListDefault, que era até
    //então o único idFormList ligado a formPrimeiraReuniaoPadraoList
    //{
    //    id: 20,
    //    descricao: 'Qual o valor total dos seus investimentos?',
    //    descricaoResumida: 'Recursos Financeiros',
    //    default: true,
    //    grupoPergunta: '',
    //    type: 'ValorPerc',
    //    atualiza: HARDCODE.formListAtualizaRecursosFinanceiros,
    //    ordem: 20,
    //},
    {
        id: 30,
        descricao: 'Possui recursos financeiros?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeRecursosFinanceiros,
        compoeCompleto: true,
        ordem: 70,
    },
    //Foi tratado em inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient para zerar a ligação
    //das perguntas com a receita mensal quando idFormList = HARDCODE.idFormListDefault, que era até
    //então o único idFormList ligado a formPrimeiraReuniaoPadraoList
    //{
    //    id: 40,
    //    descricao: 'Qual é a sua receita mensal?',
    //    descricaoResumida: 'Receita Anual',
    //    default: true,
    //    grupoPergunta: '',
    //    type: 'ValorValor',
    //    atualiza: HARDCODE.formListAtualizaReceitaMensal,
    //    ordem: 50,
    //},
    {
        id: 45,
        descricao: 'Possui receita mensal?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeReceitaMensal,
        compoeCompleto: true,
        ordem: 5,
    },
    //Foi tratado em inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient para zerar a ligação
    //das perguntas com o dia a dia quando idFormList = HARDCODE.idFormListDefault, que era até
    //então o único idFormList ligado a formPrimeiraReuniaoPadraoList
    //{
    //    id: 50,
    //    descricao: 'Qual é a sua despesa mensal?',
    //    descricaoResumida: 'Despesa Anual',
    //    default: true,
    //    grupoPergunta: '',
    //    type: 'ValorValor',
    //    atualiza: HARDCODE.formListAtualizaDiaDia,
    //    ordem: 40,
    //},
    {
        id: 55,
        descricao: 'Possui despesas do dia a dia?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeDiaDia,
        compoeCompleto: true,
        ordem: 10,
    },
    {
        id: 60,
        descricao: 'Possui financiamentos?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeFinanciamento,
        compoeCompleto: true,
        ordem: 30,
    },
    {
        id: 70,
        descricao: 'Possui projetos de vida/sonho?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeProjetoVidaSonho,
        compoeCompleto: true,
        ordem: 40,
    },
    {
        id: 80,
        descricao: 'Possui despesas com educação?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeEducacao,
        compoeCompleto: true,
        ordem: 20,
    },
    {
        id: 90,
        descricao: 'Qual é a sua renda mensal?',
        descricaoResumida: 'Receita Anual',
        default: false,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaReceitaMensal,
        identificaId: HARDCODE.identificaIdPerguntaRendaMensal,
        ordem: 90,
    },
    {
        id: 100,
        descricao: 'Qual é o seu gasto mensal?',
        descricaoResumida: 'Gasto Anual',
        default: false,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaDiaDia,
        identificaId: HARDCODE.identificaIdPerguntaGastoMensal,
        ordem: 100,
    },
    {
        id: 110,
        descricao: 'Qual o valor total dos seus investimentos hoje?',
        descricaoResumida: 'Recursos Financeiros',
        default: false,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaRecursosFinanceiros,
        identificaId: HARDCODE.identificaIdPerguntaRecursosFinanceiros,
        ordem: 110,
    },
    {
        id: 120,
        descricao: 'Qual o valor de todo o seu patrimônio imobilizado (casa, carro, negócio, etc)?',
        descricaoResumida: 'Patrimônio (Imobilizado)',
        default: false,
        grupoPergunta: '',
        type: 'Valor',
        atualiza: HARDCODE.formListAtualizaPatrimonioImobilizado,
        identificaId: HARDCODE.identificaIdPerguntaPatrimonioImobilizado,
        ordem: 120,
    },
    {
        id: 130,
        descricao: 'Qual a renda que você gostaria de ter na ' + LABEL.aposentadoria.toLowerCase() + '?',
        default: false,
        grupoPergunta: '',
        type: 'Valor',
        identificaId: HARDCODE.identificaIdPerguntaRendaAposentadoria,
        ordem: 130,
    },
]
 
export default formPrimeiraReuniaoPadraoList
import logoSvg from './logo.svg'

import consorcioLogoSvg from './consorcioLogo.svg'

import link1Svg from './link1.svg'

import link2Svg from './link2.svg'

import link3Svg from './link3.svg'

import link4Svg from './link4.svg'



export const SvgImageParceirosPortoSeguroLogo = ( props ) => {
    return (
        <img
            className='w-full'
            width="50px"
            height="50px"
            src={logoSvg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageParceirosPortoSeguroConsorcioLogo = ( props ) => {
    return (
        <img
            className='w-full'
            width="50px"
            height="50px"
            src={consorcioLogoSvg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageParceirosPortoSeguroLink1 = ( props ) => {
    return (
        <img
            className='w-full'
            width="200px"
            height="200px"
            src={link1Svg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageParceirosPortoSeguroLink2 = ( props ) => {
    return (
        <img
            className='w-full'
            width="200px"
            height="200px"
            src={link2Svg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageParceirosPortoSeguroLink3 = ( props ) => {
    return (
        <img
            className='w-full'
            width="200px"
            height="200px"
            src={link3Svg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageParceirosPortoSeguroLink4 = ( props ) => {
    return (
        <img
            className='w-full'
            width="200px"
            height="200px"
            src={link4Svg}
            alt={''}
            {...props}
        />
    )
}
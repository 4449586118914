const HARDCODE = {

    ////fintracksDotCom: 'fin-tracks.com',
    emailsFintracks: [
        {id: 'alexandrepureza@fin-tracks.com'}, // HARDCODEBACKEND.emailsFintracks
        {id: 'fa@fin-tracks.com'}, // HARDCODEBACKEND.emailsFintracks
        {id: 'jp@fin-tracks.com'}, // HARDCODEBACKEND.emailsFintracks
        {id: 'atendimento@fin-tracks.com'}, // HARDCODEBACKEND.emailsFintracks
        {id: 'atendimento2@fin-tracks.com'},
        {id: 'atendimento3@fin-tracks.com'},
        {id: 'atendimento4@fin-tracks.com'},
        {id: 'atendimento5@fin-tracks.com'},
        {id: 'comercial@fin-tracks.com'},
        {id: 'comercial2@fin-tracks.com'},
        {id: 'comercial3@fin-tracks.com'},
        {id: 'comercial4@fin-tracks.com'},
        {id: 'comercial5@fin-tracks.com'},
        {id: 'suporte@fin-tracks.com'},
    ],

    emailsPrototipo: [
        {id: 'alexandrepureza@fin-tracks.com'},
        {id: 'alexpureza@yahoo.com.br'},
        {id: 'alexandre.pureza@metlife.com'},
        {id: 'alexandre.pureza@xp.com'},
        //{id: 'nadeje.g.feitosa@metlife.com'},
        //{id: 'parul.gandhi1@metlife.com'},
    ],



    ipNaoIdentificado: 'IP não identificado',



    fontFamily: 'Poppins',



    rowsPerPageDefault: 6,
    pageDefault: 0,



    tipoIdiomaDefault: 'pt-br',
    tipoIdiomaPtPt: 'pt-pt',
    tipoMoedaDefault: 10,



    /*
    coresOld: {
        corPadrao1: {
            "hsl": {
                "h": 37.49999999999997,
                "s": 0.05555555555555555,
                "l": 0.2823529411764706,
                "a": 1
            },
            "hex": "#4c4944",
            "rgb": {
                "r": 76,
                "g": 73,
                "b": 68,
                "a": 1
            },
            "hsv": {
                "h": 37.49999999999997,
                "s": 0.10526315789473684,
                "v": 0.2980392156862745,
                "a": 1
            },
            "oldHue": 37.49999999999997,
            "source": "hex"
        },
        corPadrao2: {
            "hsl": {
                "h": 60,
                "s": 0.16379310344827588,
                "l": 0.4549019607843137,
                "a": 1
            },
            "hex": "#878761",
            "rgb": {
                "r": 135,
                "g": 135,
                "b": 97,
                "a": 1
            },
            "hsv": {
                "h": 60,
                "s": 0.2814814814814815,
                "v": 0.5294117647058824,
                "a": 1
            },
            "oldHue": 88.65168539325842,
            "source": "hex"
        },
        corPadrao3: {
            "hsl": {
                "h": 46.21621621621621,
                "s": 0.7474747474747475,
                "l": 0.38823529411764707,
                "a": 1
            },
            "hex": "#ad8b19",
            "rgb": {
                "r": 173,
                "g": 139,
                "b": 25,
                "a": 1
            },
            "hsv": {
                "h": 46.21621621621621,
                "s": 0.8554913294797688,
                "v": 0.6784313725490196,
                "a": 1
            },
            "oldHue": 250,
            "source": "hex"
        },
    },

    coresOld2: {
        corPadrao1: {
            "hsl": {
                "h": 158.64406779661016,
                "s": 0.7866666666666666,
                "l": 0.29411764705882354,
                "a": 1
            },
            "hex": "#10865c",
            "rgb": {
                "r": 16,
                "g": 134,
                "b": 92,
                "a": 1
            },
            "hsv": {
                "h": 158.64406779661016,
                "s": 0.8805970149253731,
                "v": 0.5254901960784314,
                "a": 1
            },
            "oldHue": 157.56521739130434,
            "source": "hex"
        },
        corPadrao2: {
            "hsl": {
                "h": 149.6,
                "s": 0.3731343283582089,
                "l": 0.6058823529411764,
                "a": 1
            },
            "hex": "#75c09a",
            "rgb": {
                "r": 117,
                "g": 192,
                "b": 154,
                "a": 1
            },
            "hsv": {
                "h": 149.6,
                "s": 0.390625,
                "v": 0.7529411764705882,
                "a": 1
            },
            "oldHue": 153.72262773722628,
            "source": "hex"
        },
        corPadrao3: {
            "hsl": {
                "h": 50.599078341013815,
                "s": 0.9644444444444445,
                "l": 0.4411764705882353,
                "a": 1
            },
            "hex": "#ddbb04",
            "rgb": {
                "r": 221,
                "g": 187,
                "b": 4,
                "a": 1
            },
            "hsv": {
                "h": 50.599078341013815,
                "s": 0.9819004524886878,
                "v": 0.8666666666666667,
                "a": 1
            },
            "oldHue": 50.54054054054054,
            "source": "hex"
        },
        corPadrao4: {
            "hsl": {
                "h": 250,
                "s": 0,
                "l": 1,
                "a": 1
            },
            "hex": "#ffffff",
            "rgb": {
                "r": 255,
                "g": 255,
                "b": 255,
                "a": 1
            },
            "hsv": {
                "h": 250,
                "s": 0,
                "v": 1,
                "a": 1
            },
            "oldHue": 250,
            "source": "hex"
        }
    },
    
    cores: {
        corPadrao1: {
            "hsl": {
                "h": 158.64406779661016,
                "s": 0.7866666666666666,
                "l": 0.29411764705882354,
                "a": 1
            },
            "hex": "#10865c",
            "rgb": {
                "r": 16,
                "g": 134,
                "b": 92,
                "a": 1
            },
            "hsv": {
                "h": 158.64406779661016,
                "s": 0.8805970149253731,
                "v": 0.5254901960784314,
                "a": 1
            },
            "oldHue": 157.56521739130434,
            "source": "hex"
        },
        corPadrao2: {
            "hsl": {
                "h": 149.6,
                "s": 0.3731343283582089,
                "l": 0.6058823529411764,
                "a": 1
            },
            "hex": "#75c09a",
            "rgb": {
                "r": 117,
                "g": 192,
                "b": 154,
                "a": 1
            },
            "hsv": {
                "h": 149.6,
                "s": 0.390625,
                "v": 0.7529411764705882,
                "a": 1
            },
            "oldHue": 153.72262773722628,
            "source": "hex"
        },
        corPadrao3: {
            "hsl": {
                "h": 50.599078341013815,
                "s": 0.9644444444444445,
                "l": 0.4411764705882353,
                "a": 1
            },
            "hex": "#ddbb04",
            "rgb": {
                "r": 221,
                "g": 187,
                "b": 4,
                "a": 1
            },
            "hsv": {
                "h": 50.599078341013815,
                "s": 0.9819004524886878,
                "v": 0.8666666666666667,
                "a": 1
            },
            "oldHue": 50.54054054054054,
            "source": "hex"
        },
        corPadrao4: {
            "hsl": {
                "h": 250,
                "s": 0,
                "l": 1,
                "a": 1
            },
            "hex": "#ffffff",
            "rgb": {
                "r": 255,
                "g": 255,
                "b": 255,
                "a": 1
            },
            "hsv": {
                "h": 250,
                "s": 0,
                "v": 1,
                "a": 1
            },
            "oldHue": 250,
            "source": "hex"
        },
        corPadrao5: {
            "hsl": {
                "h": 0,
                "s": 0,
                "l": 0,
                "a": 1
            },
            "hex": "#000000",
            "rgb": {
                "r": 0,
                "g": 0,
                "b": 0,
                "a": 1
            },
            "hsv": {
                "h": 0,
                "s": 0,
                "v": 0,
                "a": 1
            },
            "oldHue": 0,
            "source": "hex"
        }
    },
    */

    idWhiteLabelPadrao: 1,

    corPadraoBranca: {
        "hsl": {
            "h": 250,
            "s": 0,
            "l": 1,
            "a": 1
        },
        "hex": "#ffffff",
        "rgb": {
            "r": 255,
            "g": 255,
            "b": 255,
            "a": 1
        },
        "hsv": {
            "h": 250,
            "s": 0,
            "v": 1,
            "a": 1
        },
        "oldHue": 250,
        "source": "hex"
    },

    corPadrao1: 'corPadrao1',
    corPadrao2: 'corPadrao2',
    corPadrao3: 'corPadrao3',
    corPadrao4: 'corPadrao4',
    corPadrao5: 'corPadrao5',
    corPadrao6: 'corPadrao6',
    corPadrao7: 'corPadrao7',

    corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizado: 'corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizado',
    corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoBensMoveisImoveis:
        'corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoBensMoveisImoveis',
    corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoNegocios:
        'corChartPadraoAnalisePatrimonialAtivosPatrimonioImobilizadoNegocios',
    corChartPadraoAnalisePatrimonialAtivosRecursosFinanceiros: 'corChartPadraoAnalisePatrimonialAtivosRecursosFinanceiros',
    corChartPadraoAnalisePatrimonialPassivos: 'corChartPadraoAnalisePatrimonialPassivos',
    corChartPadraoAnalisePatrimonialSaldoPatrimonial: 'corChartPadraoAnalisePatrimonialSaldoPatrimonial',
    corChartPadraoAnalisePatrimonialLegendaGraficoPizza: 'corChartPadraoAnalisePatrimonialLegendaGraficoPizza',

    corChartPadraoManterReservaManterPadraoVida: 'corChartPadraoManterReservaManterPadraoVida',
    corChartPadraoConsumirReservaManterPadraoVida: 'corChartPadraoConsumirReservaManterPadraoVida',
    corChartPadraoManterReservaSemNovosAportes: 'corChartPadraoManterReservaSemNovosAportes',
    corChartPadraoConsumirReservaSemNovosAportes: 'corChartPadraoConsumirReservaSemNovosAportes',



    htmlMarcadorCircle: '&#9679',
    htmlMarcadorSquare: '&#9724',
    htmlMarcadorWhiteRightPointingTriangle: '&#9654',
    htmlEspacoRegular: '&nbsp;',
    htmlEspaco2: '&ensp;',
    htmlEspaco4: '&emsp;',
    htmlPularLinha: '<br/><br/>',
    htmlDivider: '<div style="background-color: #e9e9e9; minWidth: 100%; height: 1px; margin-top: 30px; margin-bottom: 30px"></div>',



    invalidDate: '-Invalid Date-',



    http: 'HTTP://',
    https: 'HTTPS://',



    urlSignin: '/session/signin',
    urlSignup: 'https://www.app.fin-tracks.com/session/signup',
    urlSignature: '/?signature=',
    signature: 'c644fc09c31c37d',
    urlCostumer: '/?costumer=',
    costumer: 'f789c41e6de681a',
    validadeLinkCostumerDiasDefault: 7,
    validadeLinkCostumerDiasMaximo: 30,
    urlCapitacaoLeads: '/?cl=',
    capitacaoLeads: 'f78pc46e6fe681n',
    validadeLinkCapitacaoLeadsMesesDefault: 6,
    validadeLinkCapitacaoLeadsMesesMaximo: 12,



    tipoLicencaPrevidenciaPrivada: 'PP', // HARDCODEBACKEND.tipoLicencaPrevidenciaPrivada



    statusAssinaturaPendente: "PEND", // HARDCODEBACKEND.statusAssinaturaPendente
    statusAssinaturaPagtoAtraso: "ATRA", // HARDCODEBACKEND.statusAssinaturaPagtoAtraso
    statusAssinaturaBloquearAcessoPagtoAtraso: "BLOQ", // HARDCODEBACKEND.statusAssinaturaBloquearAcessoPagtoAtraso


    
    idFormListDefault: 920, // HARDCODEBACKEND.idFormListDefault'
    perguntasObjetivosPerguntas: 'P',
    perguntasObjetivosObjetivos: 'O',
    formListAtualizaRecursosFinanceiros: 'recursosFinanceiros',
    formListAtualizaDiaDia: 'diaDia',
    formListAtualizaReceitaMensal: 'receitaMensal',
    formListAtualizaPatrimonioImobilizado: 'patrimonioImobilizado',

    identificaIdPerguntaRendaMensal: 'perguntaRendaMensal',
    identificaIdPerguntaGastoMensal: 'perguntaGastoMensal',
    identificaIdPerguntaRecursosFinanceiros: 'perguntaRecursosFinanceiros',
    identificaIdPerguntaPatrimonioImobilizado: 'perguntaPatrimonioImobilizado',
    identificaIdPerguntaRendaAposentadoria: 'perguntaRendaAposentadoria',



    freeTrack: 21,//30, // HARDCODEBACKEND.freeTrack
    idModuloBase: 10,



    idSexoMasculino: 'M',
    idSexoFeminino: 'F',

    ufExterior: 'EX', // HARDCODEBACKEND.ufExterior



    perfilAdministrador: "A", // HARDCODEBACKEND.perfilAdministrador
    perfilGestor: "G", // HARDCODEBACKEND.perfilGestor
    perfilLider: "L", // HARDCODEBACKEND.perfilLider
    perfilBackoffice: "B", // HARDCODEBACKEND.perfilBackoffice
    perfilPlanejador: "T",
    //perfilAssessor: "S",

    formListPrimeiraReuniao: "P",
    formListAprofundamento: "A",

    escopoParceiro: "P",



    statusDataHoraPrevistaFunilEtapaAtrasado: 'A',
    statusDataHoraPrevistaFunilEtapaHoje: 'H',
    statusDataHoraPrevistaFunilEtapaFuturo: 'F',



    diasAntecedenciaEnvioLembreteAporteDefault: 2,
    assuntoEmailEnvioLembreteAporteDefault: 'Lembrete de aporte para realização dos objetivos planejados',
    textoEmailEnvioLembreteAporteDefault: 'Caro <b>$nomeCurtoCliente$</b>,<br/><br/>Gostaria de lembrar sobre os aportes que planejamos para a realização dos seus objetivos:<br/><br/>$textoDescricaoAporte$<br/><br/>Um abraço,<br/><br/>$nomeCurtoUsuario$<br/>$celularUsuario$',
    textoDescricaoAporteEnvioLembreteAporte: '$descricaoTipoAporte$ - $nomeCurtoFamiliar$ - $valorAporte$ - $dataPrevistaAporte$ <br/>',



    idTipoItemPaginaTexto: 'TEXT',
    idTipoItemPaginaHTML: 'HTML',
    idTipoItemPaginaGraficoTabela: 'GRTB',



    idTipoGestaoPatrimonialVisao: 'VISAO',
    idTipoGestaoPatrimonialEstrategia: 'ESTRAT',
    idTipoGestaoPatrimonialPlanejamento: 'PLANEJ',



    ultimaVersaoInicial: 1,
    jsonComparacaoEstudosInicial: '{"estudos": []}',
    diasCriarNovaVersao: -30,


    idPessoaFisica: 'F',
    idPessoaJuridica: 'J',
    


    idFamiliarCliente: 1, // HARDCODEBACKEND.idFamiliarCliente

    idFamiliarConjuge: 2, // HARDCODEBACKEND.idFamiliarConjuge

    idFamiliarFamilia: 900, // HARDCODEBACKEND.idFamiliarFamilia

    idFamiliarCasal: 950,

    idFamiliarPage: 1000,



    percentual100: 100,



    idGrupoArquivoConfiguracao: 10,
    idGrupoArquivoCliente: 20,

    idTipoArquivoInstitucional: 10,
    idTipoArquivoProposta: 120,
    idTipoArquivoPropostaHorizonte: 121,
    idTipoArquivoPropostaEssencial: 122,
    idTipoArquivoListaPropostas: 123,
    idTipoArquivoListaPropostasImplantacao: 124,
    idTipoArquivoListaPropostasNovoLayout: 125,
    idTipoArquivoPropostaNovoLayout: 126,
    idTipoArquivoApolice: 130,
    idTipoArquivoApolicePrivateSolutions: 131,
    idTipoArquivoApoliceVidaToda: 132,
    idTipoArquivoApoliceSiteCliente: 133,
    idTipoArquivoApoliceHorizonte: 134,
    idTipoArquivoApoliceEssencial: 135,
    idTipoArquivoApoliceVidaInteira: 136,
    idTipoArquivoApoliceMultiPlanoVivaMais: 137,
    //idTipoArquivoApoliceNovoLayout: 138,
    idTipoArquivoListaApolices: 140,
    idTipoArquivoListaApolicesImplantacao: 145,
    idTipoArquivoListaApolicesImplantacaoConsultorFinanceiro: 146,
    idTipoArquivoListaApolicesImplantacaoLP: 147,
    idTipoArquivoListaApolicesImplantacaoSemAgente: 148,
    idTipoArquivoListaApolicesPlataforma: 150,
    idTipoArquivoListaApolicesCorretorSeguros: 155,
    idTipoArquivoListaCobrancas: 200,
    idTipoArquivoListaCobrancasPlataforma: 210,
    idTipoArquivoListaCobrancasCorretorSeguros: 215,
    idTipoArquivoListaComissoes: 300,
    idTipoArquivoPosicaoInvestimentos: 600,
    idTipoArquivoListaTransferenciaClientes: 700,
    idTipoArquivoListaClientesAgentes: 750,
    idTipoArquivoConsorcio: 800,
    idTipoArquivoNaoReconhecido: 900,

    tipoAcessoArquivoProposta: 'P',
    tipoAcessoArquivoApolice: 'A',
    tipoAcessoArquivoPosicaoInvestimentos: 'I',
    tipoAcessoArquivoConsorcio: 'S',

    tipoAtualizacaoCliente: 'C',
    tipoAtualizacaoProposta: 'P',
    tipoAtualizacaoCobranca: 'B',
    tipoAtualizacaoComissao: 'S',
    tipoAtualizacaoTransferenciaCliente: 'T',
    tipoAtualizacaoClienteAgente: 'A',

    idStatusProcessamentoNaoSeAplica: 'N', // HARDCODEBACKEND.idStatusProcessamentoNaoSeAplica
    idStatusProcessamentoPendenteProcessamento: 'P', // HARDCODEBACKEND.idStatusProcessamentoPendenteProcessamento
    idStatusProcessamentoLayoutNaoReconhecido: 'L',
    idStatusProcessamentoDadosImportados: 'I', // HARDCODEBACKEND.idStatusProcessamentoDadosImportados
    idStatusProcessamentoProcessando: 'S', // HARDCODEBACKEND.idStatusProcessamentoProcessando

    importacaoLinhaDados: 'linhaDados',
    importacaoCodigoAgente: 'codigoAgente',
    importacaoNumeroCotacao: 'numeroCotacao',
    importacaoNumeroProposta: 'numeroProposta',
    importacaoNumeroApolice: 'numeroApolice',
    importacaoUrlApolice: 'urlApolice',
    importacaoDataAssinatura: 'dataAssinatura',
    importacaoDataUltimaAlteracao: 'dataUltimaAlteracao',
    importacaoDataEmissao: 'dataEmissao',
    importacaoDataCancelamento: 'dataCancelamento',
    importacaoMotivoCancelamento: 'motivoCancelamento',
    importacaoCpfSeguradoCliente: 'cpfSeguradoCliente',
    importacaoNomeSeguradoCliente: 'nomeSeguradoCliente',
    importacaoNascimentoSeguradoCliente: 'nascimentoSeguradoCliente',
    importacaoSexoSeguradoCliente: 'sexoSeguradoCliente',
    importacaoProfissaoSegurado: 'profissaoSegurado',
    importacaoEnderecoSeguradoCliente: 'enderecoSeguradoCliente',
    importacaoNumeroEnderecoSeguradoCliente: 'numeroEnderecoSeguradoCliente',
    importacaoComplementoEnderecoSeguradoCliente: 'complementoEnderecoSeguradoCliente',
    importacaoBairroSeguradoCliente: 'bairroSeguradoCliente',
    importacaoCidadeSeguradoCliente: 'cidadeSeguradoCliente',
    importacaoUfSeguradoCliente: 'ufSeguradoCliente',
    importacaoCepSeguradoCliente: 'cepSeguradoCliente',
    importacaoTelefoneSegurado: 'telefoneSegurado',
    importacaoTelefoneComercialSegurado: 'telefoneComercialSegurado',
    importacaoCelularSeguradoCliente: 'celularSeguradoCliente',
    importacaoEmailSeguradoCliente: 'emailSeguradoCliente',
    importacaoCpfCnpjRespPagto: 'cpfRespPagto',
    importacaoNomeRespPagto: 'nomeRespPagto',
    importacaoSexoRespPagto: 'sexoRespPagto',
    importacaoRendaMensal: 'rendaMensal',
    importacaoStatusProposta: 'statusProposta',
    importacaoStatusApolice: 'statusApolice',
    importacaoObservacao: 'observacao',
    importacaoPropostaCoberturas: 'propostaCoberturas',
    importacaoCoberturas: 'coberturas',
    importacaoDescricaoCobertura: 'descricaoCobertura',
    importacaoValorCS: 'valorCS',
    importacaoValorPremioLiquido: 'valorPremioLiquido',
    importacaoValorIof: 'valorIof',
    importacaoValorPremio: 'valorPremio',
    importacaoValorPremioAnualizado: 'valorPremioAnualizado',
    importacaoDataTerminoCoberturaString: 'dataTerminoCoberturaString',
    importacaoPrazoCobertura: 'prazoCobertura',
    importacaoClasseAjusteRisco: 'classeAjusteRisco',
    importacaoStatusCobertura: 'statusCobertura',
    importacaoTipoFormaPagtoPremio: 'tipoFormaPagtoPremio',
    importacaoTipoFormaPagtoPremioConversao: 'tipoFormaPagtoPremioConversao',
    importacaoBanco: 'banco',
    importacaoAgencia: 'agencia',
    importacaoConta: 'conta',
    importacaoDiaVencimento: 'diaVencimento',
    importacaoNumeroParcela: 'numeroParcela',
    importacaoStatusParcela: 'statusParcela',
    importacaoTipoBaixaParcela: 'tipoBaixaParcela',
    importacaoDataVencimento: 'dataVencimento',
    importacaoDataCompetencia: 'dataCompetencia',
    importacaoDataInicioVigencia: 'dataInicioVigencia',
    importacaoDataFimVigencia: 'dataFimVigencia',
    importacaoDataPagamento: 'dataPagamento',
    importacaoDataCorte: 'dataCorte',
    importacaoValorAPagar: 'valorAPagar',
    importacaoValorPago: 'valorPago',
    importacaoTipoPeriodicidadePagtoPremio: 'tipoPeriodicidadePagtoPremio',
    importacaoTipoPeriodicidadePagtoPremioInteiro: 'tipoPeriodicidadePagtoPremioInteiro',
    importacaoTipoPeriodicidadePagtoPremioValor: 'tipoPeriodicidadePagtoPremioValor',
    importacaoDataGeracaoComissao: 'dataGeracaoComissao',
    importacaoValorBaseComissao: 'valorBaseCalculoComissao',
    importacaoPercComissao: 'percComissao',
    importacaoValorComissao: 'valorComissao',
    importacaoPercComissaoCoCorretagem: 'percComissaoCoCorretagem',
    importacaoValorComissaoCoCorretagem: 'valorComissaoCoCorretagem',
    importacaoValorComissaoTotal: 'valorComissaoTotal',
    importacaoCodigoCobertura: 'codigoCobertura',
    importacaoDataCotacao: 'dataCotacao',
    importacaoDataCotacaoExtenso: 'dataCotacaoExtenso',
    importacaoIdadeCotacao: 'idadeCotacao',
    importacaoTabelaEvolucaoValorResgate: 'tabelaEvolucaoValorResgate',

    importacaoInstituicaoFinanceira: 'instituicaoFinanceira',
    importacaoNumeroConta: 'numeroConta',
    importacaoTaxaCDI12Meses: 'taxaCDI12Meses',
    importacaoPosicaoInvestimentos: 'posicaoInvestimentos',

    idStatusApoliceAtiva: 'A',
    idStatusApoliceCancelada: 'C',
    idStatusApoliceRejeitada: 'R',
    idStatusApoliceConvertida: 'O',
    idStatusApoliceSubstituida: 'S',
    idStatusApoliceAnulada: 'N',
    idStatusApoliceTerminada: 'T',
    idStatusApoliceSinistrada: 'I',
    
    idStatusPropostaPendente: 'P', // view fintrackssch.vw_cliente_acesso_proposta
    idStatusPropostaEmAnalise: 'A', // view fintrackssch.vw_cliente_acesso_proposta 
    idStatusPropostaImplantada: 'I', // view fintrackssch.vw_cliente_acesso_proposta
    idStatusPropostaPaga: 'O', // view fintrackssch.vw_cliente_acesso_proposta
    idStatusPropostaCancelada: 'C',
    idStatusPropostaRecusada: 'R',
    idStatusPropostaNaoInformado: 'N', // view fintrackssch.vw_cliente_acesso_proposta

    idStatusCoberturaAtivaPagandoPremio: 'AP',
    idStatusCoberturaAtivaPremioPago: 'AS',
    idStatusCoberturaAtivaPremioDispensado: 'PD',
    idStatusCoberturaExpirada: 'EX',
    idStatusCoberturaConvertida: 'CV',
    idStatusCoberturaResgatada: 'RE',
    idStatusCoberturaSaldada: 'SD',
    idStatusCoberturaCancelada: 'CA',
    idStatusCoberturaSinistroEmAnalise: 'SA',
    idStatusCoberturaBeneficioPago: 'BP',
    idStatusCoberturaRejeitada: 'RJ',
    idStatusCoberturaSubstituida: 'SU',
    idStatusCoberturaAnulada: 'AN',

    idStatusCoberturaPropostaPendente: 'PE',
    idStatusCoberturaPropostaEmitida: 'EM',
    idStatusCoberturaPropostaRejeitada: 'RE',

    tipoValorAgrupamentoApoliceCobrancaTotal: 'T',
    tipoValorAgrupamentoApoliceCobrancaAberta: 'A',
    tipoValorAgrupamentoApoliceCobrancaCancelada: 'C',
    tipoValorAgrupamentoApoliceCobrancaPaga: 'P',



    importacaoNumeroConsorcio: 'numeroConsorcio',
    importacaoValorCreditoConsorcio: 'valorCreditoConsorcio',
    importacaoGrupoConsorcio: 'grupoConsorcio',
    importacaoCotaConsorcio: 'cotaConsorcio',
    importacaoPessoaFisicaJuridica: 'pessoaFisicaJuridica',
    importacaoStatusGrupoConsorcio: 'statusGrupoConsorcio',
    importacaoDataAssembleia: 'dataAssembleia',
    importacaoNumeroAssembleiaParticipacao: 'numeroAssembleiaParticipacao',
    importacaoValorParcelaInicial: 'valorParcelaInicial',
    importacaoTipoFormaPagtoConsorcio: 'tipoFormaPagtoConsorcio',
    importacaoPrazoTotalGrupoConsorcio: 'prazoTotalGrupoConsorcio',
    importacaoNumeroTotalParticipantesGrupoConsorcio: 'numeroTotalParticipantesGrupoConsorcio',
    importacaoTaxaAdministracaoTotal: 'taxaAdministracaoTotal',
    importacaoPercFundoReserva: 'percFundoReserva',
    importacaoPercSeguroPrestamista: 'percSeguroPrestamista',
    importacaoPercLanceEmbutido: 'percLanceEmbutido',
    importacaoPercLanceFixo: 'percLanceFixo',
    importacaoDataVenctoBoleto: 'dataVenctoBoleto',



    regExpFormataCep: /[.-]/g,
    regExpFormataCpf: /[.-]/g,
    regExpFormataCpfCnpj: /[/.-]/g,
    regExpFormataTelefone: /[+() -/\D]/g,
    regExpSomenteNumero: /^[0-9]+$/,
    regExpValidaValor: /^[0-9.]+$/,
    regExpValidaPercentual: /^[0-9.]/,
    regExpValidaSenha: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+_]).{8,}$/,
    regExpValidaCelular: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
    regExpValidaEmail: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

    typeData: 'Data', // HARDCODEBACKEND.typeData
    typeValor: 'Valor', // HARDCODEBACKEND.typeValor
    typePercentual: 'Percentual', // HARDCODEBACKEND.typePercentual
    typeInteiro: 'Inteiro', // HARDCODEBACKEND.typeInteiro
    typeString: 'String', // HARDCODEBACKEND.typeString
    typeStringNotUpperCase: 'StringNotUpperCase', // HARDCODEBACKEND.typeStringNotUpperCase
    typeStringLike: 'StringLike', // HARDCODEBACKEND.typeStringLike
    typeDiaMes: 'DiaMes', // HARDCODEBACKEND.typeDiaMes
    typeCpf: 'Cpf', // HARDCODEBACKEND.typeCpf
    typeCpfCnpj: 'CpfCnpj',
    typeTelefone: 'Telefone',
    typeCelular: 'Celular', // HARDCODEBACKEND.typeCelular
    typeEmail: 'Email', // HARDCODEBACKEND.typeEmail
    typeCep: 'Cep',
    typeList: 'List',
    typeListString: 'ListString', // HARDCODEBACKEND.typeListString
    typeListMultiSelectInteiro: 'ListMultiSelectInteiro', // HARDCODEBACKEND.typeListMultiSelectInteiro
    typeListMultiSelectString: 'ListMultiSelectString', // HARDCODEBACKEND.typeListMultiSelectString

    XLSX: 'XLSX',
    PDF: 'PDF',
    XLSXApplicationCompleto: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
    PDFApplication: 'application/pdf',
    PDFApplicationCompleto: 'data:application/pdf;base64,',



    formTypeAposentadoriaExpectativaVidaClienteConjuge: 'AposentadoriaExpectativaVidaClienteConjuge',
    formTypeReceitaMensal: 'ReceitaMensal',
    formTypeDiaDia: 'DiaDia',
    formTypeEducacao: 'Educacao',
    formTypeFinanciamento: 'Financiamento',
    formTypeProjetoVidaSonho: 'ProjetoVidaSonho',
    formTypePatrimonioImobilizado: 'PatrimonioImobilizado',
    formTypeRecursosFinanceiros: 'RecursosFinanceiros',



    diasAniversarioProximoAlerta: 30,
    diasAniversarioProximoLista: 30,



    diasDiariaCoberturaSeguroVida: 30,
    
    idadeVaiAposentarHomem: 65,

    idadeVaiAposentarMulher: 60,



    idTipoOrigemClienteIntegracao: 910,
    idTipoOrigemClienteIntegracaoMarketingDigital: 920,
    idTipoOrigemClienteNaoInformado: 999, // HARDCODEBACKEND.idTipoOrigemClienteNaoInformado
    
    profissaoOutras: 'Outras',

    idTipoContatoEmail: 'E',
    idTipoContatoCelular: 'C',
    idTipoContatoCelularComercial: 'M',
    idTipoContatoTelefoneFixo: 'F',
    idTipoContatoTelefoneComercial: 'L',
    idTipoContatoOutros: 'O',

    idParentescoFilhao: 10, // HARDCODEBACKEND.idParentescoFilhao
    idParentescoEnteadao: 15, // HARDCODEBACKEND.idParentescoEnteadao

    idTipoEnderecoResidencial: "R", // HARDCODEBACKEND.idTipoEnderecoResidencial

    idTipoFumoOutros: 900,

    idTipoEsporteHobbyRiscoOutros: 900,


    
    idTipoPeriodicidadeMensal: 'M',
    idTipoPeriodicidadeAnual: 'A',
    idTipoPeriodicidadeSemestral: 'S',



    idTipoReceitaMensalProfissionalLiberal: 10,
    idTipoReceitaMensalCLT: 20,
    idTipoReceitaMensalRendaMensal: 65,
    idTipoReceitaMensalAluguel: 70,
    idTipoReceitaMensalInssRendaInvalidez: 100,
    idTipoReceitaMensalInssRendaMorte: 110,
    idTipoReceitaMensalInssRendaAposentadoria: 120,
    idTipoReceitaMensalPensaoRendaInvalidez: 140,
    idTipoReceitaMensalPensaoRendaMorte: 150,
    idTipoReceitaMensalPensaoRendaAposentadoria: 160,
    idTipoReceitaMensalOutrasAteAposentadoria: 900,

    percFgts: 8,

    tipoReceitaMensalListDefault: '',

    idTipoDiaDiaOutros: 900,

    idTipoFormaPagtoCartaoCredito: 'C',
    idTipoFormaPagtoDebitoDinheiro: 'D',

    idTipoFormaPagtoPremioCartaoCredito: 'C',
    idTipoFormaPagtoPremioDebito: 'D',
    idTipoFormaPagtoPremioBoleto: 'B',
    idTipoFormaPagtoPremioPix: 'P',
    idTipoFormaPagtoPremioSinglePremium: 'S',
    idTipoFormaPagtoPremioNaoInformado: 'N', // HARDCODEBACKEND.idTipoFormaPagtoPremioNaoInformado

    idGrupoDespesaOutras: 900,

    idTipoConsorcioBensImoveis: 'I',
    idTipoConsorcioBensMoveis: 'M ',

    idFormaAbaterLanceReduzirParcela: 'RPC',
    idFormaAbaterLanceReduzirPrazo: 'RPZ',


    idTipoPatrimonioImobilizadoOutros: 900,
    tipoAgrupamentoPatrimonioImobilizadoBensMoveisImoveis: 'BMI',
    tipoAgrupamentoPatrimonioImobilizadoNegocios: 'NEG',

    //percMeacaoDefault: 50,
    percPatrimonioImobilizadoClienteDefault: 50,
    percRecursosFinanceirosClienteDefault: 50,
    

    idTipoRecursoFinanceiroInternacional: 30,
    idTipoRecursoFinanceiroFgtsSaldo: 70,
    idTipoRecursoFinanceiroFgtsContribuicao: 80,
    idTipoRecursoFinanceiroInflacao: 93,
    idTipoRecursoFinanceiroMultimercado: 103,
    idTipoRecursoFinanceiroPosFixado: 106,
    idTipoRecursoFinanceiroPreFixado: 115,
    idTipoRecursoFinanceiroPrevidenciaPGBL: 120,
    idTipoRecursoFinanceiroPrevidenciaVGBL: 130,
    idTipoRecursoFinanceiroPrevidenciaPGBLEmpresarial: 140,
    idTipoRecursoFinanceiroPrevidenciaVGBLEmpresarial: 145,
    idTipoRecursoFinanceiroRendaVariavel: 152,
    idTipoRecursoFinanceiroSeguroVidaSobrevivencia: 155,
    idTipoRecursoFinanceiroOutros: 900,
    idTipoRecursoFinanceiroEconomiaReceitaMensal: 920,
    idTipoRecursoFinanceiroFaltaBalancoNegativoReceitaDespesaDiaDia: 930,
    idTipoRecursoFinanceiroAporteAtingirMetaInicioAposentadoria: 940,
    idTipoRecursoFinanceiroAporteResgateVidaInteira: 950,

    //inventarioPrevidenciaPrivada: 'PrevidenciaPrivada',



    idInstituicaoFinanceiraCNP: 73,
    idInstituicaoFinanceiraEmbracon: 74,
    idInstituicaoFinanceiraItau: 100,
    idInstituicaoFinanceiraMAPFRE: 140,
    idInstituicaoFinanceiraPortoSeguro: 190,
    idInstituicaoFinanceiraXP: 275,
    idSeguradoraOutras: 900,

    idGrupoCoberturaSeguroVidaMorte: 0,
    idGrupoCoberturaSeguroVidaFuneral: 5,
    
    idTipoCoberturaSeguroVidaAcidental: 10,
    idTipoCoberturaSeguroVidaDotal: 20,
    idTipoCoberturaSeguroVidaDotalMisto: 25,
    idTipoCoberturaSeguroVidaRendaFamiliar: 45,
    idTipoCoberturaSeguroVidaTemporario: 50,
    idTipoCoberturaSeguroVidaTemporarioDecrescente: 60,
    idTipoCoberturaSeguroVidaTradicional: 65,
    idTipoCoberturaSeguroVidaVidaInteira: 70,
    idTipoCoberturaSeguroVidaVidaInteiraDecrescente: 71,
    idTipoCoberturaSeguroVidaValorSaldado: 73,
    idTipoCoberturaSeguroVidaBeneficioProlongado: 76,
    idTipoCoberturaSeguroVidaEmGrupoMorte: 80,
    idTipoCoberturaSeguroVidaEmGrupoMorteAcidental: 90,
    idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial: 100,
    idTipoCoberturaSeguroVidaInvalidezPorAcidenteParcial: 105,
    idTipoCoberturaSeguroVidaInvalidezPorAcidenteRenda: 110,
    idTipoCoberturaSeguroVidaEmGrupoInvalidezPorAcidente: 120,
    idTipoCoberturaSeguroVidaInvalidezFuncionalPorDoenca: 130,
    idTipoCoberturaSeguroVidaInvalidezPermanentePorDoenca: 135,
    idTipoCoberturaSeguroVidaEmGrupoInvalidezFuncionalPorDoenca: 140, 
    idTipoCoberturaSeguroVidaDoencasGraves: 150,
    idTipoCoberturaSeguroVidaCirurgia: 153,
    idTipoCoberturaSeguroVidaQuebraOssos: 156,
    idTipoCoberturaSeguroVidaDIH: 160,
    idTipoCoberturaSeguroVidaAdicionalUTI: 162,
    idTipoCoberturaSeguroVidaFeridasAcidentais: 164,
    idTipoCoberturaSeguroVidaReembolsoAmbulancia: 166,
    idTipoCoberturaSeguroVidaReembolsoEmergencia: 168,
    idTipoCoberturaSeguroVidaDIT: 170,
    idTipoCoberturaSeguroVidaDITA: 180,
    idTipoCoberturaSeguroVidaSERIT: 190,
    idTipoCoberturaSeguroVidaAssistenciaFuneral: 200,
    idTipoCoberturaSeguroVidaAssistEmGrupoFuneral: 210,
    idTipoCoberturaSeguroVidaAssistenciaAutoMoto: 310,
    idTipoCoberturaSeguroVidaAssistenciaResidencial: 320,
    idTipoCoberturaSeguroVidaAssistenciaDescontoMedicamentos: 330,
    idTipoCoberturaSeguroVidaAssistenciaTelessaude: 340,

    anosRecebtoRendaVitalicio: 999,



    aporteProjetoVidaSonho: 'PVS', // HARDCODEBACKEND.aporteProjetoVidaSonho
    aporteRecursosFinanceiros: 'RFI', // HARDCODEBACKEND.aporteRecursosFinanceiros

    idStatusAportePendente: 'P', // HARDCODEBACKEND.idStatusAportePendente
    idStatusAporteRealizado: 'R', // HARDCODEBACKEND.idStatusAporteRealizado
    idStatusAporteNaoRealizado: 'N', // HARDCODEBACKEND.idStatusAporteNaoRealizado



    VGBLDeclaracaoSimplificada: 'VGBLDeclaracaoSimplificada',
    PGBLVGBLDeclaracaoCompleta: 'PGBLVGBLDeclaracaoCompleta',



    idStatusIntegracaoProcessamentoPendente: 'P', // HARDCODEBACKEND.idStatusIntegracaoProcessamentoPendente
    idStatusIntegracaoIncluidoAtualizado: 'I', // HARDCODEBACKEND.idStatusIntegracaoIncluidoAtualizado
    idStatusIntegracaoCpfNaoEncontrado: 'E', // HARDCODEBACKEND.idStatusIntegracaoCpfNaoEncontrado
    idStatusIntegracaoCpfDuplicado: 'D', // HARDCODEBACKEND.idStatusIntegracaoCpfDuplicado
    //idStatusIntegracaoFamiliarNaoClienteNemConjuge: 'C', // HARDCODEBACKEND.idStatusIntegracaoFamiliarNaoClienteNemConjuge
    idStatusIntegracaoNaoVeioNaIntegracao: 'N', // HARDCODEBACKEND.idStatusIntegracaoNaoVeioNaIntegracao
    idStatusIntegracaoOcorreuErroAtualizacaoCliente: 'R', // HARDCODEBACKEND.idStatusIntegracaoOcorreuErroAtualizacaoCliente
	
	tipoSumVwAportePrevisto: "Previsto", // HARDCODEBACKEND.tipoSumVwAportePrevisto
	tipoSumVwAporteRealizado: "Realizado", // HARDCODEBACKEND.tipoSumVwAporteRealizado



    taxaCDIAnualDefault: 0,
    retornoCDIDefault: 0,
    taxaJurosAnualDefault: 0,
    taxaImpostosDefault: 0,
    taxaInflacaoAnualDefault: 0,
    taxaJurosAnualFgtsDefault: 3,



    tipoRetornoFinanceiroNominal: 'N',
    tipoRetornoFinanceiroReal: 'R',



    percInventarioDespesasJudiciaisDefault: 6,

    percInventarioCustasJudiciaisDefault: 2,

    percInventarioItcmdMaximo: 8,

    idMesesEmergencialDefault: 1,



    tempoCoberturaDITDITA: 'Mensal',
    mesesSugestaoDoencasGraves: 24,
    idTipoCalculoSugestaoDoencasGravesDespesa: 'D',
    idTipoCalculoSugestaoDoencasGravesReceita: 'R',
    valorSugestaoFuneral: 10000,
    tempoCoberturaFuneral: 1,



    indexFluxoCaixaReceitaMensal: 0,
    indexFluxoCaixaDiaDia: 1,
    indexFluxoCaixaEducacao: 2,
    indexFluxoCaixaFinanciamento: 3,
    indexFluxoCaixaProjetoVidaSonho: 4,

    indexPatrimonioPatrimonioImobilizado: 0,
    indexPatrimonioRecursosFinanceiros: 1,
    indexPatrimonioSeguroVida: 2,

    indexAcompanhamentoSeguroVidaCotacao: 0,
    indexAcompanhamentoSeguroVidaProposta: 1,
    indexAcompanhamentoSeguroVidaApolice: 2,


    tipoPanelDespesasTotais: 'DespesasTotais',
    tipoPanelGarantiasAtuais: 'GarantiasAtuais',



    indexEstudoDiaDia: 0,
    indexEstudoEducacao: 1,
    indexEstudoFinanciamento: 2,
    indexEstudoDiaDiaDespesasTemporarias: 3,
    indexEstudoProjetoVidaSonho: 4,
    indexEstudoTotalDespesas: 5,

    indexEstudoPatrimonioImobilizado: 0,
    indexEstudoReceitaMensal: 1,
    indexEstudoRecursosFinanceiros: 2,
    indexEstudoSeguroVida: 3,
    indexEstudoTotalGarantias: 4,



    origemCalculoEstudoEstudo: 'Estudo',
    origemCalculoEstudoEstudoReport: 'EstudoReport',
    origemCalculoEstudoPrimeiraReuniaoPlanilha: '1ª Reunião - Gráfico',
    origemCalculoEstudoPrimeiraReuniaoReport: '1ª Reunião - Relatório',
    origemCalculoEstudoPlanejamentoEspecializacaoPeriodoSabatico: 'Planejamento Especialização / Período Sabático',

    tamanhoMaximoReportCapaNomeCurtoClienteEConjuge: 10,



    notaIdeal: 5,



    tipoMomentoTributacaoIRIsento: 'I',
    tipoMomentoTributacaoIRComeCotas: 'C',
    tipoMomentoTributacaoIRPGBL: 'P',
    tipoMomentoTributacaoIRVGBL: 'V',
    tipoMomentoTributacaoIRResgate: 'R',



    reportNivelDados: 'D',
    reportNivelDadosTotal: 'T',
    reportLinhasSemGraficoRetrato: 24,//28,
    reportLinhasSemGraficoPaisagem: 19,
    reportLinhasSemGraficoSemTotais: 27,//33,
    reportLinhasSemGraficoSemTotaisComRodape: 31,
    reportLinhasSemGraficoSemTotaisComRodapeLetraMaior: 27,
    reportLinhasComGraficoRetrato: 18,
    reportLinhasComGraficoPaisagem: 9,
    reportLinhasComGraficoLetraMaior: 16,//18,//21,
    reportLinhasComGraficoMenor: 18,//21,
    reportLinhasSemSaltoPagina: 10000,

    disposicaoRelatorioRetrato: 'R',
    disposicaoRelatorioPaisagem: 'P',

    idFormatoReportApresentacao: 'A',
    idFormatoReportImpressao: 'I',



    estudoFormCapa: 'EstudoFormCapa',
    estudoFormOQueEsperarDesseTrabalho: 'EstudoFormOQueEsperarDesseTrabalho',
    estudoFormObjetivos: 'EstudoFormObjetivos',
    estudoReportConferenciaDados: 'EstudoReportConferenciaDados',
    estudoReportConferenciaDadosClienteConjugeFamiliares: 'EstudoReportConferenciaDadosClienteConjugeFamiliares',
    estudoReportConferenciaDadosClienteConjugeFamiliaresObjetivos: 'EstudoReportConferenciaDadosClienteConjugeFamiliaresObjetivos',
    estudoReportConferenciaDadosClienteConjugeFamiliaresTaxas: 'EstudoReportConferenciaDadosClienteConjugeFamiliaresTaxas',
    estudoFormPlanejamentoEspecializacaoPeriodoSabaticoResumo: 'EstudoFormPlanejamentoEspecializacaoPeriodoSabaticoResumo',
    gestaoPatrimonialFormEstruturaFamiliar: 'GestaoPatrimonialFormEstruturaFamiliar',
    carteiraInvestimentosChart: 'CarteiraInvestimentosChart',
    estudoFormTabelaEvolucaoValorResgate: 'EstudoFormTabelaEvolucaoValorResgate',
    estudoFormTabelaEvolucaoValorResgateComparacaoPreco: 'EstudoFormTabelaEvolucaoValorResgateComparacaoPreco',
    analisePatrimonialChart: 'AnalisePatrimonialChart',
    receitaAnualChart: 'ReceitaAnualChart',
    despesaAnualChart: 'DespesaAnualChart',
    balancoReceitaXDespesaAnualChart: 'BalancoReceitaXDespesaAnualChart',
    consumoReservaAposentadoriaChart: 'ConsumoReservaAposentadoriaChart',
    diagnosticoAposentadoriaChart: 'DiagnosticoAposentadoriaChart',
    diagnosticoAposentadoriaIndependenciaFinanceiraChart: 'DiagnosticoAposentadoriaIndependenciaFinanceiraChart',
    diagnosticoAposentadoriaIndependenciaFinanceiraEstudoChart: 'DiagnosticoAposentadoriaIndependenciaFinanceiraEstudoChart',
    estudoFormEvolucaoReservaFinanceiraEstudo: 'EstudoFormEvolucaoReservaFinanceiraEstudo',
    estudoFormProjetoVidaSonhoTracarMeta: 'EstudoFormProjetoVidaSonhoTracarMeta',
    previdenciaPrivadaFormPrevidenciaPrivada: 'PrevidenciaPrivadaFormPrevidenciaPrivada',
    estudoFormComparacaoFinanciamentos: 'EstudoFormComparacaoFinanciamentos',
    consorcioFormSimulacao: 'ConsorcioFormSimulacao',
    estudoFormProtecaoSugerida: 'EstudoFormProtecaoSugerida',
    estudoFormSucessaoVitalicia: 'EstudoFormSucessaoVitalicia',
    estudoFormComposicaoInventario: 'EstudoFormComposicaoInventario',
    projecaoSucessaoVitaliciaChart: 'ProjecaoSucessaoVitaliciaChart',
    despesasAcumuladasChart: 'DespesasAcumuladasChart',
    estudoReportResumoNecessidadeProtecao: 'EstudoReportResumoNecessidadeProtecao',
    estudoFormDespesasDiaDiaDespesasVitalicias: 'EstudoFormDespesasDiaDiaDespesasVitalicias',
    estudoFormDespesasEducacao: 'EstudoFormDespesasEducacao',
    estudoFormDespesasFinanciamento: 'EstudoFormDespesasFinanciamento',
    estudoFormDespesasDiaDiaDespesasTemporarias: 'EstudoFormDespesasDiaDiaDespesasTemporarias',
    estudoFormProjetoVidaSonho: 'EstudoFormProjetoVidaSonho',
    estudoFormGarantiasPatrimonioImobilizado: 'EstudoFormGarantiasPatrimonioImobilizado',
    estudoFormGarantiasReceitaMensal: 'EstudoFormGarantiasReceitaMensal',
    estudoFormGarantiasRecursosFinanceiros: 'EstudoFormGarantiasRecursosFinanceiros',
    estudoFormSeguroVida: 'EstudoFormSeguroVida',
    diagnosticoAposentadoriaIndependenciaFinanceiraPrimeiraReuniaoChart:
        'diagnosticoAposentadoriaIndependenciaFinanceiraPrimeiraReuniaoChart',
    simulacaoFinanceiraFormEvolucaoReservaFinanceira: 'SimulacaoFinanceiraFormEvolucaoReservaFinanceira',
    mapaFinanceiroFormGrafico: 'MapaFinanceiroFormGrafico',
    estudoFormComparacaoCenariosGrafico: 'EstudoFormComparacaoCenariosGrafico',
    estudoFormComparacaoCenarios: 'EstudoFormComparacaoCenarios',
    estudoFormComparacaoEstudosGrafico: 'EstudoFormComparacaoEstudosGrafico',
    estudoFormComparacaoEstudos: 'EstudoFormComparacaoEstudos',
    estudoFormConsideracoesFinais: 'EstudoFormConsideracoesFinais',
    estudoFormPagina: 'EstudoFormPagina-',

    moreLessAcumulacao: 'moreLessAcumulacao',

    estudoReportCapaTitulo1: '$estudoReportCapaTitulo1$',
    profissaoQualificacao: '$profissaoQualificacao$',

    diagnosticoAposentadoriaIndependenciaFinanceiraPadraoVida: '$padraoVida$',
    diagnosticoAposentadoriaIndependenciaFinanceiraAporteMensalPreservarReserva: '$aporteMensalPreservarReserva$',
    diagnosticoAposentadoriaIndependenciaFinanceiraComNovosAportesAporteUnico: '$comNovosAportesAporteUnico$',
    diagnosticoAposentadoriaIndependenciaFinanceiraAporteUnico: '$aporteUnico$',
    diagnosticoAposentadoriaIndependenciaFinanceiraRecursosFinanceirosPreservarReserva: '$recursosFinanceirosPreservarReserva$',
    diagnosticoAposentadoriaIndependenciaFinanceiraAporteMensalConsumirReserva: '$aporteMensalConsumirReserva$',
    diagnosticoAposentadoriaIndependenciaFinanceiraAnos: '$anos$',
    //diagnosticoAposentadoriaIndependenciaFinanceiraCorSituacaoAtualSuperavitDeficit: '$corSituacaoAtualSuperavitDeficit$',
    //diagnosticoAposentadoriaIndependenciaFinanceiraRecursosFinanceirosExpectativaVida: '$recursosFinanceirosExpectativaVida$',
    diagnosticoAposentadoriaIndependenciaFinanceiraPreservarReservaSemAporte: '$preservarReservaSemAporte$',
    diagnosticoAposentadoriaIndependenciaFinanceiraRecursosFinanceirosPreservarReservaSemAporte: '$recursosFinanceirosPreservarReservaSemAporte$',
    diagnosticoAposentadoriaIndependenciaFinanceiraConsumirReservaSemAporte: '$consumirReservaSemAporte$',

    tipoDiagnosticoAposentadoriaChartSituacaoAtual: 'A',
    tipoDiagnosticoAposentadoriaChartSituacaoIdeal: 'I',



    modoApresentacaoRelatorio: 'modoApresentacaoRelatorio',
    


    comparacaoCenariosEstudosItemMaximoItem1Pagina: 25,

    comparacaoCenariosEstudosIdadeDataSimulacaoCliente: 'IdadeDataSimulacaoCliente',
    comparacaoCenariosEstudosIdadeVaiAposentarCliente: 'IdadeVaiAposentarCliente',
    comparacaoCenariosEstudosIdadeFinalVitalicioClienteConjuge: 'IdadeFinalVitalicioClienteConjuge',
    comparacaoCenariosEstudosRetornoReal: 'RetornoReal',
    comparacaoCenariosEstudosProjetosVidaSonho: 'ProjetosVidaSonho',
    comparacaoCenariosEstudosNecessidadeAposentadoria: 'NecessidadeAposentadoria',
    comparacaoCenariosEstudosAporteEconomiaReceitaMensal: 'AporteEconomiaReceitaMensal',
    comparacaoCenariosEstudosAportePlanejadoAposentadoria: 'AportePlanejadoAposentadoria',
    comparacaoCenariosEstudosValorPresenteReservaInicioAposentadoria: 'ValorPresenteReservaInicioAposentadoria',
    comparacaoCenariosEstudosValorPresenteNecessidadesInicioAposentadoria: 'ValorPresenteNecessidadesInicioAposentadoria',
    comparacaoCenariosEstudosSuperavitDeficitAposentadoria: 'SuperavitDeficitAposentadoria',
    comparacaoCenariosEstudosRecursosFinanceirosAcumuladosAposentadoriaIdadeAte: 'RecursosFinanceirosAcumuladosAposentadoriaIdadeAte',
    comparacaoCenariosEstudosRecursosFinanceirosIdadeFinalVitalicioClienteConjuge: 'RecursosFinanceirosIdadeFinalVitalicioClienteConjuge',
    comparacaoCenariosEstudosRetiradaMensalMaximaConsumirReservaAposentadoria: 'RetiradaMensalMaximaConsumirReservaAposentadoria',
    comparacaoCenariosEstudosRetiradaMensalMaximaPreservarReservaAposentadoria: 'RetiradaMensalMaximaPreservarReservaAposentadoria',
    comparacaoCenariosEstudosAporteMensalConsumirReservaAposentadoria: 'AporteMensalConsumirReservaAposentadoria',
    comparacaoCenariosEstudosAporteMensalPreservarReservaAposentadoria: 'AporteMensalPreservarReservaAposentadoria',
    comparacaoCenariosEstudosAporteUnicoConsumirReservaAposentadoria: 'AporteUnicoConsumirReservaAposentadoria',
    comparacaoCenariosEstudosAporteUnicoPreservarReservaAposentadoria: 'AporteUnicoPreservarReservaAposentadoria',

    comparacaoCenariosEstudosTipoCenario: 'C',
    comparacaoCenariosEstudosTipoEstudo: 'E',



    richTextEditorVazio: '<p><br></p>',



    codePoint_a: 97,



    qtdeBarrasGraficoDashboard: 8,



    location: 'pt-br',

    versaoApp: 'Versão: 4.1.13',

    whatsappText: '(11) 97589-8555',
    whatsappUrl: '5511975898555',
    whatsappUrlFormatado: '11-97589-8555',
    //whatsappText: '(11) 99987-8777',
    //whatsappUrl: '5511999878777',
    codigoPais: '55',



    urlBoletimFocus: 'https://www.bcb.gov.br/content/focus/focus/R$1$.pdf',



    //conexao: {apiUrl: 'http://localhost:8080/api', bucket: 'fin-tracks-arquivo-desenv', fimLinha: '\r\n'},
    ////conexao: {apiUrl: 'https://fin-tracks-api-hmg.herokuapp.com/api'},
    ////conexao: {apiUrl: 'https://fin-tracks-api.herokuapp.com/api'},
    //conexao: {apiUrl: 'http://localhost:8080/api', bucket: 'fin-tracks-arquivo', fimLinha: '\r\n'},
    conexao: {apiUrl: 'https://www.api.fin-tracks.com/api', bucket: 'fin-tracks-arquivo', fimLinha: '\n'},

    urlFintracks: 'HTTPS://WWW.FIN-TRACKS.COM',
    urlAppFintracks: 'HTTPS://WWW.APP.FIN-TRACKS.COM',
    urlAppFintracksDashboardInicial: 'HTTPS://WWW.APP.FIN-TRACKS.COM/DASHBOARD/CLIENTE',


    sessaoExpirou: 'Sessão Expirou',
    tokenInvalido: 'Token Inválido', // HARDCODEBACKEND.tokenInvalido
    sessaoExpirouToken: 'Sessão Expirou por causa do Token',



    tipoIntegracaoInclusaoSiteInstitucional: 'inclusaoSiteInstitucional',
    tipoIntegracaoInclusaoSiteInstitucional2: 'inclusaoSiteInstitucional2',
    tiInclusaoSiteInstitucional: 'isi',



    apiConfiguracoes: '/configuracoes',
    apiConfiguracoesAtualizarConfiguracaoUsuario: '/atualizarConfiguracaoUsuario',
    apiConfiguracoesAtualizarCamposCalculadosConfiguracaoNovaVersao: '/atualizarCamposCalculadosConfiguracaoNovaVersao',
    apiConfiguracoesBuscarConfiguracaoUsuario: '/buscarConfiguracaoUsuario',
    apiConfiguracoesBuscarConfiguracaoURL: '/buscarConfiguracaoURL',
    apiConfiguracoesGerarTokenIntegracao: '/gerarTokenIntegracao',
    
    apiUsuarios: '/usuarios',
    apiUsuariosAutenticar: '/autenticar',
    apiUsuariosSalvar: '/salvar',
    apiUsuariosAtualizarSenha: '/atualizarSenha',
    apiUsuariosReiniciarFreetrack: '/reiniciarFreetrack',
    apiUsuariosAtualizarCamposCalculadosUsuarioNovaVersao: '/atualizarCamposCalculadosUsuarioNovaVersao',
    apiUsuariosBuscarEquipeList: '/buscarEquipeList',
    apiUsuariosBuscarEquipeForm: '/buscarEquipeForm',
    apiUsuariosSalvarEquipeForm: '/salvarEquipeForm',
    apiUsuariosAtualizarEquipeForm: '/atualizarEquipeForm',
    apiUsuariosDeletarEquipe: '/deletarEquipe',

    apiVwUsuarioAcesso: '/vwUsuarioAcesso',
    apiVwUsuarioAcessoBuscarAcessoUsuarioLogadoList: '/buscarAcessoUsuarioLogadoList',

    apiVwUsuarioEstatistica: '/vwUsuarioEstatistica',
    apiVwUsuarioEstatisticaBuscarVwUsuarioEstatisticaList: '/buscarVwUsuarioEstatisticaList',

    apiEmails: '/emails',
    apiEmailsConfirmacaoCadastroUsuario: '/confirmacaoCadastroUsuario',
    apiEmailsResetSenhaUsuario: '/resetSenhaUsuario',
    apiEmailsValidacaoLogin: '/validacaoLogin',

    apiIntegracao: '/integracao',
    apiIntegracaoObterTokenIntegracaoInterno: '/obterTokenIntegracaoInterno',
    apiIntegracaoAutenticarTokenIntegracao: '/autenticarTokenIntegracao',

    apiParceiros: '/parceiros',
    apiParceirosSalvarParceiro: '/salvarParceiro',
    apiParceirosBuscarParceiroList: '/buscarParceiroList',
    apiParceirosDeletarParceiro: '/deletarParceiro',

    apiArquivos: '/arquivos',
    apiArquivosSalvarArquivoArquivoAcesso: '/salvarArquivoArquivoAcesso',
    apiArquivosAtualizarArquivo: '/atualizarArquivo',
    apiArquivosAtualizarArquivoStatusProcessamento: '/atualizarArquivoStatusProcessamento',
    apiArquivosSalvarAtualizarListaTipoArquivo: '/salvarAtualizarListaTipoArquivo',
    apiArquivosDeletarArquivoArquivoAcesso: '/deletarArquivoArquivoAcesso',
    apiArquivosBuscarArquivoByIdMandatorioConteudo: '/buscarArquivoByIdMandatorioConteudo',
    apiArquivosBuscarArquivoByGrupoArquivoTipoArquivoConteudo: '/buscarArquivoByGrupoArquivoTipoArquivoConteudo',

    apiVwArquivoAcesso: '/vwArquivoAcesso',
    apiVwArquivoAcessoBuscarArquivoList: '/buscarArquivoList',

    apiClientes: '/clientes',
    apiClientesSalvarClienteInfoclient: '/salvarClienteInfoclient',
    apiClientesSalvarClienteInfoclientList: '/salvarClienteInfoclientList',
    apiClientesAtualizarClienteInfoclient: '/atualizarClienteInfoclient',
    apiClientesDeletarClienteInfoclient: '/deletarClienteInfoclient',
    apiClientesTransferirCliente: '/transferirCliente',
    apiClientesAtualizarCamposCalculadosClienteNovaVersao: '/atualizarCamposCalculadosClienteNovaVersao',
    apiClientesBuscarClienteRecomendouList: '/buscarClienteRecomendouList',

    apiClienteAgente: '/clienteAgente',
    apiClienteAgenteIncluirAtualizarClienteAgenteList: '/incluirAtualizarClienteAgenteList',

    apiInfoclients: '/infoclients',
    apiInfoclientsBuscarByIdClienteMandatorio: '/buscarByIdClienteMandatorio',
    apiInfoclientsBuscarVersoesInfoclientList: '/buscarVersoesInfoclientList',

    apiFamilia: '/familia',
    apiFamiliaIdentificaSexo: '/identificaSexo',

    apiContaInvestimentos: '/contaInvestimentos',
    apiContaInvestimentosBuscarContaInvestimentos: '/buscarContaInvestimentos',

    apiLogradouros: '/logradouros',
    apiLogradourosBuscarByCepMandatorio: '/buscarByCepMandatorio',

    apiVwClienteAcesso: '/vwClienteAcesso',
    apiVwClienteAcessoBuscarClienteList: '/buscarClienteList',
    apiVwClienteAcessoCount: '/count',
    apiVwClienteAcessoCountFunilEtapas: '/countFunilEtapas',



    buscaClientes: 'Clientes', // HARDCODEBACKEND.buscaClientes
    buscaReceitaMensal: 'ReceitaMensal', // HARDCODEBACKEND.buscaReceitaMensal
    buscaPatrimonioImobilizado: 'PatrimonioImobilizado', // HARDCODEBACKEND.buscaPatrimonioImobilizado
    buscaRecursosFinanceiros: 'RecursosFinanceiros', // HARDCODEBACKEND.buscaRecursosFinanceiros
    buscaFunil: 'Funil', // HARDCODEBACKEND.buscaFunil
    buscaFunilEtapa: 'FunilEtapa', // HARDCODEBACKEND.buscaFunilEtapa
    buscaAgenda: 'Agenda', // HARDCODEBACKEND.buscaAgenda
    buscaPendencias: 'Pendencias', // HARDCODEBACKEND.buscaPendencias

    usuarioClienteSelecionadoGetFamiliaClienteList: 'UsuarioClienteSelecionadoGetFamiliaClienteList', // HARDCODEBACKEND.usuarioClienteSelecionadoGetFamiliaClienteList

    apiVwClienteAcessoFamilia: '/vwClienteAcessoFamilia',
    apiVwClienteAcessoFamiliaBuscarClienteList: '/buscarClienteList',
    apiVwClienteAcessoFamiliaBuscarFamiliaList: '/buscarFamiliaList',
    apiVwClienteAcessoFamiliaSumFamilia: '/sumFamilia',

    apiVwClienteAcessoConsorcio: '/vwClienteAcessoConsorcio',
    apiVwClienteAcessoConsorcioBuscarConsorcioList: '/buscarConsorcioList',
    apiVwClienteAcessoConsorcioSumConsorcio: '/sumConsorcio',
    
    apiVwClienteAcessoProjetoVidaSonho: '/vwClienteAcessoProjetoVidaSonho',
    apiVwClienteAcessoProjetoVidaSonhoBuscarProjetoVidaSonhoList: '/buscarProjetoVidaSonhoList',
    apiVwClienteAcessoProjetoVidaSonhoSumProjetoVidaSonho: '/sumProjetoVidaSonho',

    apiProposta: '/proposta',
    apiPropostaAtualizarPropostaList: '/atualizarPropostaList',
    
    apiApoliceCobranca: '/apoliceCobranca',
    apiApoliceCobrancaAtualizarSalvarDeletarApoliceCobrancaList: '/atualizarSalvarDeletarApoliceCobrancaList',
    
    apiApoliceComissao: '/apoliceComissao',
    apiApoliceComissaoAtualizarSalvarDeletarApoliceComissaoList: '/atualizarSalvarDeletarApoliceComissaoList',

    apiVwAporte: '/vwAporte',
    apiVwAportePutAporteList: '/putAporteList',
    apiVwAporteBuscarAporteList: '/buscarAporteList',
    apiVwAporteSum: '/sum',
    apiVwAporteSumData: '/sumData',
    apiVwAporteEnviarLembreteAporte: '/enviarLembreteAporte',



    apiVwClienteAcessoProposta: '/vwClienteAcessoProposta',
    apiVwClienteAcessoPropostaBuscarPropostaList: '/buscarPropostaList',
    apiVwClienteAcessoPropostaCountSumProposta: '/countSumProposta',
    apiVwClienteAcessoPropostaSumProposta: '/sumProposta',
    
    apiVwClienteAcessoApolice: '/vwClienteAcessoApolice',
    apiVwClienteAcessoApoliceBuscarApoliceList: '/buscarApoliceList',
    apiVwClienteAcessoApoliceSumApolice: '/sumApolice',

    apiVwClienteAcessoApoliceCobranca: '/vwClienteAcessoApoliceCobranca',
    apiVwClienteAcessoApoliceCobrancaBuscarApoliceCobrancaList: '/buscarApoliceCobrancaList',
    apiVwClienteAcessoApoliceCobrancaSumApoliceCobranca: '/sumApoliceCobranca',

    apiVwClienteAcessoApoliceComissao: '/vwClienteAcessoApoliceComissao',
    apiVwClienteAcessoApoliceComissaoBuscarApoliceComissaoList: '/buscarApoliceComissaoList',
    apiVwClienteAcessoApoliceComissaoSumApoliceComissao: '/sumApoliceComissao',



    apiVwClienteAgente: '/vwClienteAgente',
    apiVwClienteAgenteBuscarClienteAgenteList: '/buscarClienteAgenteList',
    apiVwClienteAgenteCountClienteAgenteTotal: '/countClienteAgenteTotal',
    apiVwClienteAgenteCountClienteAgente: '/countClienteAgente',



    apiVwClienteAcessoAporte: '/vwClienteAcessoAporte',
    apiVwClienteAcessoAporteBuscarClienteList: '/buscarClienteList',



    apiVwClienteAcessoPendencia: '/vwClienteAcessoPendencia',
    apiVwClienteAcessoPendenciaBuscarClienteList: '/buscarClienteList',
    apiVwClienteAcessoPendenciaCountPendencias: '/countPendencias',



    apiCobranca: '/cobranca',
    apiCobrancaGerarCobranca: '/gerarCobranca',
    apiCobrancaEfetuarCobranca: '/efetuarCobranca',
    apiCobrancaAcompanharCobranca: '/acompanharCobranca',
    apiCobrancaCalcularVariavel: '/calcularVariavel',



    apiConvertePDFBase64ToText: '/convertePDFBase64ToText',
    apiConvertePDFBase64ToTextConverter: '/converter',



    apiObtemPDFBase64FromUrl: '/obtemPDFBase64FromUrl',
    apiObtemPDFBase64FromUrlObter: '/obter',
    apiObtemPDFBase64FromUrlObterConverter: '/obterConverter',

    

    apiRespostaSellFlux: '/respostaSellFlux',
    apiRespostaSellFluxEnviarResposta: '/enviarResposta',

    sellFluxNovosUsuarios: 'cfc34923abd8b6492345ffa5b9f40f46',
}

export default HARDCODE
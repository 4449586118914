import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'



class VwClienteAcessoPropostaService extends ApiService {

    constructor() {
        super(HARDCODE.apiVwClienteAcessoProposta)
    }

    async buscarPropostaList(variaveisGlobais, filtroList) {

        /*
        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, null, filtroList, true)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoPropostaBuscarPropostaList}/?${params}`,
            paramsAux, false)
        */

        const body = await this.calculaBodyFiltroList(variaveisGlobais, null, filtroList, true)

        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoPropostaBuscarPropostaList, body, false)
    }

    async countSumProposta(variaveisGlobais, filtroList) {

        /*
        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, null, filtroList, true)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoPropostaCountSumProposta}/?${params}`,
            paramsAux, false)
        */

        const body = await this.calculaBodyFiltroList(variaveisGlobais, null, filtroList, true)
    
        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoPropostaCountSumProposta, body, false)
    }
    
    async sumProposta(variaveisGlobais, campoAgrupamento, filtroList) {

        /*
        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, campoAgrupamento, filtroList, true)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoPropostaSumProposta}/?${params}`, paramsAux, false)
        */

        const body = await this.calculaBodyFiltroList(variaveisGlobais, campoAgrupamento, filtroList, true)
    
        return this.put(variaveisGlobais, HARDCODE.apiVwClienteAcessoPropostaSumProposta, body, false)
    }
}

export default VwClienteAcessoPropostaService
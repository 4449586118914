// alterado - revisado

import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import ClienteService from '../../../business-api/ClienteService'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    pesquisaList
} from '../../../business-rules/List/ListService'

import simNaoList from '../../../business-rules/List/simNaoList'

import {
    atualizaFormListRecursosFinanceiros
} from '../garantias-form/GarantiasServiceRecursosFinanceiros'

import {
    atualizaFormListDiaDia
} from '../despesas-form/DespesasServiceDiaDia'

import {
    atualizaFormListReceitaMensal
} from '../garantias-form/GarantiasServiceReceitaMensal'

import {
    atualizaFormListPatrimonioImobilizado
} from '../garantias-form/GarantiasServicePatrimonioImobilizado'



const formataOrdem = ( ordem ) => {

    return ('000' + ordem).slice(-3)
}

const incluiPerguntasFormulario = ( perguntasExibir ) => {

    const perguntas = []

    let cont = 0

    perguntasExibir?.forEach( (linhaPerguntasExibir, indexPerguntasExibir) => {

        cont++

        perguntas.push(
            {
                id: parseInt(linhaPerguntasExibir.id),
                descricao: linhaPerguntasExibir.content,
                default: true,
                ordem: cont,
            }
        )
    })

    return perguntas
}

const incluiFormulario = ( cont, id, linhaConfiguracaoPerguntas, formularioList ) => {

    formularioList.push(
        {
            id: id + '-' + linhaConfiguracaoPerguntas.id,
            descricao: linhaConfiguracaoPerguntas.descricaoFormulario,
            ordem: formataOrdem(cont),
            perguntas: incluiPerguntasFormulario(linhaConfiguracaoPerguntas.perguntasExibir)
        }
    )

}

export const calculaConfiguracaoPerguntas = ( variaveisGlobais, values, linhaFormList, jsonUsuario, jsonUsuarioAdmin,
    jsonIntegracao) => {

    const formularioList = []

    let formularioListDefault = ''

    let cont = 1

    formularioList.push(
        {
            id: cont,
            descricao: LABEL.padrao,
            ordem: formataOrdem(cont),
            perguntas: linhaFormList.perguntasFormList
        }
    )



    jsonUsuario.configuracaoPerguntas?.forEach( (linhaConfiguracaoPerguntas, indexConfiguracaoPerguntas) => {

        if (linhaConfiguracaoPerguntas.descricaoFormulario && linhaConfiguracaoPerguntas.ativo !== "N") {

            cont++

            incluiFormulario(cont, ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
                linhaConfiguracaoPerguntas, formularioList)


            if (
                    !formularioListDefault &&
                    (
                        (
                            (
                                !jsonIntegracao ||
                                (
                                    jsonIntegracao &&
                                    !jsonIntegracao.integracaoInclusaoSiteInstitucional &&
                                    !jsonIntegracao.integracaoInclusaoSiteInstitucional2 &&
                                    !jsonIntegracao.integracaoInclusaoCapitacaoLeads
                                )
                            ) &&
                            linhaConfiguracaoPerguntas.default
                        ) ||
                        (
                            jsonIntegracao &&
                            (
                                jsonIntegracao.integracaoInclusaoSiteInstitucional ||
                                jsonIntegracao.integracaoInclusaoSiteInstitucional2 ||
                                jsonIntegracao.integracaoInclusaoCapitacaoLeads
                            ) &&
                            linhaConfiguracaoPerguntas.defaultCapitacaoLeads
                        )
                    )
            ) {

                formularioListDefault = formularioList[formularioList.length - 1].id
            }
        }
    })



    jsonUsuarioAdmin.jsonUsuarioAdmin?.forEach( (linhaJsonUsuarioAdmin, indexJsonUsuarioAdmin) => {

        linhaJsonUsuarioAdmin.jsonUsuario.configuracaoPerguntas?.forEach( (linhaConfiguracaoPerguntas,
            indexConfiguracaoPerguntas) => {

            if (
                    linhaConfiguracaoPerguntas.descricaoFormulario &&
                    linhaConfiguracaoPerguntas.compartilhar &&
                    linhaConfiguracaoPerguntas.ativo !== "N"
            ) {

                cont++

                incluiFormulario(cont, linhaJsonUsuarioAdmin.id, linhaConfiguracaoPerguntas,
                    formularioList)

                if (
                        !formularioListDefault &&
                        (
                            (
                                (
                                    !jsonIntegracao ||
                                    (
                                        jsonIntegracao &&
                                        !jsonIntegracao.integracaoInclusaoSiteInstitucional &&
                                        !jsonIntegracao.integracaoInclusaoSiteInstitucional2 &&
                                        !jsonIntegracao.integracaoInclusaoCapitacaoLeads
                                    )
                                ) &&
                                linhaConfiguracaoPerguntas.default
                            ) ||
                            (
                                jsonIntegracao &&
                                (
                                    jsonIntegracao.integracaoInclusaoSiteInstitucional ||
                                    jsonIntegracao.integracaoInclusaoSiteInstitucional2 ||
                                    jsonIntegracao.integracaoInclusaoCapitacaoLeads
                                ) &&
                                linhaConfiguracaoPerguntas.defaultCapitacaoLeads
                            )
                        )
                ) {
            
                    formularioListDefault = formularioList[formularioList.length - 1].id
                }
            }
        })
    })

    if (!values.formulario && formularioListDefault) {

        values.formulario = formularioListDefault
    }

    if (formularioList.length === 1 || !values.formulario || !pesquisaList(formularioList, values.formulario).id) {

        values.formulario = formularioList[0].id
    }

    return formularioList
}



export const indexPergunta = ( values, id ) => {

    let indexPerguntaAux

    values.perguntas?.forEach( (pergunta, index) => {

        if (pergunta.id === id) {

            indexPerguntaAux = index
        }
    })

    return indexPerguntaAux
}

export const processaRegistrosDefaultIniciaisPerguntas = ( values, linhaFormList, formularioList, formulario, completo, setPrimInd ) => {

    if (!values.perguntas) {

        values.perguntas = []
    }

    const perguntas = sortAsc(linhaFormList.perguntasFormList)

    perguntas?.forEach( (pergunta, index) => {

        const linhaFormularioList =
            pesquisaList(pesquisaList(formularioList, formulario).perguntas, pergunta.id)

        if (!pesquisaList(values.perguntas, pergunta.id).id) {

            values.perguntas.push(
                {
                    id: pergunta.id,
                    default: linhaFormularioList.default,
                    compoeCompleto: linhaFormularioList.compoeCompleto,
                    identificaId: pergunta.identificaId,
                    ordem: linhaFormularioList.ordem ? linhaFormularioList.ordem : 0,
                }
            )
    
            if (pergunta.type === "MultiList") {
                values.perguntas[indexPergunta(values, pergunta.id)].multiList = [{}]
            }

        } else {

            values.perguntas[indexPergunta(values, pergunta.id)].default = linhaFormularioList.default
            values.perguntas[indexPergunta(values, pergunta.id)].compoeCompleto = linhaFormularioList.compoeCompleto
            values.perguntas[indexPergunta(values, pergunta.id)].identificaId = pergunta.identificaId
            values.perguntas[indexPergunta(values, pergunta.id)].ordem = linhaFormularioList.ordem ? linhaFormularioList.ordem : 0
        }
    })

    let indexPerguntaAux = values.perguntas.length - 1
        
    while (indexPerguntaAux >= 0) {

        if (!pesquisaList(linhaFormList.perguntasFormList, values.perguntas[indexPerguntaAux].id).id) {
                
            values.perguntas.splice(indexPerguntaAux, 1)
        }

        indexPerguntaAux -= 1
    }

    values.perguntas = sortAsc(values.perguntas)

    let primIndAux

    values.perguntas?.forEach((linhaPergunta, ind) => {

        const linhaPerguntas = pesquisaList(linhaFormList.perguntasFormList, linhaPergunta.id)

        if (
            !primIndAux &&
            primIndAux !== 0 &&
            (
                (completo && linhaPerguntas.completo) ||
                (!completo && !linhaPerguntas.completo)
            ) &&
            linhaPergunta.default
        ) {

            primIndAux = ind
        }

        setPrimInd(primIndAux)
    })
}



export const processaAlteracaoPerguntasValor = ( values, item, linhaFormList ) => {

    const perguntas = linhaFormList.perguntasFormList

    const atualiza = pesquisaList(perguntas, item.id).atualiza

    const naoAtualiza = pesquisaList(perguntas, item.id).naoAtualiza

    let valor = item.valor
    let valor2 = item.valor2

    if (naoAtualiza) {
        
        if (pesquisaList(values.perguntas, naoAtualiza).valor) {

            valor = null
            valor2 = null
        }
    }

    if (atualiza) {

        if (atualiza === HARDCODE.formListAtualizaRecursosFinanceiros) {

            item.idRecursoFinanceiro =
                atualizaFormListRecursosFinanceiros(values, item.id, valor, item.perc, item.idRecursoFinanceiro)
        
        } else {

            if (atualiza === HARDCODE.formListAtualizaDiaDia) {

                item.idDiaDia =
                    atualizaFormListDiaDia(values, item.id, valor, item.idDiaDia, HARDCODE.idFamiliarCliente)

                item.idDiaDia2 =
                    atualizaFormListDiaDia(values, item.id, valor2, item.idDiaDia2, HARDCODE.idFamiliarConjuge)

            } else {

                if (atualiza === HARDCODE.formListAtualizaReceitaMensal) {

                    item.idReceitaMensal =
                        atualizaFormListReceitaMensal(values, item.id, valor, item.idReceitaMensal,
                            HARDCODE.idFamiliarCliente)

                    item.idReceitaMensal2 =
                        atualizaFormListReceitaMensal(values, item.id, valor2, item.idReceitaMensal2,
                            HARDCODE.idFamiliarConjuge)

                } else {

                    if (atualiza === HARDCODE.formListAtualizaPatrimonioImobilizado) {

                        item.idPatrimonioImobilizado =
                            atualizaFormListPatrimonioImobilizado(values, item.id, valor, item.perc, item.idPatrimonioImobilizado)
                    }
                }
            }
        }
    }
}

export const atualizaPerguntaValor = ( values, id, valor ) => {

    if (id) {

        values.perguntas?.forEach( (linhaPergunta, index) => {

            if (linhaPergunta.id === id) {

                values.perguntas[index].valor = valor
            }
        })
    }
}

export const atualizaPerguntaPerc = ( values, id, perc ) => {

    if (id) {

        values.perguntas?.forEach( (linhaPergunta, index) => {

            if (linhaPergunta.id === id) {

                values.perguntas[index].perc = perc
            }
        })
    }
}

export const atualizaPerguntaValorOuValor2 = ( values, id, valor, familiar ) => {

    if (id) {

        values.perguntas?.forEach( (linhaPergunta, index) => {

            if (linhaPergunta.id === id) {

                if (familiar === HARDCODE.idFamiliarCliente) {

                    values.perguntas[index].valor = valor

                } else {

                    if (familiar === HARDCODE.idFamiliarConjuge) {

                        values.perguntas[index].valor2 = valor
                    }
                }
            }
        })
    }
}



export const descricaoResumidaPergunta = ( linhaFormList, idPergunta ) => {

    return pesquisaList(linhaFormList.perguntasFormList, idPergunta).descricaoResumida
}



export const valorPergunta = ( values, idPergunta ) => {

    return pesquisaList(values.perguntas, idPergunta).valor
}

export const valorPerguntaIdentificaId = ( values, identificaId ) => {

    let idPergunta = ''

    values.perguntas?.forEach( (linha, ind) => {

        if (linha.identificaId === identificaId) {

            idPergunta = linha.id
        }
    })

    return idPergunta ? pesquisaList(values.perguntas, idPergunta).valor : ''
}

export const inteiroPergunta = ( values, idPergunta ) => {

    return pesquisaList(values.perguntas, idPergunta).inteiro
}

export const anosPergunta = ( values, idPergunta ) => {

    return pesquisaList(values.perguntas, idPergunta).anos
}

export const simNaoPergunta = ( values, idPergunta ) => {

    return pesquisaList(simNaoList, pesquisaList(values.perguntas, idPergunta).simNao).descricao
}